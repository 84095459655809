import React from "react";
import {Button, Typography} from "@material-ui/core";
import {NFIULDocsFetch} from "./index";
import GenericDialog from "../../../../../../../components/dialog/GenericDialog";


type NFIULOptionProps = {
    iulPopUp: boolean;
    setEstimationLoading: Function;
    setIsIUL?: Function;
    setIulPopUp: Function;
    setReportLoading: Function;
    setRequestId: any;
}

const NFIULOption: React.FC<NFIULOptionProps> = (props) => {
    const {iulPopUp, setEstimationLoading, setIsIUL, setIulPopUp, setReportLoading, setRequestId} = props;
    const [afterConfirm, setAfterConfirm] = React.useState(false);

    const onDocClose = () => {
        onClose();
        setAfterConfirm(!afterConfirm);
    };

    // open reports popup
    const onConfirm = async () => {
        setAfterConfirm(!afterConfirm);
    };

    const onClose = () => {
        setIulPopUp(false);
    };

    const content = (
            <>
                {
                        afterConfirm &&
                        <NFIULDocsFetch
                                onClose={onDocClose}
                                setEstimationLoading={setEstimationLoading}
                                setIsIUL={setIsIUL}
                                setRequestId={setRequestId}
                                setReportLoading={setReportLoading}
                        />
                }
                <Typography variant="body1">
                    You have selected the Non-Financed IUL option. Please confirm.
                </Typography>
                <Button
                        size="small"
                        variant="contained"
                        className="mt20 mb20 floatRight"
                        color="secondary"
                        onClick={onClose}
                >
                    NO
                </Button>
                <Button
                        size="small"
                        variant="contained"
                        className="mt20 mb20 floatRight mr15"
                        color="primary"
                        onClick={onConfirm}
                >
                    YES
                </Button>
            </>
    );

    return (
            <GenericDialog
                    aria-describedby="nfiul-confirm-dialog"
                    content={content}
                    fullWidth
                    keepMounted
                    maxWidth="sm"
                    onClose={onClose}
                    open={iulPopUp}
                    title="NFIUL OPTION"
            />
    );
};

export default NFIULOption;