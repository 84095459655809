import { Button, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import InfoIcon from "@material-ui/icons/Info";
import { targetBlank } from "../../utils/commonUtils";
import { MARKETING_SITE_URL } from "../../constants";
import * as React from "react";
import HomeIcon from "@material-ui/icons/Home";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useHistory } from "react-router-dom";

export default function AgentLinkClient(props: any) {
    const {
        handleNavigation,
        estimationRoute,
        routeName,
        isServiceDashboardAccessible,
        user,
        strategySlug,
        isClientDemoAccess,
        enrollmentTabse,
        handleNavClick,
        enrollmentClick,
        enrollmentStatus,
    } = props;
    const estimationRouteArray: any = ["/client/estimation", "/client/estimationV1"];
    const history = useHistory();
    const agentLink = user && user.client && user.client.agent && user.client.agent.agentLinks && user.client.agent.agentLinks.length > 0 && user.client.agent.agentLinks[0].guid;
    const educationCenter = (agentLink === "25ab03b1-9308-44d2-bf0c-b57d4c850b81" || agentLink === "5d85c662-f048-481e-a30d-a01a5cfcca2e") ? "https://jared.simplicityniw.com/25ab03b1-9308-44d2-bf0c-b57d4c850b81" : MARKETING_SITE_URL + agentLink;
    return (
            <>
                <List>
                    {
                            !(user.accessType === "client" || isServiceDashboardAccessible) &&
                            <ListItem className={(routeName === "/dashboard") ? "active menuHead" : "menuHead"}
                                      onClick={() => {
                                          handleNavigation("/dashboard");
                                      }}>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Home Dashboard"} />
                            </ListItem>
                    }
                    <>
                        <ListItem className="menuHead" onClick={() => targetBlank(educationCenter)} button>
                            <ListItemIcon>
                                <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary="Education Center" />
                        </ListItem>
                        <ListItem button className={estimationRouteArray.includes(routeName) ? "active" : ""}
                                  onClick={() => handleNavigation(estimationRoute)}>
                            <DescriptionIcon />
                            <ListItemText primary="Estimate My Benefits" />
                        </ListItem>
                    </>
                </List>
                <List className="listTwo">
                    {user.inviteStatus === 10 && strategySlug !== "esd" ? (<ListItem>
                                <Button className="btnPrimary btnHilight animated-box in"
                                        variant="contained"
                                        color="primary"
                                        disabled={user.accessType === "client"}
                                >
                                    <DescriptionIcon
                                            className="mr15" />Enrollment
                                </Button>
                            </ListItem>) :
                            (
                                    !isServiceDashboardAccessible && !isClientDemoAccess &&
                                    <React.Fragment>
                                        <ListItem
                                                className={`menuHead ${(enrollmentTabse.indexOf(history.location.pathname) > -1) ? "active" : ""}`}
                                                button disabled={user.accessType === "client"}>
                                            <React.Fragment>
                                                <ListAltIcon />
                                                <ListItemText
                                                        className={` ${user.accessType === "client" ? "disabled" : ""} `}
                                                        onClick={() => {
                                                            enrollmentClick(user);
                                                            handleNavClick();
                                                        }}
                                                        primary={
                                                            <>
                                                                <span style={{
                                                                    float: "left",
                                                                    marginTop: "6px",
                                                                }}>Enrollment </span>
                                                                {(user.inviteStatus >= 2 && (!user.enrollment)) &&
                                                                        <span className="btnHilightBar glow"
                                                                              style={{ color: "#ffffff" }}>Get Started</span>
                                                                }
                                                                {enrollmentStatus()}
                                                            </>
                                                        } />
                                            </React.Fragment>
                                        </ListItem>
                                    </React.Fragment>)}
                </List>
            </>
    );
}