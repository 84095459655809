import * as React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import imgbottomActions from "../../../../components/client/landingPage/v2/images/sectionFive/img-bottom-actions.png";

const LandingSectionFive = (props: any) => {
    const getElementDistanceFromTop = (elem) => {
        let box = elem ? elem.getBoundingClientRect() : 0;
        return box.top + window.pageYOffset;
    };
    const strategy: string = props.strategyData.displayName;
    const isMobile = window.innerWidth < 992;
    return (
        <div className="sectionPadding" id="sectionLast">
            <Grid container justify="center">
                <Grid item xs={12} md={11} lg={10} xl={9} className="textLeft pL7Rem">
                    <Grid container justify="center">
                        <Grid item xs={12} data-aos="fade-up"
                            data-aos-duration="1000">
                            <span className="section-marker last" id="lastPoint" />
                            <Typography variant="h1" align="center" className="tittle tittleCenter">Learn more and
                            get
                                    started.</Typography>
                        </Grid>
                        <Grid item xs={12} className="textCenter mb30" data-aos="fade-up"
                            data-aos-duration="1000">
                            <Typography align="center" variant="subtitle1">
                                Estimate your potential benefits to see how much more you could get with {strategy}.
                                </Typography>
                        </Grid>
                        <Grid item xs={12} className="textCenter mb30" data-aos="fade-up"
                            data-aos-duration="1000">
                            <Button size="medium" onClick={() => {
                                let distance: number = getElementDistanceFromTop(document.getElementById("estimateContainer"))
                                window.scrollTo(0,  distance-150);
                            }}
                                className="mb40 boxShadowNone"
                                variant="contained" color="primary">
                                {isMobile ? "Get Started" : "Get Started: See if You Qualify"}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} className="textCenter" data-aos="fade-up"
                            data-aos-duration="1000">
                            <img alt={"imgBottomAction"} className="imgResponsive" src={imgbottomActions} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default LandingSectionFive;
