import * as React from "react";
import qs from "query-string";
import { TextField, NumberTextField, SelectField, Label } from "../../../../../components/formInputs";
import { required } from "../../../../../utils/validation";
import { Field } from "redux-form";
import { genderDropDown, healthDropdown, StrategySlugEnum } from "../../../../../constants";
import {
    Grid,
    InputAdornment,
    Typography, Card, CardContent,
} from "@material-ui/core";
import { Form, getFormValues, reduxForm, isInvalid } from "redux-form";
import { connect } from "react-redux";
import { RootState } from "../../../../../reducers";
import { Button } from "../../../../../components/formInputs";
import * as AccountActions from "../../../../../actions/account";
import * as EstimationActions from "../../../../../actions/estimation";
import { useActions } from "../../../../../actions";
import { isEmpty } from "../../../../../utils/validation";
import { unmaskCurrency, makeQueryString } from "../../../../../utils/commonUtils";
import { minContributionAmount, fiveYearTrustFee } from "../../../../../constants";
import { EstimationPayload } from "../../../../../model/estimation";
import img from "../../../../../styles/images/img.jpg";
import numeral from "numeral";
import moment from "moment";

const EstimationForm = (props: any) => {
    const { ALLOWED_ACCOUNT_TYPE, accountType, touch } = props;
    const strategySlug: string = props.strategy && props.strategy.slug ? props.strategy.slug : "kaizen";
    const { handleSubmit, invalid, formValues, startEstimation, estimation, token, setEstimationInputValues } = props;
    const accountActions = useActions(AccountActions);
    const estimationActions = useActions(EstimationActions);
    const [benifitPercentage, setBenifitPercentage] = React.useState(0);
    const [minAge, setMinAge] = React.useState(18);
    const [maxAge, setMaxAge] = React.useState(65);
    let carrierName: string = (props.carrier && props.carrier.carrierName) ? props.carrier.carrierName : "National Life - (LSW)";
    let total_5yr_Contribution: number = (formValues && formValues.contribution) ? numeral((formValues && formValues.contribution) || 0).value() * 5 : 0;
    const searchParams = qs.parse(props.location.search);
    const [isSelected, setIsSelected] = React.useState(false);

    const validateAge = React.useCallback((value: any) => {
        if (value < minAge || value > maxAge) {
            return <Typography variant="caption" className="f12">Participants age must be between {minAge}-{maxAge} Contact your agent</Typography>;
        }
    },[formValues && formValues.age]);

    if (total_5yr_Contribution > 0) {
        total_5yr_Contribution += fiveYearTrustFee;
    }
    let totalContribution: string = total_5yr_Contribution.toFixed(2);
    let estimationData: any = {
        "def_comp_beginning_age": 0,
        "def_comp_ending_age": 0,
        "def_comp_total": 0,
        "def_comp_annual": 0,
        "def_comp_irr": "0",
        "kaizen_beginning_age": 0,
        "kaizen_ending_age": 0,
        "kaizen_annual_income": 0,
        "kaizen_total_income": 0,
        "kaizen_initial_death_benefit": 0,
        "kaizen_death_benefit_yr1": 0,
        "kaizen_death_benefit_yr90": 0,
        "kaizen_levd_irr": "0",
        "kaizen_unlevd_irr": "0",
        "kaizen_1980s_stress_test": 0,
        "kaizen_great_depression_test": 0,
        "lsw_beginning_age": 0,
        "lsw_ending_age": 0,
        "lsw_comparison_annual_income": 0,
        "lsw_comparison_total_income": 0,
        "lsw_comparison_initial_death_benefit": 0,
        "lsw_comparison_death_benefit_yr1": 0,
        "lsw_comparison_death_benefit_yr90": 0,
        "lsw_comparison_unlevd_irr": "0",
        "bonus_irr": "0",
        "bonus_beginning_age": 0,
        "bonus_ending_age": 0,
        "bonus_comp_total": 0,
        "bonus_comp_annual": 0,
    };
    if (estimation && estimation.data && estimation.data.result) {
        estimationData = estimation.data.result;
        props.sendEstimationOnParent(estimationData);
        props.setTotalContribution(totalContribution);
    }

    React.useEffect(() => {
        if (formValues && formValues.contribution) {
            touch("contribution");
        }
        if (formValues && formValues.age >= minAge && formValues.age <= maxAge) {
            getEstimates({
                contribution: formValues.contribution,
                age: formValues.age,
                gender: formValues.gender,
                healthType: formValues.healthType,
                strategySlug: strategySlug,
                carrierName: carrierName,
            }).then(() => {
                props.sendEstimationOnParent(estimationData);
                props.setTotalContribution(totalContribution);
            });
        }

    }, [formValues]);

    React.useEffect(()=>{
        if(carrierName && carrierName === "National Life - (LSW)" && strategySlug === "kaizen"){
            setMinAge(18);
            setMaxAge(65);
        } else if (carrierName === "Allianz Life" && strategySlug === "kaizen") {
            setMaxAge(65);
            setMinAge(18);
        }
    },[carrierName])

    React.useEffect(() => {
        let kaizen_annual_income: number = parseInt(estimationData.kaizen_annual_income);
        let lsw_comparison_annual_income: number = parseInt(estimationData.lsw_comparison_annual_income);
        let benfitPer: any = 0;
        benfitPer = ((kaizen_annual_income - lsw_comparison_annual_income) / lsw_comparison_annual_income) * 100;
        benfitPer = benfitPer ? parseInt(benfitPer) : 0;
        setBenifitPercentage(benfitPer || 0);
    }, [estimationData && estimationData.kaizen_annual_income > 0 ? estimationData.kaizen_annual_income : 0]);

    const getEstimatorData = async (data: any) => {
        let urlObject:any = {};
        let queryString: string = ""
        if(!data.healthType){
            data.healthType = "Standard";
        }
        if (startEstimation === 1) {
            accountActions.setPreEstimationData(data);
            //if(searchParams.landing === "1"){
                let page = {
                    landing: 2
                };
                urlObject = {...page, ...data}
                queryString = makeQueryString(urlObject)
            //}
            props.setStartEstimation(2);
            props.history.push({
                pathname: window.location.pathname,
                search: "?"+queryString,
            });
            return false;
        }

        if (startEstimation === 3) {
            await getEstimates({
                contribution: data.contribution,
                age: data.age,
                gender: data.gender,
                healthType: data.healthType,
                strategySlug: strategySlug,
                carrierName: carrierName,
            });

            props.sendEstimationOnParent(estimationData);
            props.setTotalContribution(totalContribution);
            return false;
        }
    };

    const getEstimates = async (data: any) => {
        let payload: EstimationPayload = {
            age: data.age,
            gender: data.gender === 1 ? "Male" : "Female",
            rating: data.healthType || "Standard",
            contribution: numeral(data.contribution).value() + "",
            strategySlug: data.strategySlug,
            carrierName: data.carrierName,
        };

        if (
                (payload.contribution && Number(payload.contribution) >= 0)
        ) {
            let estimatorData:any = await estimationActions.getInvitedClientEstimates(payload, token);
            if (startEstimation === 3 && estimatorData.data && estimatorData.data.result) {
                let profileData: any = {
                    age: data.age || searchParams.age,
                    gender: data.gender || searchParams.gender,
                    healthType: searchParams.healthType || "Standard",
                    contributionAmount: numeral(data.contribution).value() + "" || numeral(searchParams.contribution).value() + ""
                };
                if(props.history.location.pathname.search("/kaizen") > -1) {
                    props.sendEstimationOnParent(estimationData);
                    props.setTotalContribution(totalContribution);
                    setEstimationInputValues(profileData);
                    return;
                }
                await accountActions.updateInvitedUserClientDetails(profileData);
            }
        }
    };

    const validateContributionAmount = React.useCallback((value: any) => {
        if (!isEmpty(value) && parseFloat(unmaskCurrency(value.toString())) < 1) {
            return "Required";
        }
        if (strategySlug !== "kaizen" && value && (Number(numeral(minContributionAmount).value()) < minContributionAmount)) {
            return "Minimum contribution is " + numeral(minContributionAmount).format("$0,0");
        }
        if(strategySlug && strategySlug === StrategySlugEnum.NfIUL && (numeral(value).value() < Number(minContributionAmount))){
            return "Minimum contribution is " + numeral(minContributionAmount).format("$0,0");
        }
        if (estimation.statusCode === 400 && (numeral(value).value() < Number(estimation.minContributionAmount))) {
            return "Minimum contribution is " + numeral(estimation.minContributionAmount).format("$0,0");
        }
    }, [estimation]);

    const inputProp  = isSelected ? {
        startAdornment: <InputAdornment className="dollerIcon" position="start">$</InputAdornment>,
    } : {}

    return (
            <Form className="estimateReturnsForm mt20" onSubmit={handleSubmit(getEstimatorData)}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={6} sm={6} md={startEstimation === 3 ? 2 : 6}>
                        <Field
                                id="outlined-number1"
                                fullWidth
                                className="estimateReturnsField"
                                color="primary"
                                name="age"
                                placeholder="Enter Age"
                                type="number"
                                component={TextField}

                                validate={[required, validateAge]}
                                label={<Label label={"Your Age"} required={true} />}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={startEstimation === 3 ? 2 : 6}>
                        <Field
                                id="outlined-number2"
                                className="estimateReturnsField textLeft"
                                fullWidth
                                color="primary"
                                variant="outlined"
                                name='gender'
                                component={SelectField}
                                options={genderDropDown}
                                validate={[required]}
                                label={<Label label={"Gender"} required={true}/>}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={startEstimation === 3 ? 3 : 12}>
                        <Field
                                fullWidth
                                label={<Label label={"Your Health"} required={true}/>}
                                className="estimateReturnsField textLeft"
                                color="primary"
                                placeholder="Enter Health"
                                variant="outlined"
                                name='healthType'
                                component={SelectField}
                                options={healthDropdown}
                                validate={[required]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={startEstimation === 3 ? 5 : 12}>
                        <Field
                                fullWidth
                                label={
                                    <Label
                                            label={"Annual Contribution Amount"}
                                            required={true}/>
                                }
                                id="outlined-number4"
                                className={startEstimation === 3 ? "estimateReturnsField" : "estimateReturnsField mt10"}
                                color="primary"
                                name="contribution"
                                placeholder={"Annual Contribution Amount"}
                                component={NumberTextField}
                                validate={[required, validateContributionAmount]}
                                InputProps={inputProp}
                                onValueChange={(e) => {
                                    if (!e.value) {
                                        setIsSelected(false);
                                    } else {
                                        setIsSelected(true);
                                    }
                                }}
                        />
                    </Grid>

                    {startEstimation === 1 && accountType &&
                    <Grid item xs={12}
                          style={{ display: `${benifitPercentage > 0 && !invalid ? "block" : "none"}` }} data-aos="fade-in"
                          data-aos-duration="1500">
                        <Card className="rectangleBox">
                            <CardContent className="rectangleBoxContent">
                                <img src={img} alt="cbIcon"/>
                                <Typography variant="caption">We estimate we could increase your potential distributions in retirement by <strong
                                            className="textHighLight f22">{benifitPercentage}%</strong> more.{ALLOWED_ACCOUNT_TYPE.INDIVIDUAL !== accountType ? ` Continue to view your full benefits.` : ''}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    }
                </Grid>
                {startEstimation === 1 &&
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} className="textCenter">
                        <Button
                                label={"Continue"}
                                disabled={invalid}
                                type="submit"
                                className="mt20"
                                variant="contained"
                                color="primary"
                                size='medium'
                        />
                    </Grid>
                </Grid>
                }
            </Form>
    );

};

function mapStateToProps(state: RootState, ownProps: any) {
    let verifyData: any = {};
    if (ownProps.client) {
        let age:number = 0;
        let dobFormatted = ownProps && ownProps.client && ownProps.client.dob ? moment((ownProps.client.dob), ["YYYY-MM-DD"]) : null;
        if(dobFormatted){
            age = moment().diff(dobFormatted, 'years');
        }
        verifyData.contribution = (state.preEstimationData && state.preEstimationData.contribution) ? state.preEstimationData.contribution : (ownProps && ownProps.client ? ownProps.client.contributionAmount : '');;
        verifyData.healthType = (state.preEstimationData && state.preEstimationData.healthType) ? state.preEstimationData.healthType : 'Standard';
        //const age = ownProps && ownProps.client && ownProps.client.age ? ownProps.client.age : '';

        verifyData.age = (state.preEstimationData && state.preEstimationData.age) ? state.preEstimationData.age : (age || "");
        verifyData.gender = (state.preEstimationData && state.preEstimationData.gender) ? state.preEstimationData.gender : (ownProps && ownProps.client ? ownProps.client.gender : 0);
    } else if (state.verify.client) {
        let age:number = 0;
        let dobFormatted = state.verify && state.verify.client && state.verify.client.dob ? moment((state.verify.client.age), ["YYYY-MM-DD"]) : null;
        if(dobFormatted){
            age = moment().diff(dobFormatted, 'years');
        }
        verifyData.contribution = (state.preEstimationData && state.preEstimationData.contribution) ? state.preEstimationData.contribution : (state.verify && state.verify.client ? state.verify.client.contributionAmount : '');;
        //const age = state.verify && state.verify.client && state.verify.client.age ? state.verify.client.age : "";
        verifyData.age = (state.preEstimationData && state.preEstimationData.age) ? state.preEstimationData.age : (age || "");
        verifyData.gender = (state.preEstimationData && state.preEstimationData.gender) ? state.preEstimationData.gender : (state.verify && state.verify.client ? state.verify.client.gender : 0);
    }

    return {
        initialValues: { ...state.preEstimationData, ...verifyData },
        formValues: getFormValues("LandingEstimatorForm")(state),
        estimation: state.estimation,
        invalid: isInvalid("LandingEstimatorForm")(state),
    };
}

const reduxEnrollmentPage = reduxForm({
    form: "LandingEstimatorForm",
    enableReinitialize: true,
    touchOnChange: true,
})(EstimationForm);

export default connect(mapStateToProps)(reduxEnrollmentPage);