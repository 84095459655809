import React from "react";
import {useSelector} from "react-redux";
import {v4 as uuidV4} from "uuid";
import {Typography} from "@material-ui/core";
import {webHookURL} from "../../../Steppers";
import GenericDialog from "../../../../../../../components/dialog/GenericDialog";
import {useActions} from "../../../../../../../actions";
import * as EstimationActions from "../../../../../../../actions/estimation";
import {RootState} from "../../../../../../../reducers";
import {isDebug, isFunc, sleep} from "../../../../../../../utils/commonUtils";

type NFIULDocumentsProps = {
    onClose: () => void;
    setEstimationLoading: Function;
    setIsIUL?: Function;
    setReportLoading: Function;
    setRequestId: Function;
}

const NFIULDocuments: React.FC<NFIULDocumentsProps> = (props) => {
    const {onClose, setEstimationLoading, setIsIUL, setReportLoading, setRequestId} = props;
    const debugMode = isDebug();
    const estimationActions = useActions(EstimationActions as any);
    const {user} = useSelector((state: RootState) => state.auth);

    const content = (
            <Typography variant="body1">
                Please wait as we run your Non-Financed IUL documents.
            </Typography>
    );

    const runIULReports = async () => {
        const callingFrom = "ENROLLMENT";
        const uuid = uuidV4();
        const userId = user && user.id;

        if (userId) {
            const userRole = user.role;
            // const companyLinkUrl = (user.client && user.client.company && pez(user.client.company.companyLinkUrl));
            const webHookUrl: string = `${webHookURL}/${userId}?callingFrom=${callingFrom}`;
            const estimationDetails: any = await estimationActions.getEstimationDetailsByUserId(userId, callingFrom);

            if (estimationDetails && estimationDetails.id) {
                const responsePayload: any = JSON.parse(estimationDetails && estimationDetails.stressResponse);
                if (responsePayload && responsePayload.hasOwnProperty("success")) {
                    console.table(responsePayload);
                    const {common, comparison, illustration, run, stress} = responsePayload.success;
                    setRequestId(uuid);
                    let existingRunPayload: any = {};

                    // Setting up a template key on the basis of carrier.
                    let templateS3Key = run["deathbenefitonly"] ? "NLG Annual Individual DB Only.pdf" : "NLG Annual Individual Income.pdf";
                    if (common['carrier'] === "ALZ") {
                        // isNfiUl case
                        templateS3Key = "ALZ NFIUL.pdf";
                    } else {
                        // TODO make a common funx
                    }

                    existingRunPayload["id"] = uuid;
                    existingRunPayload["webhook"] = webHookUrl;
                    existingRunPayload["run"] = {
                        comparison: false,
                        deathbenefitonly: false,
                        express: true,
                        illustration: false,
                        reports: true,
                        stress: false,
                        stresssolvefor: undefined,
                    };
                    existingRunPayload["common"] = common;
                    existingRunPayload["reports"] = {
                        "existingrun": {
                            "reports": false,
                            "stress": stress,
                            "illustration": illustration,
                            "comparison": comparison,
                        },
                        "details": {
                            "Kaizen": {
                                "templateS3Key": templateS3Key,
                            },
                            "Final_ClientFacing": {
                                "acceleratedbenefitsridersummary": true,
                                "printpuzzlepieceimageswithabrsummary": true,
                                "benefitsummaryanalysis": false,
                                "diversecreditingoptions": false,
                                "illustratedfuturepolicyactions": false,
                                "inputsummary": false,
                                "internalrateofreturn": false,
                                "monthlylowpointvalues": false,
                                "myplannedlifeinsuranceoffers": true,
                                "myplannedlifeinsuranceoffersdisplayorder": 1,
                                "policychargesandexpenses": false,
                                "salespresentationpages": false,
                                "statutorypremiuminformation": false
                            },
                            "Final_Internal": {
                                "acceleratedbenefitsridersummary": true,
                                "printpuzzlepieceimageswithabrsummary": true,
                                "benefitsummaryanalysis": false,
                                "diversecreditingoptions": false,
                                "illustratedfuturepolicyactions": false,
                                "inputsummary": true,
                                "internalrateofreturn": false,
                                "monthlylowpointvalues": false,
                                "myplannedlifeinsuranceoffers": true,
                                "myplannedlifeinsuranceoffersdisplayorder": 1,
                                "policychargesandexpenses": false,
                                "salespresentationpages": false,
                                "statutorypremiuminformation": false
                            },
                            "LowPoint": {
                                "acceleratedbenefitsridersummary": true,
                                "printpuzzlepieceimageswithabrsummary": true,
                                "benefitsummaryanalysis": false,
                                "diversecreditingoptions": false,
                                "illustratedfuturepolicyactions": false,
                                "inputsummary": true,
                                "internalrateofreturn": false,
                                "monthlylowpointvalues": false,
                                "myplannedlifeinsuranceoffers": true,
                                "myplannedlifeinsuranceoffersdisplayorder": 1,
                                "policychargesandexpenses": false,
                                "salespresentationpages": false,
                                "statutorypremiuminformation": false
                            }
                        },
                    };

                    if (debugMode) {
                        console.log(existingRunPayload);
                        console.log(existingRunPayload.run);
                    }

                    await estimationActions.getEstimatesFromCloud(existingRunPayload, userRole, {}, userId, callingFrom);

                    // We reached here; it is NF IUL case; set temp Nf IUL
                    const isNfIulCase = true;
                    setIsIUL && isFunc(setIsIUL) && setIsIUL(isNfIulCase);

                    onClose();
                }
            }

            return false;
        }
        return false;
    };

    const firstRunEffect: any = () => {
        if (user && user.id) {
            // loader
            setEstimationLoading(true);
            setReportLoading(true);

            // run iul reports
            sleep(500)
                    .then(runIULReports);
        }
    };

    React.useEffect(firstRunEffect, [user && user.id]);

    return (
            <GenericDialog
                    aria-describedby="nfiul-documents-dialog"
                    content={content}
                    fullWidth
                    keepMounted
                    maxWidth="sm"
                    onClose={onClose}
                    open={true}
                    title="NFIUL Documents"
            />
    );
};

export default NFIULDocuments;