import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import NewCustomDesignButton from "./NewCustomDesignButton";
import ResolvedFor from "./ResolvedFor";
import StartNewEstimation from "./StartNewEstimation";
import RenderIllustrationButton from "./RenderIllustrationButton";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        marginTop: 40,
        width: 505,
        float: "right",
        border: "3px solid #3DDB93",
        [theme.breakpoints.down(767)]: {
            width: "100%",
        },
        [theme.breakpoints.down(1292)]: {
            marginTop: 0,
            float: "left",
        },
    },
    demoRoot:{
        marginTop: 40,
        boxShadow:"none"
    }
}));

export default function ResultActionAndInfoBox(props: {
    isEnrolled: boolean,
    isEnrolledCompleted: boolean,
    setIsReportRequestCompleted: Function,
    isReportDataLoading: boolean,
    isItUnderExpansionPanel?: boolean,
    calculateAgain?: Function,
    callingFrom?: string,
    resolveFor: any,
    setEditLifeTimeDeathBenefitAmount: Function,
    setEditCalculationInputs: Function,
    clearEstimationData: Function,
}) {
    const {
        isEnrolled, isEnrolledCompleted,
        setIsReportRequestCompleted,
        isReportDataLoading,
        calculateAgain,
        callingFrom,
        resolveFor,
        setEditLifeTimeDeathBenefitAmount,
        setEditCalculationInputs,
        clearEstimationData
    } = props;

    const { user: { role } }: any = useSelector((state: RootState) => state.auth);
    const classes = useStyles();

    let text: string = "";
    let helperText = <Typography className="textBlackLight f12 floatLeft w100" variant="caption" align="left">
        <sup>*</sup>Sample illustration is valid for 30 days and is for
        estimation purposes only.
    </Typography>;
    let topText: string = "Ready for Next Steps?";
    let resolveForBtn: string = (resolveFor === "Contribution_Amount" ? "Life Time Death Benefit" : "Edit Contribution");
    console.log(callingFrom);
    switch (callingFrom) {
        case "ESTIMATOR":
            text = "You can start a new estimate, edit the current contribution or proceed to your enrollment from here using the current calculations.";
            break;
        case "CUSTOM_DESIGN":
            topText = "Actions";
            resolveForBtn = "Edit Current Design";
            break;
    }

    return (
            <Card className={callingFrom === "ESTIMATOR" ? classes.root + " w100 " + " boxShadow " : classes.demoRoot}>
                <CardContent>
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <div className="displayF">
                                {callingFrom === "ESTIMATOR" &&
                                        <span className="glowDot mr10"/>
                                }
                                <Typography className="mb0 floatLeft w100" variant="body1" align="left"
                                        component="strong">{topText}</Typography>
                            </div>
                        </Grid>

                        {!!text && <Grid item xs={12}>
                            <Typography className="mb0 floatLeft w100" variant="caption"
                                        align="left">{text}</Typography>
                        </Grid>}

                        <RenderIllustrationButton
                                role={role}
                                callingFrom={callingFrom}
                                isReportDataLoading={isReportDataLoading}
                                setIsReportRequestCompleted={setIsReportRequestCompleted}
                                classes={classes}
                        />

                        {
                                callingFrom === "ESTIMATOR" &&
                                <StartNewEstimation clearEstimationData={clearEstimationData}/>
                        }

                        <ResolvedFor
                                resolveFor={resolveFor}
                                resolveForBtn={resolveForBtn}
                                setEditLifeTimeDeathBenefitAmount={setEditLifeTimeDeathBenefitAmount}
                                setEditCalculationInputs={setEditCalculationInputs}
                                callingFrom={callingFrom}
                        />

                        {/*{*/}
                        {/*        callingFrom === "ESTIMATOR" &&*/}
                        {/*        <RenderStatusButton isEnrolled={isEnrolled}*/}
                        {/*                            isEnrolledCompleted={isEnrolledCompleted}*/}
                        {/*                            isReportDataLoading={isReportDataLoading}*/}
                        {/*                            calculateAgain={calculateAgain}*/}
                        {/*        />*/}
                        {/*}*/}
                        {
                                callingFrom === "CUSTOM_DESIGN" &&
                                <NewCustomDesignButton clearEstimationData={clearEstimationData}/>
                        }
                    </Grid>
                    {/*{helperText}*/}
                </CardContent>
            </Card>

    );
}