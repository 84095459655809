import {getCarrierCode} from "../../../../utils/commonUtils";
import numeral from "numeral";
import {getEmployerAndParticipantCont} from "../../Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";

export const InvestmentValues = {
    SP500: "S&P500",
    lowVolatility: "lowVolatility",
    // Todo @tejveer remove unsed constants after complete work
    SP500ALZ: "S&P500ALZ", //this is for S&P Futures
    lowVolatilityALZ: "lowVolatilityALZ",
    Conservative: "Conservative",
    Moderate: "Moderate",
    Aggressive: "Aggressive",
    ConservativePlus: "ConservativePlus",
    ModeratePlus: "ModeratePlus",
    AggressivePlus: "AggressivePlus",
};

export const isSuffixExist  = (str) =>{
    return !!InvestmentValues[str+"Plus"]
}
export const checkSuffixPlus = (str:any, suffix= 'Plus') =>{

    // Check if either string is null or empty
    if (((str === null) || (str === '')) || ((suffix === null) || (suffix === '')))
    {
        return false; // Return false if either string is null or empty
    }
    else
    {
        // Convert both strings to type string
        str = str.toString();
        suffix = suffix.toString();
    }
    // Check if the suffix is found at the end of the string
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}
export const InvestmentValuesLabel = {
    [InvestmentValues.SP500]: "S&P 500",
    [InvestmentValues.lowVolatility]: "low Volatility",
    [InvestmentValues.SP500ALZ]: "S&P Futures",
    [InvestmentValues.lowVolatilityALZ]: "BUDBI Classic",
    [InvestmentValues.Conservative]: "Conservative",
    [InvestmentValues.Moderate]: "Moderate",
    [InvestmentValues.Aggressive]: "Aggressive",
    [InvestmentValues.ConservativePlus]: "Conservative PLUS",
    [InvestmentValues.ModeratePlus]: "Moderate PLUS",
    [InvestmentValues.AggressivePlus]: "Aggressive PLUS",
};

export const allocationMapper = {
    [InvestmentValues.SP500]: ["SP500PointToPointCapFocus"],
    [InvestmentValues.lowVolatility]: ["BalancedTrendIndexPointToPointNoCap", "USPacesetterIndexPointtoPointNoCap"],
    [InvestmentValues.SP500ALZ]: ["130"],
    [InvestmentValues.lowVolatilityALZ]: ["100"],
    [InvestmentValues.Conservative]: ["101", "131", "130", "129"],
    [InvestmentValues.Moderate]: ["77", "128", "127", "126"],
    [InvestmentValues.Aggressive]: ["86", "125", "124", "123"],
    [InvestmentValues.ConservativePlus]: ["101", "131", "129", "130", "3"],
    [InvestmentValues.ModeratePlus]: ["77", "128", "127", "126", "3"],
    [InvestmentValues.AggressivePlus]: ["86", "125", "124", "123", "3"],
}
export const addPlus = (plus, code) =>{
     if(plus && InvestmentValues[code+"Plus"]){
        return code+"Plus"
     }
     return code
}

export const overRideLabels = {
    // [InvestmentValues.ConservativePlus]: {
    //         "101": "20% PIMCO (1% Bonus)",
    //         "131": "20% Blended Futures (1% Bonus)",
    //         "130": "20% S&P Futures (1% Bonus)",
    //         "129": "20% BUDBI (1% Bonus)",
    //         "3": "20% Fixed Account"
    // },
    // [InvestmentValues.ModeratePlus]: {
    //     "77": "20% PIMCO (15% Multiplier)",
    //     "128": "20% Blended Futures (15% Multiplier)",
    //     "127": "20% S&P Futures (15% Multiplier)",
    //     "126": "20% BUDBI (15% Multiplier)",
    //     "3": "20% Fixed Account"
    // },
    // [InvestmentValues.AggressivePlus]: {
    //     "86": "20% PIMCO Select (40% Multiplier)",
    //     "125": "20% Blended Futures Select (40% Multiplier)",
    //     "124": "20% S&P Futures Select (40% Multiplier)",
    //     "123": "20% BUDBI Select (40% Multiplier)",
    //     "3": "20% Fixed Account"
    // },
    // [InvestmentValues.SP500ALZ]: {
    //     "130": "S&P 500® Futures (1% Bonus)"
    // }

}
export const allocationQuestions = {
    [InvestmentValues.SP500]: {
        codes:["SP500PointToPointCapFocus"],
        labels: ["S&P 500 Point to Point Cap Focus"]
    },
    [InvestmentValues.lowVolatility]: {
        codes:["BalancedTrendIndexPointToPointNoCap", "USPacesetterIndexPointtoPointNoCap"],
        labels: ["Balanced Trend Index Point to Point No Cap", "US Pacesetter Index Point To Point No Cap"]
    },
    [InvestmentValues.lowVolatilityALZ]: {
        codes:["100"],
        labels: ["BUDBI Classic 0.90% Guaranteed Bonus No Additional Charge"]
    },
    [InvestmentValues.SP500ALZ]: {
        codes:["130"],
        labels: ["S&P 500® Futures"]
    },
    [InvestmentValues.Conservative]: {
        codes:["101", "131", "130", "129"],
        labels: ["PIMCO (1% Bonus)", " Blended Futures (1% Bonus)", " S&P Futures (1% Bonus))", " BUDBI (1% Bonus)"]
    },
    [InvestmentValues.ConservativePlus]: {
        codes:["101", "131", "129", "130", "3"],
        labels: ["PIMCO (1% Bonus)", " Blended Futures (1% Bonus)", " S&P Futures (1% Bonus))", " BUDBI (1% Bonus)", " Fixed Allocations"]
    },
    [InvestmentValues.Moderate]: {
        codes:["77", "128", "127", "126"],
        labels: ["PIMCO (15% Multiplier)", " Blended Futures (15% Multiplier)", " S&P Futures (15% Multiplier)", " BUDBI (15% Multiplier)"]
    },
    [InvestmentValues.ModeratePlus]: {
        codes:["77", "128", "127", "126", "3"],
        labels: ["PIMCO (15% Multiplier)", " Blended Futures (15% Multiplier)", " S&P Futures (15% Multiplier)", " BUDBI (15% Multiplier)", " Fixed Allocations"]
    },
    [InvestmentValues.Aggressive]: {
        codes: ["86", "125", "124", "123"],
        labels: ["PIMCO Select (40% Multiplier)", " Blended Futures Select (40% Multiplier)", " S&P Futures Select (40% Multiplier)", " BUDBI Select (40% Multiplier)"]
    },
    [InvestmentValues.AggressivePlus]: {
        codes: ["86", "125", "124", "123", "3"],
        labels: ["PIMCO Select (40% Multiplier)", " Blended Futures Select (40% Multiplier)", " S&P Futures Select (40% Multiplier)", " BUDBI Select (40% Multiplier)", " Fixed Allocations"]
    },
}

export function isALGCarrier(carrierId:any){
    const carrierCode = getCarrierCode(carrierId);
    return carrierCode == "ALZ"
}

export function calculateGrowthRate(allocations){
    return allocations && allocations.reduce((total, question) => {
        return Number(question && question.allocationrate) + total
    }, 0) / allocations.length;
}

export const ALZInterestPayload =[
    {
        "allocations": [
            {
                "allocationname": "100",
                "allocationrate": 6.74,
                "allocationweight": 100
            }
        ],
        "from": "1",
        "through": "M"
    }
]

export const stressAllocationForALZ =  [
        {
            "allocations": [
                {
                    "allocationname": "101",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "131",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "130",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "129",
                    "allocationrate": 0,
                    "allocationweight": 25
                }
            ],
            "from": "1",
            "through": "5"
        },
        {
            "allocations": [
                {
                    "allocationname": "101",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "131",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "130",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "129",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                }
            ],
            "from": "6",
            "through": "7"
        },
        {
            "allocations": [
                {
                    "allocationname": "101",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "131",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "130",
                    "allocationrate": 0,
                    "allocationweight": 25
                },
                {
                    "allocationname": "129",
                    "allocationrate": 0,
                    "allocationweight": 25
                }
            ],
            "from": "8",
            "through": "8"
        },
        {
            "allocations": [
                {
                    "allocationname": "101",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "131",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "130",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                },
                {
                    "allocationname": "129",
                    "allocationrate": 6.72,
                    "allocationweight": 25
                }
            ],
            "from": "9",
            "through": "M"
        }
    ];

export const stressAllocationsData = [
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            }
        ],
        "from": "1",
        "through": "5"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            }
        ],
        "from": "6",
        "through": "7"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            }
        ],
        "from": "8",
        "through": "8"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            }
        ],
        "from": "9",
        "through": "9"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 0,
                "allocationweight": 50
            }
        ],
        "from": "10",
        "through": "12"
    },
    {
        "allocations": [
            {
                "allocationname": "BalancedTrendIndexPointToPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            },
            {
                "allocationname": "USPacesetterIndexPointtoPointNoCap",
                "allocationrate": 6.44,
                "allocationweight": 50
            }
        ],
        "from": "13",
        "through": "M"
    }
]


