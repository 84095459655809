import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import EstimationTabOne from "./Components/tabs/EstimationTabOne";
import EstimationTabTwo from "./Components/tabs/EstimationTabTwo";
import EstimationTabThree from "./Components/tabs/EstimationTabThree";
import {Button, Card, CardContent, CircularProgress, Grid} from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {useSelector} from "react-redux";
import {RootState} from "../../../../reducers";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
            <div
                    role="tabpanel"
                    hidden={value !== index}
                    id={`wrapped-tabpanel-${index}`}
                    aria-labelledby={`wrapped-tab-${index}`}
                    {...other}
            >
                {value === index && (
                        <Box p={3} component="div">
                            {children}
                        </Box>
                )}
            </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`,
    };
}

interface EstimationTabProps {
    strategyName: string
    trizenDemo?: any
    registrationMark: any
    responseData: any
    lifeTimeDeathBenefitSeeking: boolean
    strategySlug: string,
    callingFrom?:string
    tag?:string,
    setIulPopUp?: Function;
}

export const IllustrationButton = (props: { isReportDataLoading: boolean | undefined, classes: any, clientEstimationPage?: boolean, setIsReportRequestCompleted?: Function, callingFrom?: string }) => {
    const {
        isReportDataLoading,
        classes,
        clientEstimationPage = true,
        setIsReportRequestCompleted,
        callingFrom,
    } = props;
    const { user } = useSelector((state: RootState) => state.auth);


    return (<>{isReportDataLoading ?
            <Grid item xs={12} md={callingFrom === "ESTIMATOR" ? 6 : 12}>
                <Button
                        className={clientEstimationPage ? "floatLeft" : "floatRight"}
                        variant="outlined"
                        disabled
                        size="small"
                        color="secondary"
                >
                    <CircularProgress className={classes.buttonProgress} size={20}/>
                    Loading Illustration
                </Button>
            </Grid>
            :
            <Grid item xs={12} md={callingFrom === "ESTIMATOR" ? 6 : 12}>
                <Button
                        className={clientEstimationPage ? "floatLeft" : "floatRight"}
                        variant={callingFrom === "ESTIMATOR" ? "outlined" : "outlined"}
                        size="small"
                        color="primary"
                        onClick={() => {
                            if (typeof setIsReportRequestCompleted === "function") {
                                setIsReportRequestCompleted(true);
                            }
                        }}
                        startIcon={<PictureAsPdfIcon/>}
                >
                    {callingFrom === "CUSTOM_DESIGN" && !!(user && user.accessType) ? "View Illustration " : "View Documents"}
                </Button>
            </Grid>
    }</>);
};

export default function EstimationTabs(props: EstimationTabProps) {
    const {
        strategyName,
        trizenDemo,
        registrationMark,
        responseData,
        lifeTimeDeathBenefitSeeking,
        strategySlug,
        callingFrom,
        tag = "",
        setIulPopUp,
    } = props;
    const [tabValue, setTabValue] = React.useState("one");
    const headerRef: any = React.useRef("null");
    const secondTab: any = React.useRef("null");
    const firstTab: any = React.useRef("null");
    const thirdTab: any = React.useRef("null");
    const [firstTabLeftPost, setFirstTabLeftPost] = React.useState(0);
    const [secondTabLeftPost, setSecondTabLeftPost] = React.useState(0);
    const [thirdTabLeftPost, setThirdTabLeftPost] = React.useState(0);
    const isMobile = window.innerWidth < 767;
    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        setSecondTabLeftPost(secondTab.current.getBoundingClientRect().left);
        setFirstTabLeftPost(firstTab.current.getBoundingClientRect().left);
        setThirdTabLeftPost(thirdTab.current.getBoundingClientRect().left);
    }, []);

    return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="estimationTabs mt40">
                            <div className="estimationHeader" ref={headerRef}>
                                <Tabs value={tabValue} className="forOverflow"
                                      variant={isMobile ? "scrollable" : "fullWidth"}
                                      onChange={handleChange} aria-label="wrapped label tabs example">
                                    <Tab className="tabOne" value="one"
                                         label={<>
                                             <div className="tabsIcons">1</div>
                                             <span className="floatLeft text">CONTRIBUTION TOTALS</span></>}
                                         wrapped
                                         {...a11yProps("one")}
                                         ref={firstTab} onClick={() => {
                                        headerRef.current.scrollLeft = firstTabLeftPost;
                                    }}
                                    />
                                    <Tab value="two"
                                         label={<>
                                             <div className="tabsIcons">2</div>
                                             <span className="floatLeft text">BENEFITS & DISTRIBUTIONS</span></>}
                                         {...a11yProps("two")} ref={secondTab} onClick={() => {
                                        headerRef.current.scrollLeft = secondTabLeftPost;
                                    }}/>
                                    <Tab value="three"
                                         label={<>
                                             <div className="tabsIcons">3</div>
                                             <span
                                                     className="floatLeft text">COMPARISONS</span></>}
                                         {...a11yProps("three")} ref={thirdTab} onClick={() => {
                                        headerRef.current.scrollLeft = thirdTabLeftPost;
                                    }}/>
                                </Tabs>
                            </div>
                            <Card className="estimationContentCard">
                                <CardContent className="pd0">
                                    <TabPanel value={tabValue} index="one">
                                        <EstimationTabOne
                                                strategyName={strategyName} strategySlug={strategySlug}
                                                trizenDemo={trizenDemo}
                                                responseData={responseData}
                                                setTabValue={setTabValue}
                                                callingFrom={callingFrom}
                                                tag={tag}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabValue} index="two">
                                        <EstimationTabTwo
                                                strategyName={strategyName}
                                                strategySlug={strategySlug} responseData={responseData}
                                                setTabValue={setTabValue}
                                                lifeTimeDeathBenefitSeeking={lifeTimeDeathBenefitSeeking}
                                                callingFrom={callingFrom}
                                                tag={tag}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabValue} index="three">
                                        <EstimationTabThree
                                                strategyName={strategyName}
                                                strategySlug={strategySlug}
                                                registrationMark={registrationMark}
                                                responseData={responseData} setTabValue={setTabValue}
                                                lifeTimeDeathBenefitSeeking={lifeTimeDeathBenefitSeeking}
                                                callingFrom={callingFrom}
                                                tag={tag}
                                                setIulPopUp={setIulPopUp}
                                        />
                                    </TabPanel>
                                </CardContent>
                            </Card>
                        </div>
                    </Grid>
                </Grid>
            </>
    );
}
