import {Carrier} from './carrier'
import {Imo} from './imo';
import {Enrollment} from './enrollment';
import {Bank} from './bank';
import {Trustee} from './trustee';
import {Company} from './company';
import {Tranche} from "./tranche";
import {Aggregator} from "./aggregator";
import {ClientDocsInterface} from "./documents";
import {BN} from "./misc";
import {ClientPolicies} from "./clientPolicies";
import {Lead} from "./lead";


export interface AgentCarriers {
  id?: number,
  agentId?: number,
  carriersId?: number,
  status?: boolean,
  agentCode?: string | number,
  isPrimary?: boolean | number,
  carrier?: Carrier
}

export interface Agent {
  id?: number,
  imoId?:number,
  companyLogo?: string,
  companyName?: string,
  officeNumber?: string,
  companyStreet?: string,
  companyCity?: string,
  companyState?: string,
  companyZipCode?: string,
  designationId?: number,
  userId: string,
  imo?: Imo,
  carriers?: AgentCarriers[],
  user?: Account,
  agentType?: number,
  companyCount?: number,
  leads?: Lead[]
}

export interface Client {
  id?: number,
    userId?:string,
  trancheNo?:number,
  trancheStarted?:number,
  strategyId?: number,
  adminProposal?: string,
  strategyName?: string,
  leadSourceId?: number,
  clientType?: number,
  agentId?: number,
  carrierId?:number
  companyId?: number,
  gender?: number,
  dob?: string,
    age?:number,
  birthState?: string,
    marriageStatus?: string,
    employeeIdState?: string,
    faceAmount: number,
  state?: string,
  country?: string,
  city?: string,
  street?: string,
  zipCode?: string,
  strategy?: Strategy,
  agent?: Agent,
  imo?: Imo,
  clientPolicies?: ClientPolicies[],
  isAgreementAccepted?: boolean,
  contributionAmount?: number,
  healthType? : string,
  company?: Company,
    clientlist?:any,
  underWritingOfferType?: string,
    underWritingOfferTypeFinal?: string,
  carrier?: Carrier | undefined,
    employeeIdNumber? : string,
    employeeSSN? : string,
    workPhoneNumber? : string,
    annualIncome? : string,
    deathBenefit? : string,
    netWorth? : string,
    occupation? : string,
    isLead?: number,
    issueAge? : number,
    identityType? : string,
    isDocumentAddress? : number,
    selfEmployed? : number,
    fullTimeEmployee? : number,
    employer? : string,
    employedTime? : number
    usCitizen? : number
    nonUsCitizen? : number,
    birthCountry? : string,
    identityFrontPhoto?:string,
    identityBackPhoto?:string,
    residencyDocument?:string,
    residenceBackPhoto?:string,
    residenceFrontPhoto?:string,
    utilityBillPhoto?:string,
    taxDocument?:string,
    employerContribution?: number,
    noOfPolicies?: number,
    existPolicies?: boolean,
    annualIncome1?: string,
    annualIncome2?: string,
    clientDocuments?: (ClientDocsInterface | { path: string, backPath?:string, documentType?:string })[],
    annualPremium?: number,
    issuedContribution: number | undefined,
    processedAmount: number | undefined,
    enrollmentNotes? : any,
    tranche? : any,
    clientRequestedDocuments?: any,
    showMarketing?: number,
    isCombineTrust?: number,
    combinedClientId?: number,
    currentPolicyStatus?: string
    agreeOnContributionAmount?: boolean
    agreeOnMedicalQuestion?: boolean
    agreeOnInsuredQuestion?: boolean
    agreeOnFinancialQuestion?: boolean
    gwtClients?: GWTClients | undefined
    match?:number,
    cap?:number
  nonFinance?: boolean
}

export interface GWTClients {
    clientId: number;
    createdAt: string;
    currentKaiZenPolicy: BN;
    email: string;
    firstName: string;
    gen1UserId: string;
    id: number;
	lastName: string;
	otherCompanyName: string;
	otherDeathBenefitAmount: string;
	otherPolicyNumber: string;
	otherType: number;
	policyNumber: string;
	relation: string;
	tranceNo: string;
	updatedAt: string;
    taxDocument: string;
    status: number;
}

export interface GroupLinkUser {
  id?: number,
  groupLinkUserId?: string,
  gender?: number,
  dob?: string,
  state?: number,
  city?: string,
  address1?: string,
  zip?: string,
  documentSigned?: number,
  groupLink?: GroupLink
}

export interface GroupLink {
  productName?: string
}

export interface Account {
  id?: number | string | undefined,
  firstName: string,
  middleName?: string | undefined,
  lastName: string,
  email: string,
  phoneNumber: string,
  role?: number,
  accessType?: string | undefined
  scope?: [string] | undefined,
  accountType?: string | undefined,
  carrier?: Carrier | undefined,
  inviteLink?: string | undefined,
  inviteStatus?: number | undefined,
  imo?: Imo | undefined,
  image?: string | undefined,
  agent?: Agent | undefined,
  client?: Client | undefined,
  groupLinkUser?: GroupLinkUser | undefined,
  createdAt?: string | undefined,
  touDuration?: number | undefined,
  lastLogin?: string | undefined,
  enrollment? : Enrollment,
  trustee?: Trustee | undefined,
  bank?: Bank | undefined,
  company?: Company | undefined,
  completed?:number,
  isMailBounced?: boolean | undefined
  defaultStatergyLogo?: string | null
  demoStrategy?: Strategy
  demoCarrier?: DemoCarrier,
  demoCarrierId?: number,
    demoStrategyId?: number,
  demoClientId?: number,
  nationalLife?: any,
  tranche?: Tranche,
  pendingItemsCount?:number,
  currentTicketCount?:number,
  leadsCount?:number,
  agentApproval?:number,
  trusteeDetail?:any,
  permissions?: AuthPermission[],
  demoLogo?: string,
  groupSigners?: any,
  participant?:any,
  admin?:any,
  override?: number,
  aggregator?: Aggregator,
  gwtClients?: GWTClients | undefined
}

export interface Verify {
    verifiedInvite?: boolean,
    verifiedLink?: boolean,
    checked?: boolean,
    updated?: boolean,
    firstName?: string,
    lastName?: string
    phoneNumber?: string
    email?: string
    role?: number,
    strategy?: Strategy,
    client?: any,
    agent?: any,
    carrier?:any,
    participant?: any,
    nationalLife?:any
}

export interface GetPreEstimation {
    age?: number,
    gender?:number,
    healthType?:number,
    contribution?:string
}

export interface AccountList {
  rows: Account[],
  count: number
}

export interface AuthPermission {
  
}

export interface ProfileDetail extends Account{ }

export interface AdditionalCarrier {
  id?: number;
  carrierName?: string;
}

export interface CarrierStrategy {
  carrierId?: number;
  strategyId?: number;
  status?: boolean;
  strategies?: Strategy
}

export interface Strategy {
    id?: number;
    name?: string;
    status?: boolean;
    slug?: string;
    logo?: string;
    landingPageLogo?: string;
    reBrand?: number;
    carrierStrategyId?: number
    reBrandLogo?:string;
    emailLogo?:string;
    animationImage?:string;
    animationImageThumb?:string;
    niwLogo?:string;
    individualType?: number;
    groupType?: number;
    clientListType?: number;
    displayName?: string;
    text?: string;
    registrationMark?: boolean;
    companyText?: string;
}

export interface ProfileStrategies {
    strategyId: number,
    strategies: Strategy
}

export interface StrategyList {
  data: Strategy[]
}

export interface CarrierStrategyList {
  data: AgentCarriers[]
}

export interface Carrier {
  id?: number;
  carrierId?: number;
  carrierName?: string;
  isPrimary?: boolean
}

export interface CarrierList {
  data: Carrier[]
}

export interface AdditionalCarrierList {
  data: AdditionalCarrier[],
}

export interface Designation {
  id?: number;
  name?: string;
}

export interface DesignationList {
  data: Designation[],
}

export interface CarrierRequestList {
  id: number,
  isPrimary: number,
  status: number,
  agentId: number,
  carrierId: number,
  "carrierAgent.id": number,
  "carrierAgent.carriers.id": number,
  "carrierAgent.carriers.status": number,
  "carrierAgent.carriers.isPrimary": number,
  "carrierAgent.carriers.carrier.id": number,
  "carrierAgent.carriers.carrier.primaryCarrier": string,
  "carrierAgent.user.id": number,
  "carrierAgent.user.firstName": string,
  "carrierAgent.user.lastName": string,
  "carrier.requestedCarrier": string
}

export interface carrierRequests {
  rows: CarrierRequestList[],
  count: number
}

export interface AccountSortList {
  "sNo"? : string|number,
  "firstName": string,
  "lastName": string,
  "email"?: string,
  "agentType"?: string,
  "participantType"?: string,
  "createdAt": string,
  "role"?: number,
  "phoneNumber"?: string,
  "inviteStatus"?: string,
  "status"?: number,
  "accountsCount"? : number,
  "startedEnrollmentCount"? : number,
  "completedEnrollmentCount"? : number,
  "client.agent.user.name"?: string,
  "client.strategyName"?: string,
  "client.id"?: number,
  "client.strategy.id"?: number,
  "client.strategy.name"?: string,
  "client.company.id"?: number,
  "client.company.companyName"?: string,
  "participants"?: number,
  "client.imo.groupName"?: string,
  "client.imo.region"?: string,
  "agent.imo.groupName"?: string,
  "agent.agentImos.imo.groupName": string,
  "agent.participants"?: number,
  "imo.groupName"?: string,
  "imo.officeNumber"?: string,
  "imo.imoClass"?: string,
  "imo.region"?: string,
  "carrier.carrierName"?: string,
  "carrier.carrierPhone"?: string,
  "documents"?: number
  "imo.carriers.carrier.carrierName"?: string,
  "imo.agentCount"?: number,
  "company.id"?: number,
  "company.participants"?: number,
  "agent.carriers.carrier.carrierName"?: string,
  "agent.agentImos.agentImoCarriers.carrier.carrierName"?: string,
  "groupLink.groupName"?: string,
  "groupLink.createdAt"?: string,
  "groupLink.participants"?: number,
  "trustee.trusteeName"?: string,
  "trustee.email"?:string,
  "bank.bankName"?:string,
  "bank.email"?:string,
  "clientListName"?: string
  "agent.imo.region"? : string,
  "agent.user.name"?: string,
  "strategyName"?: string,
    "lastLogin"?:string,
    ""?: string,
    "trancheNo"?: string,
    "aggregator.aggregatorName"?:string,

    "imo.division.divisionName"?: string
}

export interface AccountHeadRow {
  id: keyof AccountSortList;
  label: string;
  hasSort?: boolean;
}

export interface Imo {
  id?: number,
  groupName?: string
}

export interface ImoTeamMemberList {
  id?: number,
  memberFirstName?: string
  memberLastName?: string
  memberType?: string
  inviteStatus?: string
  action?: any
}

export interface ImoList {
  data: Imo[]
}

export interface ImoTeamMemberHeadRow {
  id: keyof ImoTeamMemberList;
  label: string;
}

export interface GetAdminActivityLogs {
    userId: string
    log: string,
    logType:string,
    createdAt:string,
    user:{
        firstName: string,
        lastName: string
    }
}

export interface GetAdminActivityLogsList {
    rows: GetAdminActivityLogs[],
    count: number
}

export interface GetCompanyInviteData {
    data:any,
    nationalLife?: any
}

export interface AdminActivityLog {
    id: string,
    notes?: string
}

type DemoCarrier = {
    id: number,
    carrierName: string,
    growthRate?: number
}

export interface ProfileImage {
    image: string,
}

export type AccountOrder = 'asc' | 'desc';


export enum ImoDashboardListType {
	TotalAgentsInvited = "TOTAL_AGENTS_INVITED",
	TotalAgentAccounts = "TOTAL_AGENT_ACCOUNTS",
	TotalActiveAgents = "TOTAL_ACTIVE_AGENTS",
	TotalInActiveAgents = "TOTAL_INACTIVE_AGENTS",

  TotalGroups = "TOTAL_GROUPS",
	TotalGroupParticipants = "TOTAL_GROUP_PARTICIPANTS",
	TotalGroupEnrollments = "TOTAL_GROUP_ENROLLMENTS",

  TotalAgentLinkViews = "TOTAL_AGENT_LINK_VIEWS",
  TotalAgentLinkLeads = "TOTAL_AGENT_LINK_LEADS",
  TotalAgentLinkLeadsClosed = "TOTAL_AGENT_LINK_LEADS_CLOSED" 
}

export interface AggregatorTeamMemberList {
    id?: number,
    firstName?: string
    lastName?: string
    inviteStatus?: string
    divisionId?: number;
    action?: any
}

export interface AggregatorTeamMemberHeadRow {
    id: keyof AggregatorTeamMemberList;
    label: string;
}