import pdfIcon from "../../../../../styles/images/pdfIcon.png";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import React, { useEffect } from "react";
import { useActions } from "../../../../../actions";
import * as Aws from "../../../../../actions/aws";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { PreviewFileDialog } from "../../../../../pages/client/Enrollment/EnrollmentHome/basicInfo/ViewAndDelete";
type FileType = {
    name:string,
    viewTitle:string
}
type Props<T> = {
    file: T
    directory?: string // if  directory will pass through the props then we use it as the s3 bucket location by default it will search in the current login user enrollment directory
    onDelete?:(props:T) =>void,
    OnView?:(props:T) =>void
}
const PreviewFiles:<T extends FileType>(p: Props<T>) => React.ReactElement<Props<T>> = ({directory, onDelete , file, OnView}) =>{
    const [secureFilePath, setSecureFilePath] = React.useState();
    const awsActions = useActions(Aws);
    const [fileExt, setFileExt ] =  React.useState();
    const [popUpImage, setPopUpImage] = React.useState(false);
    const { user:{ enrollment, id:userId} } = useSelector((state: RootState) => state.auth);
    if(!directory){
        directory = ( enrollment && enrollment.id);
    }
    // fullFileName will be like Test.png
    const getSignedAndSecuredURL = async (fullFileName) => {
        let file = fullFileName.split(".");
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
        let queryObj = {
            objectName: fullFileName,
            contentType: contentType,
        };
        const s3url_backpath = await awsActions.getSecureUrl(directory, queryObj, true);
        setSecureFilePath(s3url_backpath);
        setFileExt(fileType);
    };
    useEffect(() => {
        if (file.name) {
            getSignedAndSecuredURL(file.name);
        }
    }, [file.name]);
    const viewDoucment = () =>{
        setPopUpImage(true);
    }
    return (<div className="imgWrap">
        {fileExt == "PDF" ?
                <img alt="Identity Licence"
                     style={{ maxWidth: 150, maxHeight: 150 }}
                     className="img-responsive floatLeft"
                     src={pdfIcon}
                     title="Identity Licence" />
                :
                <img alt="Identity Licence"
                     className="img-responsive floatLeft"
                     src={secureFilePath}
                     title="Identity Licence" />
        }
        <div className="btnfix">
            <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        OnView ? OnView(file) : viewDoucment();
                    }}
            >
                View
            </Button>
            {
                    onDelete && <Button color="primary" onClick={async () => {
                        onDelete(file);
                    }} variant="contained">
                        Delete
                    </Button>
            }
        </div>
        {popUpImage &&
                <PreviewFileDialog
                        fileExt={fileExt}
                        popUpImage={popUpImage}
                        title={file.viewTitle}
                        secureFilePath={secureFilePath}
                        onClose={() => {
                            setPopUpImage(false);
                        }}
                />
        }
    </div>)
}
export default PreviewFiles