import React, {memo, useState} from 'react';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {useActions} from "../../../../actions";
import * as Actions from "../../../../actions/account";
import {WEBSOCKET_URL} from "../../../../constants"
import {isDebug} from "../../../../utils/commonUtils";

interface SocketProps {
    isNfIUL?: boolean;
    handleResult:Function,
    setResponseData:Function,
    handleReset:Function,
    userId:any
    page:string,
    setIsReportRequestCompleted:Function,
    setLifeDeathBenefitSeeking: Function
    isReportRequestAutomated: boolean,
    handleButtonClick: Function,
    requestId: string|null,
    setIsEstimationDataLoading:Function,
    setIsReportDataLoading:Function,
    callingFrom: string,
    closeAfterFullProcess?:boolean,
    onCompleteReportDataLoading?:Function
}

const Socket = (props:SocketProps) => {
    const { handleResult,
        setResponseData,
        handleReset,
        userId,
        page,
        setIsReportRequestCompleted,
        setLifeDeathBenefitSeeking,
        isReportRequestAutomated,
        isNfIUL,
        handleButtonClick,
        requestId,
        setIsEstimationDataLoading,
        setIsReportDataLoading,
        callingFrom,
        closeAfterFullProcess,
        onCompleteReportDataLoading
    } = props;
    const accountActions = useActions(Actions);
    const isDebugMode = isDebug();
    const [gotRes, setGotRes] = useState(false);
    const {
        lastMessage,
        readyState,
    }:any = useWebSocket(WEBSOCKET_URL,{
        shouldReconnect: (closeEvent) => {
            return true;
        },
        onError: (event: WebSocketEventMap['error']) => {
            console.log("error event", event);
        },
        onOpen: (event: WebSocketEventMap['open']) => {
           // console.log("onOpen event", event);
        },
        onReconnectStop: (numAttempts: number) => {
            console.log("numAttempts", numAttempts);
        },
        queryParams: {userId, page},
        reconnectAttempts: 10,
        reconnectInterval: 3000,
        retryOnError: true
    });

    React.useEffect(() => {
        if(lastMessage && lastMessage.data) {
            let response:any = JSON.parse(lastMessage.data);
            let responseId:string = response && response.id;
            if(requestId === responseId) {
                if (response && response.success && Object.keys(response.success).length > 0) {
                    let data: any = response.success;
                    let msz = "Estimation Result Fetch Successfully";
                    if (isNfIUL) {
                        msz = "NF IUL Reports Processed Successfully";
                    }
                    if (data && data.reports === false) {
                        handleResult();
                        // if closeAfterFullProcess is false it will close loader
                        (!(closeAfterFullProcess)) && setIsEstimationDataLoading(false);
                        setResponseData(data);
                        localStorage.setItem("lastEstimatedTime", new Date().toString());
                        (!(closeAfterFullProcess)) && showMessage(true, `${msz}`).then(() => {
                        });
                        handleButtonClick(false);
                        if(typeof setIsReportDataLoading === 'function' ) {
                            setIsReportDataLoading(true);
                        }
                    } else {
                        if(typeof setIsReportDataLoading === 'function') {
                            setIsReportDataLoading(false);
                        }
                        console.info({closeAfterFullProcess, isNfIUL});
                        if(closeAfterFullProcess) {
                            setIsEstimationDataLoading(false);
                            showMessage(true, `${msz}`).then(() => {
                            });
                        }
                        if (!isReportRequestAutomated) {  // if ref button is clicked than we don't open the file popup
                            setIsReportRequestCompleted(true);
                        }

                        if (callingFrom === "INVITATION") {
                            msz = "Design Completed";
                            showMessage(true, `${msz}`).then(() => {
                            });
                        }

                    }
                    onCompleteReportDataLoading && onCompleteReportDataLoading(data)
                    localStorage.setItem("lifeDeathBenefitSeeking", data["run"]["deathbenefitonly"] === true ? "1" : "0");
                    setLifeDeathBenefitSeeking(data["run"]["deathbenefitonly"]);
                    setGotRes(true);
                } else {
                    handleResetWork();
                }
            }
        }
    }, [lastMessage]);

    const handleResetWork = () => {
        /*if (!viewDownloadClicked) {
            handleReset();
            setResponseData([]);
        }*/
        handleReset();
        setResponseData([]);
        setIsEstimationDataLoading(false);
        showMessage(false, "Something went wrong! Please try again").then(() => {
        });
    }

    const showMessage = async (status:boolean, message:string) => {
        await accountActions.setSnackBarMessage(status, message);
    }

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    return (
            <>
                {
                        isDebugMode &&
                        <pre>
                            {
                                JSON.stringify({
                                    callingFrom,
                                    closeAfterFullProcess,
                                    isNfIUL,
                                    isReportRequestAutomated,
                                    page,
                                    requestId,
                                    userId,
                                }, null, 2)
                            }
                        </pre>
                }
            </>
    );
};
export default memo(Socket);