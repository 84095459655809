import React from "react";
import { Grid } from "@material-ui/core";
import Assets from "./Assets";
import Liabilities from "./Liabilities";
import UnearnedIncomePastYear from "./UnearnedIncomePastYear";
import EarnedIncome from "./EarnedIncome";
import AttestationOfTruth from "./AttestationOfTruth";
import FinancialAgreement from "./FinancialAgreement";
import SourceOfFunds from "./SourceOfFunds";
import PremiumFinancing from "./PremiumFinancing";
import BusinessEquity from "./BusinessEquity";
import RealStateHoldings from "./RealStateHoldings";
import { NF_IUL_STRATEGY, StrategySlugEnum } from "../../../../../../constants";
const FinancialAndLiabilitiesContent = (props: any) => {
	const {
		clientDetail, isFromClientDetail, setFinancialAgreementRef, setPageChecked, setSourceOfFundsRef, setAttestRef,
		setAssetsVerifyRef, setAssetsVerified,
		setLiabilitiesVerifyRef, setLiabilitiesVerified,
		setEarnedVerifyRef, setEarnedVerified,
		setUnEarnedVerifyRef, setUnEarnedVerified,
		setFinancialAndLiabilitiesArr, financialAndLiabilitiesArr,
		financialAgreementFormRef,
		assetRef,
		liabilitiesRef,
		sourceOfFundsRefs,
		autoSaveTrigger,
		sendSaveRequest,
		setSaveFinancialAgreementForm,
		setSaveFormContent,
		setFormType,
		setSaveSourceOfFundsForm,
		earnedIncomeRef,
		unearnedIncomePastYearRef,
		premiumFinancingRefs,
        setDisableSubmit,
        setRealEstateVerifyRef, setRealEstateVerified,
        setBusinessEquityVerifyRef, setBusinessEquityVerified,
    } = props;

    let isDbErrorCount = 4;
    if( clientDetail && clientDetail.client && clientDetail.client.verifyAssets ){
        isDbErrorCount = isDbErrorCount - 1;
    }
    if( clientDetail && clientDetail.client &&  clientDetail.client.verifyLiabilities ){
        isDbErrorCount = isDbErrorCount - 1;
    }
    const [isErrorCount, setIsErrorCount] = React.useState(isDbErrorCount);
const isRealEstateFieldsTrue = assetRef && assetRef.current && assetRef.current.values;
    const isNfiUl = clientDetail.client && clientDetail.client.strategy && (clientDetail.client.strategy.slug === StrategySlugEnum.NfIUL && clientDetail.client.strategy.id === NF_IUL_STRATEGY);
	return (
		<Grid container spacing={2}>
			{!isFromClientDetail &&
				<Grid item xs={12} md={12}>
					<AttestationOfTruth
						checked={!!(clientDetail && clientDetail.client && clientDetail.client.agreeOnFinancialQuestion)}
						setPageChecked={setPageChecked}
						setAttestRef={setAttestRef}
						name={"FINANCIAL_LIABILITIES_QUESTION"}
						userData={clientDetail}
					/>
				</Grid>
			}
			<Grid item xs={12} md={12}>
				<FinancialAgreement
					user={clientDetail}
					isFromClientDetail={isFromClientDetail}
					setFinancialAgreementRef={setFinancialAgreementRef}
					setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
					financialAndLiabilitiesArr={financialAndLiabilitiesArr}
					financialAgreementFormRef={financialAgreementFormRef}
					setSaveFinancialAgreementForm={setSaveFinancialAgreementForm}
					formType={"FINANCIAL_AGREEMENT"}
					setFormType={setFormType}
					autoSaveTrigger={autoSaveTrigger}
                    setDisableSubmit={setDisableSubmit}
				/>
			</Grid>
			<Grid item xs={12} md={12}>
				<Assets
					user={clientDetail}
					isFromClientDetail={isFromClientDetail}
					setAssetsVerifyRef={setAssetsVerifyRef}
					setAssetsVerified={setAssetsVerified}
					setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
					financialAndLiabilitiesArr={financialAndLiabilitiesArr}
					assetRef={assetRef}
					setSaveFormContent={setSaveFormContent}
					formType={"ASSETS"}
					setFormType={setFormType}
					autoSaveTrigger={autoSaveTrigger}
				/>
			</Grid>
            { isRealEstateFieldsTrue &&
                    (isRealEstateFieldsTrue.primaryRealEstate > "0" ||
                    isRealEstateFieldsTrue.otherRealEstateHoldings > "0" ||
                    isRealEstateFieldsTrue.personalProperty > "0" )  &&
                <Grid item xs={12} md={12}>
                    <RealStateHoldings user={clientDetail} isFromClientDetail={isFromClientDetail}
                                       setRealEstateVerifyRef={setRealEstateVerifyRef}
                                       setRealEstateVerified={setRealEstateVerified}
                                       setIsError={(flag) => {
                                           if (flag) {
                                               setIsErrorCount(isErrorCount + 1);
                                           } else {
                                               setIsErrorCount(isErrorCount - 1);
                                           }
                                       }}
                    />
                </Grid>
            }
            { isRealEstateFieldsTrue &&
                    (isRealEstateFieldsTrue.businessEquity > "0") &&

                    <Grid item xs={12} md={12}>
                            <BusinessEquity user={clientDetail} isFromClientDetail={isFromClientDetail}
                                            setBusinessEquityVerifyRef={setBusinessEquityVerifyRef}
                                            setBusinessEquityVerified={setBusinessEquityVerified}
                                            setIsError={(flag) => {
                                                if (flag) {
                                                    setIsErrorCount(isErrorCount + 1);
                                                } else {
                                                    setIsErrorCount(isErrorCount - 1);
                                                }
                                            }}/>
                        </Grid>
            }
			<Grid item xs={12} md={12}>
				<Liabilities
					user={clientDetail}
					isFromClientDetail={isFromClientDetail}
					setLiabilitiesVerifyRef={setLiabilitiesVerifyRef}
					setLiabilitiesVerified={setLiabilitiesVerified}
					setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
					financialAndLiabilitiesArr={financialAndLiabilitiesArr}
					liabilitiesRef={liabilitiesRef}
					setSaveFormContent={setSaveFormContent}
					formType={"LIABILITIES"}
					setFormType={setFormType}
					autoSaveTrigger={autoSaveTrigger}
				/>
			</Grid>
			<React.Fragment>
				<Grid item xs={12} md={12}>
					<EarnedIncome
						user={clientDetail}
						isFromClientDetail={isFromClientDetail}
						setEarnedVerifyRef={setEarnedVerifyRef}
						setEarnedVerified={setEarnedVerified}
						setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
						financialAndLiabilitiesArr={financialAndLiabilitiesArr}
						earnedIncomeRef={earnedIncomeRef}
						setSaveFormContent={setSaveFormContent}
						formType={"EARNED"}
						setFormType={setFormType}
						autoSaveTrigger={autoSaveTrigger}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<UnearnedIncomePastYear
						user={clientDetail}
						isFromClientDetail={isFromClientDetail}
						setUnEarnedVerifyRef={setUnEarnedVerifyRef}
						setUnEarnedVerified={setUnEarnedVerified}
						setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
						financialAndLiabilitiesArr={financialAndLiabilitiesArr}
						unearnedIncomePastYearRef={unearnedIncomePastYearRef}
						setSaveFormContent={setSaveFormContent}
						formType={"UN_EARNED"}
						setFormType={setFormType}
						autoSaveTrigger={autoSaveTrigger}
					/>
				</Grid>
			</React.Fragment>
			<Grid item xs={12} md={12}>
				<SourceOfFunds
					autoSaveTrigger={autoSaveTrigger}
					user={clientDetail}
					isFromClientDetail={isFromClientDetail}
					setSourceOfFundsRef={setSourceOfFundsRef}
					setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
					financialAndLiabilitiesArr={financialAndLiabilitiesArr}
					sourceOfFundsRefs={sourceOfFundsRefs}
					formType={"SOURCE_OF_FUNDS"}
					setFormType={setFormType}
					setSaveSourceOfFundsForm={setSaveSourceOfFundsForm}
				/>
			</Grid>
            {
                !isNfiUl &&
                    <Grid item xs={12}>
                        <PremiumFinancing
                                autoSaveTrigger={autoSaveTrigger}
                                clientDetail={clientDetail}
                                isFromClientDetail={isFromClientDetail}
                                premiumFinancingRefs={premiumFinancingRefs}
                                setFinancialAndLiabilitiesArr={setFinancialAndLiabilitiesArr}
                                financialAndLiabilitiesArr={financialAndLiabilitiesArr}
                                sendSaveRequest={sendSaveRequest}
                        />
                    </Grid>
            }

		</Grid>
	);
};

export default FinancialAndLiabilitiesContent;