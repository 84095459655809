import * as React from "react";
import {
    Card,
    CardContent,
    Grid,
    Typography,
    CardHeader,
    ListItem,
    ListItemIcon, ListItemText, List,
} from "@material-ui/core";
import ReactPlayer from "react-player";
import icon_smart_leverage
    from "../../../../components/client/landingPage/v2/images/sectionThree/icon_smart_leverage.svg";
import icon_more_protection
    from "../../../../components/client/landingPage/v2/images/sectionThree/icon_more_protection.svg";

import icon_potential_growth
    from "../../../../components/client/landingPage/v2/images/sectionThree/icon_potential_growth.svg";
import CheckIcon from "@material-ui/icons/Check";

const LandingSectionThree = (props: any) => {
    const strategy: string = props.strategyData.displayName;
    const strategyText:string = props.strategyData.text;
    const nationalLife:any = props.nationalLife;
    let carrier: any = (props.carrier && props.carrier) ? props.carrier :  {
        id : 0,
        name : '',
        value : '',
        key : ''
    };

    let prortection:any = [
        {
            primaryText: "Death benefit",
            secondaryText: "with living benefit riders.",
        },
        {
            primaryText: "Chronic illness",
            secondaryText: "",
        },
        {
            primaryText: "Terminal illness",
            secondaryText: "",
        },
    ];

    if( nationalLife && nationalLife.id === carrier.id ){
        prortection = [
            {
                primaryText: "Critical Illness",
                secondaryText: "",
            },
            {
                primaryText: "Critical Injury",
                secondaryText: "",
            }
        ];
    }

    let cards: any = [
        {
            cardHeader: {
                src: icon_smart_leverage,
                title: "Smart",
                subheader: "Leverage",
            },
            cardContent: {
                listItem: [
                    {
                        primaryText: "No credit checks",
                        secondaryText: "",
                    },
                    {
                        primaryText: "No loan documents",
                        secondaryText: "",
                    },
                    {
                        primaryText: "No personal guarantees",
                        secondaryText: "",
                    },
                    {
                        primaryText: "No interest payments",
                        secondaryText: "",
                    },
                ],
            },
        },
        {
            cardHeader: {
                src: icon_more_protection,
                title: "More",
                subheader: "Protection",
            },
            cardContent: {
                listItem: prortection,
            },
        },
        {
            cardHeader: {
                src: icon_potential_growth,
                title: "More",
                subheader: "Potential Growth",
            },
            cardContent: {
                listItem: [
                    {
                        primaryText: "Upside crediting",
                        secondaryText: "(subject to cap)",
                    },
                    {
                        primaryText: "No negative returns",
                        secondaryText: "(no loss due to market declines)",
                    },
                    {
                        primaryText: "Tax-free distributions",
                        secondaryText: "(potential to access cash value using tax-free policy loans)",
                    },
                ],
            },
        },
    ];

    return (
            <div className="sectionPadding">
                <Grid container justify="center">
                    <Grid item xs={12} md={11} lg={10} xl={9} className="pL7Rem">
                        <Grid container>
                            <Grid item xs={12} md={7} lg={7} data-aos="fade-up" data-aos-delay="100"
                                  data-aos-once="false" data-aos-duration="1000">
                                <span className="section-marker three"/>
                                <Typography variant="h1" align="left" className="tittle">
                                    {strategy} is different!
                                </Typography>
                                <Typography variant="body1" align="left">{strategy} is the only
                                    strategy that provides you the opportunity to add up to 3 times more money to fund a
                                    cash accumulating life insurance policy. Your contribution and the policy provide
                                    the
                                    security needed to obtain the loan and your participation is easy. Simply create an
                                    account, estimate your short 5-year annual contribution amount, and
                                    let {strategy} leverage do the rest.

                                </Typography>
                                <Typography variant="body1" align="left">{strategy} was
                                    designed to minimize expenses and maximize your potential for accumulation. We then
                                    combined it with leverage to provide you the potential for 60-100% more:*
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5} lg={5} data-aos="fade-left">
                                <ReactPlayer
                                        className="videos"
                                        controls={false}
                                        playing={false}
                                        loop={true}
                                        volume={0}
                                        muted={true}
                                        url="https://niw-staging.s3.us-east-1.amazonaws.com/landing/animation-kai-zen-solution.mp4"
                                        width='100%'
                                        height='800px'
                                        playsinline={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={4} className="MoveTop">
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" component="strong">
                                    {strategy} advantage:
                                </Typography>
                            </Grid>
                            {cards.length > 0 &&
                            cards.map((card: any, index:number) => {
                                return (
                                        <Grid key={index} item xs={12} md={4} lg={4} data-aos="fade-up"
                                              data-aos-duration="1000">
                                            <Card className="muiCard hT400">
                                                <CardHeader
                                                        className="cardHead"
                                                        avatar={
                                                            <img alt={"cardHeader"} src={card.cardHeader.src} aria-label="recipe"/>
                                                        }
                                                        title={card.cardHeader.title}
                                                        subheader={card.cardHeader.subheader}
                                                />
                                                <CardContent className="muicardContent">
                                                    <List className="advantageList">
                                                        {card.cardContent.listItem.length > 0 &&
                                                        card.cardContent.listItem.map((listItem: any, index:number) => {
                                                            return (
                                                                    <ListItem key={index} >
                                                                        <ListItemIcon>
                                                                            <CheckIcon className="textHighLight"/>
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                                primary={listItem.primaryText}
                                                                                secondary={listItem.secondaryText}
                                                                        />
                                                                    </ListItem>
                                                            );
                                                        })
                                                        }
                                                    </List>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                );
                            })
                            }

                            <Grid item xs={12}>
                                <Typography align="left" className="description">*{strategyText !== "Leveraged Savings Plan" && 'The' } {strategy} Strategy is dependent
                                    on the client making
                                    contributions for the first 5 years therefore not defaulting on the policy, which
                                    could result in policy lapse and surrender charges. The client will not have access
                                    to the policy, the cash values, the death benefits or the living benefits until the
                                    loan is repaid and the assignment is released. The lender has the right to
                                    discontinue funding new premiums, exit the market, or to demand loan repayment based
                                    on the terms and conditions signed by the Master Trust. See the Master Trust
                                    documents for additional information. There are some exceptions to this rule. Please
                                    consult a tax professional for advice concerning your individual situation.
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
    );
};

export default LandingSectionThree;
