import React from 'react';
import { Button, Grid } from "@material-ui/core";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { isFunc } from "../../../../../utils/commonUtils";

function ResolvedFor(props:{setEditLifeTimeDeathBenefitAmount:Function, setEditCalculationInputs:Function, resolveFor:string|null, resolveForBtn:string, callingFrom?: string})  {
    const {setEditLifeTimeDeathBenefitAmount, setEditCalculationInputs, resolveFor, resolveForBtn, callingFrom} = props;
    return <Grid item xs={12} md={callingFrom === "ESTIMATOR" ? 6 : 12}>
        <Button
            color="primary"
            size="small"
            variant="outlined"
            startIcon={<BorderColorIcon/>}
            onClick={() => {
                if (resolveFor === "Contribution_Amount") {
                    console.info(setEditLifeTimeDeathBenefitAmount && isFunc(setEditLifeTimeDeathBenefitAmount) && setEditLifeTimeDeathBenefitAmount(true),"8+8+8+8");
                    setEditLifeTimeDeathBenefitAmount && isFunc(setEditLifeTimeDeathBenefitAmount) && setEditLifeTimeDeathBenefitAmount(true);
                    // setEditLifeTimeDeathBenefitAmount(true);
                } else if (resolveFor === "Death_Benefit") {
                    setEditCalculationInputs && isFunc(setEditCalculationInputs) && setEditCalculationInputs(true);
                    // setEditCalculationInputs(true);
                } else {
                    setEditLifeTimeDeathBenefitAmount && isFunc(setEditLifeTimeDeathBenefitAmount) && setEditLifeTimeDeathBenefitAmount(false);
                    setEditCalculationInputs && isFunc(setEditCalculationInputs) && setEditCalculationInputs(false);
                    // setEditLifeTimeDeathBenefitAmount(false);
                    // setEditCalculationInputs(false);
                }
            }}
        >
            {resolveForBtn}
        </Button>
    </Grid>

}

export default ResolvedFor;