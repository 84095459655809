import React, { useEffect } from "react";
import Typography from "@material-ui/core/Typography/Typography";
import pdfIcon from "../../../../../styles/images/pdfIcon.png";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import * as Aws from "../../../../../actions/aws";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/account";
import { ClientDocumentType } from "../../../../../constants";

const ViewAndDelete = (props) => {
	let {
		identityPhoto,
		clientSigned,
		title,
		action,
		userId,
		setIdentityFrontPrevPhoto,
		setIdentityBackPrevPhoto,
		setUtilityBillPrevPhoto,
		setResidenceFrontPrevPhoto,
		setResidenceBackPrevPhoto,
		directory,
		setTaxDocumentYearOne,
		setTaxDocumentYearTwo,
		setOtherDocPrevPhoto,
		setGreatWealthTransferCoverLetter,
		setGreatWealthTransferDisclosureLetter,
		setUploadDriversLicenseGEN1GWT,
		setUploadDriversLicenseBackGEN1GWT,
		setUploadFinancialStatementForms,
		docName,
		closeConfDialog = false,
		emptyDocName1 = null,
		emptyDocName2 = null,
		docSubName,
		setActionObj1,
		setActionObj2,
		docusignStatus,
        enableDelete = false
	} = props;
	const awsActions = useActions(Aws);
	const accountActions = useActions(Actions);
	const [secureFilePath, setSecureFilePath] = React.useState("");
	const [fileExt, setFileExt] = React.useState("");
	const [popUpImage, setPopUpImage] = React.useState(false);
	const [confirmationWindow, setConfirmationWindow] = React.useState<boolean>(false);
	useEffect(() => {
		if (identityPhoto) {
			getSignedAndSecuredURL(identityPhoto);
		}
	}, [identityPhoto]);

	const deleteDocument = async (documentKey: string, documentType: string, documentSubType: string) => {
		let payLoad: any = {};
		if (documentType === "IDENTITY_DOCUMENT") {
			if (documentSubType === "front") {
				setIdentityFrontPrevPhoto("");
				payLoad = {
					documentKey: documentKey,
					documentType: documentType,
					formType: "documents",
					identityFrontPhoto: "",
					documentSubType: documentSubType,
					operation: "Deleted",
				};
			} else {
				setIdentityBackPrevPhoto("");
				payLoad = {
					documentKey: documentKey,
					documentType: documentType,
					formType: "documents",
					identityBackPhoto: "",
					documentSubType: documentSubType,
					operation: "Deleted",
				};
			}
		}

		if (documentType === "UTILITY_BILL") {
			setUtilityBillPrevPhoto("");
			payLoad = {
				documentKey: documentKey,
				documentType: documentType,
				formType: "documents",
				utilityBillPhoto: "",
				operation: "Deleted",
			};
		}

		if (documentType === "RESIDENCE_DOCUMENT") {
			if (documentSubType === "front") {
				setResidenceFrontPrevPhoto("");
				payLoad = {
					documentKey: documentKey,
					documentType: documentType,
					formType: "documents",
					residenceFrontPhoto: "",
					documentSubType: documentSubType,
					operation: "Deleted",
				};
			} else {
				setResidenceBackPrevPhoto("");
				payLoad = {
					documentKey: documentKey,
					documentType: documentType,
					formType: "documents",
					residenceBackPhoto: "",
					documentSubType: documentSubType,
					operation: "Deleted",
				};
			}
		}

		if (documentType === "TAX_DOCUMENT") {
			if (documentSubType === "YEAR_ONE") {
				setTaxDocumentYearOne("");
				payLoad = {
					documentKey: documentKey,
					documentType: documentType,
					formType: "documents",
					documentSubType: documentSubType,
					operation: "Deleted",
				};
			} else {
				setTaxDocumentYearTwo("");
				payLoad = {
					documentKey: documentKey,
					documentType: documentType,
					formType: "documents",
					documentSubType: documentSubType,
					operation: "Deleted",
				};
			}
		}

		if (documentType === "MORE_DOCUMENTS" && documentKey == "OtherDocument") {
			setOtherDocPrevPhoto("");
			payLoad = {
				documentKey: documentKey,
				documentType: documentType,
				formType: "documents",
				otherDocument: "",
				operation: "Deleted",
			};
		}

		if (documentType === "ADDITIONAL_DOCUMENTS" && documentKey == "AdditionalDocument") {
			setOtherDocPrevPhoto("");
			payLoad = {
				documentKey: documentKey,
				documentType: documentType,
				formType: "documents",
				additionalDocument: "",
				operation: "Deleted",
			};
			if (docName) {
				payLoad.docName = docName;
			}
		}
		if (documentType === "COVER_LETTER" && documentKey == "coverLetter") {
			payLoad = {
				docName: "Cover Letter",
				documentKey: documentKey,
				documentType: documentType,
				formType: "documents",
				otherDocument: "",
				operation: "Deleted",
			};
		}
		if (documentType === "GWT_COVER_LETTER" && documentKey == "GWT_COVER_LETTER") {
			setGreatWealthTransferCoverLetter("");
			payLoad = {
				documentKey: documentKey,
				docSubName: documentSubType,
				documentType: ClientDocumentType.GWT_COVER_LETTER,
				formType: "documents",
				documentSubType: documentSubType,
				operation: "Deleted",
			};
		}
		if (documentType === "GWT_DISCLOSURE_LETTER" && documentKey == "GWT_DISCLOSURE_LETTER") {
			setGreatWealthTransferDisclosureLetter("");
			payLoad = {
				documentKey: documentKey,
				docSubName: documentSubType,
				documentType: ClientDocumentType.GWT_DISCLOSURE_LETTER,
				formType: "documents",
				documentSubType: documentSubType,
				operation: "Deleted",
			};
		}
		if (documentType === "GWT_DRIVERS_LICENSE" && documentKey == "GWT_DRIVERS_LICENSE") {
			setUploadDriversLicenseGEN1GWT("");
			payLoad = {
				documentKey: documentKey,
				docSubName: documentSubType,
				documentType: ClientDocumentType.GWT_DRIVERS_LICENSE,
				formType: "documents",
				documentSubType: documentSubType,
				operation: "Deleted",
			};
		}
		if (documentType === "GWT_DRIVERS_LICENSE_BACK" && documentKey == "GWT_DRIVERS_LICENSE_BACK") {
			setUploadDriversLicenseBackGEN1GWT("");
			payLoad = {
				documentKey: documentKey,
				docSubName: documentSubType,
				documentType: ClientDocumentType.GWT_DRIVERS_LICENSE_BACK,
				formType: "documents",
				documentSubType: documentSubType,
				operation: "Deleted",
			};
		}
		if (documentType === "GWT_FINANCIAL_STATEMENT" && documentKey == "GWT_FINANCIAL_STATEMENT") {
			setUploadFinancialStatementForms("");
			payLoad = {
				documentKey: documentKey,
				docSubName: documentSubType,
				documentType: ClientDocumentType.GWT_FINANCIAL_STATEMENT,
				formType: "documents",
				documentSubType: documentSubType,
				operation: "Deleted",
			};
		}
		if (documentType === "GWT_TAX_DOCUMENT_YEAR_ONE" && documentKey == "GWT_TAX_DOCUMENT_YEAR_ONE") {
			setTaxDocumentYearOne("");
			payLoad = {
				documentKey: documentKey,
				docSubName: documentSubType,
				documentType: ClientDocumentType.GWT_TAX_DOCUMENT_YEAR_ONE,
				formType: "documents",
				documentSubType: documentSubType,
				operation: "Deleted",
			};
		}
		if (documentType === "GWT_TAX_DOCUMENT_YEAR_TWO" && documentKey == "GWT_TAX_DOCUMENT_YEAR_TWO") {
			setTaxDocumentYearTwo("");
			payLoad = {
				documentKey: documentKey,
				docSubName: documentSubType,
				documentType: ClientDocumentType.GWT_TAX_DOCUMENT_YEAR_TWO,
				formType: "documents",
				documentSubType: documentSubType,
				operation: "Deleted",
			};
		}
		if (documentType === "CARRIER_ILLUSTRATION_INPUTS" && documentKey == "carrierIllustrationInputs") {
			payLoad = {
				docName: "Carrier Illustration Inputs",
				documentKey: documentKey,
				documentType: documentType,
				formType: "documents",
				otherDocument: "",
				operation: "Deleted",
			};
		}

		if (documentType === "TAX_DOCUMENT_INCOME_EARNER" || documentType === "TAX_DOCUMENT_SPOUSE" || documentType === "TAX_DOCUMENT_WEALTH_TRANSFER") {
			if (documentSubType === "YEAR_ONE") {
				setTaxDocumentYearOne("");
				if (typeof setActionObj1 === "function")
					setActionObj1({});
				payLoad = {
					documentKey: documentKey,
					documentType: documentType,
					formType: "documents",
					documentSubType: documentSubType,
					operation: "Deleted",
					docSubName: docSubName || "",
				};
			} else {
				setTaxDocumentYearTwo("");
				if (typeof setActionObj2 === "function")
					setActionObj2({});
				payLoad = {
					documentKey: documentKey,
					documentType: documentType,
					formType: "documents",
					documentSubType: documentSubType,
					operation: "Deleted",
					docSubName: docSubName || "",
				};
			}
		}
        if(documentType === ClientDocumentType.SUPPORTING_DOCUMENTS){
            payLoad = {
                documentKey: documentKey,
                documentType: documentType,
                formType: "documents",
                documentSubType: documentSubType,
                operation: "Deleted",
                docSubName: docSubName || "",
            };
        }

		await accountActions.updateUserBasicDetails(payLoad, userId);
		if (typeof emptyDocName1 === "function") {
			emptyDocName1();
		}
		if (typeof emptyDocName2 === "function") {
			emptyDocName2();
		}
	};

	const getSignedAndSecuredURL = async (identityPhoto) => {
		let file = identityPhoto.split(".");
		let fileType = file[file.length - 1].toUpperCase();
		const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
		let queryObj = {
			objectName: identityPhoto,
			contentType: contentType,
		};
		const s3url_backpath = await awsActions.getSecureUrl(directory, queryObj, true);
		setSecureFilePath(s3url_backpath);
		setFileExt(fileType);
	};

	const sideText = (txt: string) => {
		if (txt === "YEAR_ONE") {
			return "Year 1 (most recent)";
		}
		if (txt === "YEAR_TWO") {
			return "Year 2 (1yr prior)";
		}
		return txt;
	};

	const openConfirmation = () => {
		setConfirmationWindow(true);
	};

	return (
		<React.Fragment>

			<div className="imgWrap">
				{fileExt == "PDF" ?
					<img alt="Identity Licence"
						 style={{ maxWidth: 150, maxHeight: 150 }}
						 className="img-responsive floatLeft"
						 src={pdfIcon}
						 title="Identity Licence" />
					:
					<img alt="Identity Licence"
						 className="img-responsive floatLeft"
						 src={secureFilePath}
						 title="Identity Licence" />
				}
				<div className="btnfix">
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							setPopUpImage(true);
						}}
					>
						View
					</Button>
					{((!clientSigned && docusignStatus < 2) || enableDelete) && // when the docusign status is less then < 2
						<Button color="primary" onClick={async () => {
							openConfirmation();
						}} variant="contained">Delete</Button>
					}
				</div>
				<Typography variant="caption"
							className="floatLeft w100">{sideText(action.side)}</Typography>
			</div>
			{popUpImage &&
				<PreviewFileDialog
					fileExt={fileExt}
					popUpImage={popUpImage}
					title={title}
					secureFilePath={secureFilePath}
					onClose={() => {
						setPopUpImage(false);
					}}
				/>
			}

			{confirmationWindow &&
				<Dialog
					fullWidth={true}
					maxWidth="sm" aria-labelledby="customized-dialog-title"
					open={confirmationWindow}>
					<DialogContent dividers>
						<Typography variant="body1" className="mb0"> Are you sure to delete?</Typography>
					</DialogContent>
					<DialogActions>
						<Button
							size="small"
							variant="contained"
							color="primary"
							onClick={async () => {
								await deleteDocument(action.key, action.type, action.side);
								setTimeout(async () => {
									await accountActions.getProfileDetails();
								}, 500);
								if (closeConfDialog) {
									setConfirmationWindow(false);
								}
							}}
						>
							YES
						</Button>
						<Button
							className="bgRed"
							size="small"
							variant="contained"
							color="primary"
							onClick={() => setConfirmationWindow(false)}
						>
							NO
						</Button>
					</DialogActions>
				</Dialog>
			}
		</React.Fragment>
	);
};

export const PreviewFileDialog = ({ fileExt, popUpImage, title, secureFilePath, onClose }) => {
	return (
		<Dialog
			fullWidth={fileExt === "PDF"}
			maxWidth={fileExt === "PDF" ? "md" : "sm"}
			open={popUpImage}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<Typography variant="h6" className="mt20 mb0 ml15"> {title} </Typography>
			<DialogContent>
				<div className={fileExt === "PDF" ? "" : "imgWrap"}>
					{fileExt === "PDF" ?
						<object data={`${secureFilePath}#toolbar=0`} type="application/pdf"
								style={{ width: "100%", height: "80vh" }}>
							<embed src={`${secureFilePath}#toolbar=0`} type="application/pdf" />
						</object>
						:
						<img width="100%" alt="Uploaded Document"
							 className="img-responsive"
							 src={secureFilePath}
							 title="Identity Licence"
						/>}
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					size="small"
					className="mb10 mr15"
					type="submit"
					variant="contained"
					color="primary"
					onClick={onClose}
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ViewAndDelete;