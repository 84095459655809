import * as React from "react";
import { useEffect, useState } from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { useActions } from "../../../../../actions";
import { Formik } from "formik";
import * as AccountActions from "../../../../../actions/account";
import {
    PASSWORD_VALIDATE_REGEX,
    PWD_MIN_EIGHT_CHAR_REGEX,
    PWD_ONE_CAPITAL_LETTER_REGEX,
    PWD_ONE_LETTER_REGEX,
    PWD_ONE_NUMBER_REGEX,
    PWD_ONE_SPECIAL_CHAR,
} from "../../../../../constants";
import PasswordRequired from "./PasswordRequired";
import { ErrorCommon, TextField, TextFieldCommon } from "../../../../../components/formikFormInputs";
import { Button, Label } from "../../../../../components/formInputs";
import LandingContactForm from "../../v2/LandingContactForm";
import moment from "moment";
import GroupEstimationForm from "../../../../../components/client/Estimation/Group/GroupEstimation";
import { pez } from "../../../../../utils/commonUtils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const UnAuthenticationForm = (props: any) => {
    const [newPassword, setNewPassword] = React.useState("");
    const [newPasswordError, setNewPasswordError] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
    const { notAuthenticat, strategyData } = props;
    const [oneLetter, setOneLetter] = React.useState(false);
    const [oneNumber, setOneNumber] = React.useState(false);
    const [oneCapitalLetter, setOneCapitalLetter] = React.useState(false);
    const [eightCharacter, setEightCharacter] = React.useState(false);
    const [oneSpecialChar, setOneSpecialChar] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [showPasswordRequirementSection, setShowPasswordRequirementSection] = React.useState<boolean>(false);
    const accountActions = useActions(AccountActions);


    let searchParams: string = props && props.location.search;
    const UrlClass = new URLSearchParams(searchParams)
    const landing = UrlClass.get("landing");
    const emailFromParam = UrlClass.get("token") ? atob((UrlClass.get("token")) as string) : "";
    const [activeForm, setActiveForm] = useState(Number(landing));
    const [timerFinished, setTimerFinished] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const goToTop: () => void =  () => window.scrollTo(0, 0);


    let expDate: string | null = (props && props.groupDetail && props.groupDetail.linkExpiry) || null;
    const onPassWordChange = (e: any): void => {
        const val = e.target.value;
        PWD_ONE_LETTER_REGEX.test(val) ? setOneLetter(true) : setOneLetter(false);
        PWD_ONE_CAPITAL_LETTER_REGEX.test(val) ? setOneCapitalLetter(true) : setOneCapitalLetter(false);
        PWD_ONE_NUMBER_REGEX.test(val) ? setOneNumber(true) : setOneNumber(false);
        PWD_MIN_EIGHT_CHAR_REGEX.test(val) ? setEightCharacter(true) : setEightCharacter(false);
        PWD_ONE_SPECIAL_CHAR.test(val) ? setOneSpecialChar(true) : setOneSpecialChar(false);
        setNewPassword(e.target.value);

        val ?
                ((!PWD_ONE_LETTER_REGEX.test(val) || !PWD_ONE_CAPITAL_LETTER_REGEX.test(val) || !PWD_ONE_NUMBER_REGEX.test(val) || !PWD_MIN_EIGHT_CHAR_REGEX.test(val) || !PWD_ONE_SPECIAL_CHAR.test(val)) ? setNewPasswordError("Enter valid password") : setNewPasswordError(""))
                :
                setNewPasswordError("Required");
    };


    let redirectUrl: string = props && props.match && props.match.url;
    let encodeUrl: string = "";
    let url: string = "";
    encodeUrl = btoa(redirectUrl);
    if (searchParams) {
        encodeUrl = btoa(redirectUrl + searchParams);
    }
    if (redirectUrl) {
        url = `/login?token=${encodeUrl}`;
    }


    const fieldObj = [
        {
            label: <Label label="First Name" required={true} />,
            name: "firstName",
            placeholder: "First Name",
            id: "firstName",
            component: TextField,
            type: "text",
        }, {
            label: <Label label="Last Name" required={true} />,
            name: "lastName",
            placeholder: "Last Name",
            id: "lastName",
            component: TextField,
            type: "text",
        }, {
            label: <Label label="Email" required={true} />,
            name: "email",
            placeholder: "email",
            id: "email",
            component: TextField,
            type: "text",
        }, {
            label: <Label label="Password" required={true} />,
            name: "password",
            placeholder: "Password",
            id: "password",
            component: TextField,
            type: "password",

        }, {
            label: <Label label="Confirm Password" required={true} />,
            name: "confirmPassword",
            placeholder: "confirmPassword",
            id: "confirmPassword",
            component: TextField,
            type: "password"
        }];

    const fieldObj1 = [{
        label: <Label label="Otp" required={true} />,
        name: "otp",
        placeholder: "Enter OTP",
        id: "otp",
        component: TextField,
        type: "password",
        autoComplete:"off",
    }];

    const fields = [
        [],
        fieldObj,
        fieldObj1
    ]

    const intitialValues = {
        firstName: "",
        lastName: "",
        email: emailFromParam || "",
        password: "",
        confirmPassword: ""
    };

    const validate = ((value: any) => {
        const errors: {
            firstName?: string,
            lastName?: string,
            email?: string,
            password?: string | boolean,
            confirmPassword?: string
            otp?: string,
        } = {};
        switch (activeForm) {
            case 1:
                if (!value.firstName)
                    errors.firstName = "Required";
                if (!value.lastName)
                    errors.lastName = "Required";
                if (!value.email)
                    errors.email = "Required";
                if (!value.password)
                    errors.password = 'Required';
                if (!value.confirmPassword) {
                    errors.confirmPassword = 'Required';
                } else if (value.confirmPassword !== value.password) {
                    errors.confirmPassword = 'Password mismatched';
                }
                if (value.password && !PASSWORD_VALIDATE_REGEX.test(value.password)) {
                    errors.password = true;
                }
                break;
            case 2:
                if (!value.otp) {
                    errors.otp = 'Required';
                }
                break
        }
        return errors;
    });

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000); // Update every second

        // Clear the timer and set timerFinished to true when time is up
        if (timeLeft === 0 || timeLeft < 0) {
            clearInterval(timer);
            setTimerFinished(true);
        }

        // Clear the timer when the component unmounts
        return () => clearInterval(timer);
    }, [timeLeft]);

    const handleResendClick = async (values) => {
        let payload: any = { email: values["email"] || emailFromParam };
        let details: any = await accountActions.resendOtp(payload);
        if (details) {
            setTimeLeft(5 * 60);
            setTimerFinished(false);
        }

    };
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };


    const submitFormik = async (values: any, setSubmitting: Function, resetForm: Function) => {

        switch (activeForm) {
            case 1:
                let payload: any = { companyLinkUrl: props && props.groupDetail && props.groupDetail.companyLinkUrl, ...values };
                let details: any = await accountActions.createGroupClient(payload);
                if (details && details.id) {
                    setActiveForm((activeForm) => activeForm + 1)
                    props.history.push({
                        pathname: `${props.location.pathname}`,
                        search: `?${new URLSearchParams({ landing: "2", token: btoa(pez(values["email"] as string)) })}`,
                    })
                    setTimeLeft(5*60)
                    setTimerFinished(false);
                }
                break;
            case 2:
                let payload1: any = { otp: values["otp"], email: values["email"] };
                let res: any = await accountActions.verifyGroupClient(payload1);
                if (res && res.type != 0) {
                    resetForm();
                    // props.history.push("/login");
                  window.location.href=("/login");
                }

                break;
        }

    };

    const forms = [
        {
            buttonText: "",
            bottomTitleText: "",
            fields: fieldObj,
            inv: { firstName: "", lastName: "", email: "" },
            titleText: "",
            subTitleText: "",
        },
        {
            buttonText: "CONTINUE",
            bottomTitleText: "",
            fields: fieldObj,
            inv: { firstName: "", lastName: "", email: "" },
            titleText: "Create Your Account",
            subTitleText: "",
        },
        {
            buttonText: "Confirm OTP",
            bottomTitleText: "Codes can take up to 5 minutes to arrive. Check your spam folder.",
            fields: fieldObj1,
            inv: { otp: "" },
            titleText: (<>Please confirm the One Time Pass-code (OTP)<br/>sent to the email you registered with.</>),
            subTitleText: "Please check your email for one-time verifications code so we can confirm your ID.",

        },
    ];

    const headings ={
        0: <>
            <Typography
                    align="left"
                    className="textHighLight floatLeft w100 mb0 mt20"
                    component="strong"
                    id="get-started"
                    variant="body1"
            >
                GET STARTED
            </Typography>
            <Typography
                    variant="h3"
                    className="floatLeft w100 mb15">
                See what your {strategyData["displayName"]} looks like.
            </Typography>
        </>,
        1:<>
        </>,
        2:<>

        </>,


}


    return (
            <React.Fragment>
                <Grid container spacing={3}>
                    {headings[activeForm]}
                    {activeForm == 0 ?
                            <GroupEstimationForm onSubmit ={()=>{
                                props.history.push({
                                    pathname: `${props.location.pathname}`,
                                    search: `?${new URLSearchParams({ landing: "1" })}`,
                                });
                                setActiveForm(activeForm+1)
                            }} />
                            :
                            <>
                                <Formik
                                        initialValues={intitialValues}
                                        validate={values => validate(values)}
                                        onSubmit={(values, {
                                            setSubmitting,
                                            setErrors,
                                            setStatus,
                                            resetForm,
                                        }) => submitFormik(values, setSubmitting, resetForm)}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          dirty,
                                          setFieldValue,
                                      }) => {
                                        return (
                                                <form className="floatLeft w100" onSubmit={handleSubmit} autoComplete="off">
                                                    <Typography variant="subtitle1" color="primary" className="f22 mb15">
                                                        {forms[activeForm].titleText}
                                                    </Typography>
                                                    <Grid container spacing={3}>
                                                        {
                                                            fields[activeForm].map((field: any, index) => {
                                                                return (
                                                                        <React.Fragment key={index}>
                                                                            <Grid item xs={12} sm={12} md={12}
                                                                                  className={""}>
                                                                                <TextFieldCommon
                                                                                        {...field}
                                                                                        values={values}
                                                                                        onChange={(e: any) => {
                                                                                            handleChange(e);
                                                                                            if (field.name === "password") onPassWordChange(e);
                                                                                        }}
                                                                                        onBlur={handleBlur}
                                                                                        onClick={(e) => {
                                                                                            if (field.name === "password" || field.name === "confirmPassword") {
                                                                                                setShowPasswordRequirementSection(true);
                                                                                            }
                                                                                        }}
                                                                                />
                                                                                <ErrorCommon errors={errors}
                                                                                             name={field.name}
                                                                                             touched={touched} />
                                                                            </Grid>
                                                                        </React.Fragment>);
                                                            })
                                                        }
                                                        {
                                                                activeForm === 2 && <>
                                                                    <Grid item xs={12} sm={12} md={12}>
                                                                        <Typography variant="caption" className="floatLeft w100 " color="primary">Check spam for otp.</Typography>
                                                                        <Typography variant="caption" className="floatLeft w100 " color="primary">Allow upto 5 min to receive otp.</Typography>
                                                                        {!timerFinished &&
                                                                            <Typography variant="caption"
                                                                                        className="floatLeft w100 " color="primary">
                                                                                        Resend OTP in {formatTime(timeLeft)}
                                                                            </Typography>
                                                                        }
                                                                        {timerFinished && (
                                                                                <Link variant="caption"
                                                                                      className="floatLeft w100  textUnderLine"
                                                                                      color="primary"
                                                                                      onClick={ () =>   handleResendClick(values) }>Click here to resend new otp</Link>
                                                                        )}
                                                                    </Grid></>
                                                        }

                                                        <Grid item xs={12} sm={12} md={12}>
                                                            {showPasswordRequirementSection && activeForm === 1 &&
                                                                    <PasswordRequired oneLetter={oneLetter}
                                                                                      oneNumber={oneNumber}
                                                                                      oneCapitalLetter={oneCapitalLetter}
                                                                                      eightCharacter={eightCharacter}
                                                                                      oneSpecialChar={oneSpecialChar}
                                                                    />
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            {
                                                                activeForm == 1 &&
                                                                    <Link className="displayF floatLeft"
                                                                          onClick={() => setActiveForm(activeForm - 1)}
                                                                          variant="body2">
                                                                        <ArrowBackIcon /> Back
                                                                    </Link>
                                                            }
                                                    <Button
                                                            type="submit"
                                                            label={forms[activeForm].buttonText}
                                                            variant="contained"
                                                            color="primary"
                                                            className="mb15 floatRight"
                                                            disabled={(Object.keys(errors).length !== 0) || !dirty || isSubmitting}
                                                            loading={isSubmitting}
                                                    />
                                                        </Grid>
                                                    </Grid>
                                                </form>
                                        );
                                    }
                                    }
                                </Formik>

                                <Grid item xs={12} className="textCenter">
                                    <Typography variant="body2" align="center">
                                        Already have a account? <Link color="primary"
                                                                      onClick={() => window.location.href = url}>
                                        Log in</Link>. If you need assistance please <Link color="primary"
                                                                                           onClick={() => setOpen(true)}>
                                        contact us</Link>.
                                    </Typography>
                                </Grid>

                                <LandingContactForm setOpen={(isOpen: boolean) => setOpen(isOpen)}
                                                    open={open} {...props} />
                                <Typography variant="caption" className="colorRed f12 w100 textCenter">This invitation
                                    expires on {moment(expDate).format("MMMM DD, YYYY")}</Typography>
                            </>}
                </Grid>
            </React.Fragment>

    );
};

export default UnAuthenticationForm;
