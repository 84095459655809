import * as React from "react";
import {useState} from "react";
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    Link,
    Radio,
    RadioGroup as MUIRadioGroup,
    RadioProps,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import {Button, Label, PageTitle, SelectField} from "../components/formInputs";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import InfoIcon from "@material-ui/icons/Info";
import {Field, FieldArray, getFormValues, reduxForm, reset, updateSyncErrors} from "redux-form";
import {useActions} from "../actions";
import * as Actions from "../actions/account";
import * as CarrierActions from "../actions/carrier";
import * as AggregatorActions from "../actions/aggregator";
import {RootState} from "../reducers";
import {connect, useDispatch, useSelector} from "react-redux";
import {ucFirst, unmaskPhoneNumber} from "../utils/commonUtils";
import {ImoAdditionalMembers} from "../model/imoAdditionalMembers";
import {Carrier, CarrierStrategy} from "../model/account";
import AddImoAccount from "../components/AddImoAccount";
import PrimaryContactDetail from "../components/PrimaryContactDetail";
import AddCarrierAccount from "../components/AddCarrier";
import AddTrustee from "../components/AddTrustee";
import AddBank from "../components/AddBank";
import { isRequiredArray, maxLength, required } from "../utils/validation";
import {SimpleBreadcrumbs} from "../components";
import {asDbDateFormat, DATE_FORMAT_FOR_ALL_ACC_DB_SAVE} from "../utils/dateUtils";
import {scroller} from "react-scroll";
import {green, red} from "@material-ui/core/colors";
import {ADMIN_TYPES, AGENT_TYPES, imoRegionDropDown} from "../constants";
import TableWrap from "../components/TableWrap/TableWrap";
import AddAggregator from "../components/AddAggregator";
import * as PublicActions from "../../src/actions/public";

interface AccountData {
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    accountType: string,
    imo: {
        groupName: string,
        officeNumber: string,
        street: string,
        city: string,
        state: string,
        zipCode: string,
        imoEmail: string,
        imoClass: string,
        reBrand: number,
        reBrandLogo: string,
        reBrandStrategy: string,
        strategyId: number | string,
        imoStrategy?: number[],
        additionalMembers: ImoAdditionalMembers[],
        carriers: Carrier[]
    },
    carrier: {
        carrierName: string,
        carrierEmail: string,
        carrierProductName: string,
        carrierStreet: string,
        carrierCity: string,
        carrierState: string,
        carrierZipCode: string,
        carrierPhone: string,
        carrierStrategies: CarrierStrategy[],
        onBoard: number,
        onBoardDate: string | null
    },
    admin?: {
        adminType: string,
        region: string
    },
    aggregator?: {
        aggregatorName: string,
        aggregatorEmail: string,
        street: string,
        city: string,
        state: string,
        zipCode: string,
        phoneNumber: string,
        divisions: any,
        imoDivisions: any
    },
    agent?: {
        agentType: number
    }
}

const userRoleArr: string[] = ["admin", "aggregator", "agent", "imo", "carrier", "individual", "multi-life", "trustee", "bank"];
// let carrierListArr: object[] = [
//     {
//         key: 0,
//         value: "",
//         name: "-- Please select --",
//     },
// ];


function AddAccountPage(props: any) {
    const accountActions = useActions(Actions);
    const carrierActions = useActions(CarrierActions);
    const aggregatorActions = useActions(AggregatorActions);
    const dispatch = useDispatch();
    const { handleSubmit, pristine, submitting, match, invalid, initialize } = props;
    const userRole: string = match.params.role;
    const imos = useSelector((state: RootState) => state.imos);
    const [emailExist, setEmailExist] = useState(false);
    const [aggregatorEmailExist, setAggregatorEmailExist] = useState(false);
    const [emailRef, setEmailRef] = useState<any>(null);
    const [imoRegions, setImoRegions] = React.useState<any>([]);
    const [imoValue, setImoValue] = useState<string>();
    const [carrierValue, setCarrierValue] = useState();
    let imoRegion:any = {
        id: 0,
        value: 'All',
        name: 'All'
    };
    const publicActions = useActions(PublicActions);

    React.useEffect(()=>{
        let regions:any = [imoRegion, ...imoRegionDropDown];
        setImoRegions(regions)
    }, []);

    const validateIMOCarriers = () => {
        if (props.formValues && props.formValues.imo && !props.formValues.imo.carriers) {
            dispatch(updateSyncErrors('AddAccount', { "imo.carriers": 'Required' }, 'Required'));
        }
    };


    const showFocusError = async (id: string) => {
        scroller.scrollTo(id, {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart",
            offset: -200,
        });
    };
    const initializePermissions = async () => {
        const adminPermissionTypes = await accountActions.getPermissionTypes();

        initialize({
            permissions: (adminPermissionTypes || []).map(x => ({ ...x, isEnabled: null })),
            imoCarrierId:[],
        });
    };

    React.useEffect(() => {
        initializePermissions();
    }, []);

    React.useEffect(() => {
        if (emailExist && emailRef && emailRef.current) {
            showFocusError(emailRef.current.id).then(() => {
            });
        }
    }, [emailExist]);

    React.useEffect(() => {
        if (userRole && userRole === "imo") {
            carrierActions.getAllCarriers();
            accountActions.getAllStrategy();
        } else if (userRole && userRole === "carrier") {
            accountActions.getAllStrategy();
        } else if (userRole && userRole === "agent") {
            accountActions.getAllImos();
        }
    }, []);

    React.useEffect(() => {
        getCarrierData().then();
    }, [imoValue]);

    // Add Account
    const addAccount = async (data: AccountData, dispatch: Function) => {
        console.log("data", data);
        let res = false;
        setEmailExist(false);
        data.accountType = userRole;
        if (data.phoneNumber) {
            data.phoneNumber = unmaskPhoneNumber(data.phoneNumber || "");
        }
        switch (data.accountType) {
            case "agent": {
                try {
                    res = await accountActions.addAgent(data);
                } catch (err) {
                    if (err.code == 400 && err.message == "Email already exists") {
                        setEmailExist(true);
                    }
                }
                break;
            }
            case "imo": {
                try {
                    if (!props.formValues.imo.carriers) {
                        dispatch(updateSyncErrors('AddAccount', { "imo.carriers": 'Required' }, 'Required'));
                        return;
                    }
                    if (data.imo.officeNumber) {
                        data.imo.officeNumber = unmaskPhoneNumber(data.imo.officeNumber || "");
                    }
                    if (data.imo.additionalMembers) {
                        data.imo.additionalMembers = data.imo.additionalMembers.map((item: any) => {
                            item.memberPhoneNumber = unmaskPhoneNumber(item.memberPhoneNumber || "");
                            item.iliaTrained = item.iliaTrained || 0;
                            return item;
                        });
                    }
                    if (!data.imo.reBrand) {
                        data.imo.reBrandLogo = "";
                    }
                    data.imo.reBrandStrategy = "";
                    data.imo.strategyId = 0;
                    res = await accountActions.addImo(data);
                } catch (err) {
                    if (err.code == 400 && err.message == "Email already exists") {
                        setEmailExist(true);
                    }
                }
                break;
            }
            case "carrier": {
                try {
                    if (data.carrier.carrierStrategies) {
                        data.carrier.carrierStrategies = data.carrier.carrierStrategies.map((item: any) => Object.assign({}, {
                            strategyId: item.id,
                        }));
                    }
                    if (data.carrier.carrierPhone) {
                        data.carrier.carrierPhone = unmaskPhoneNumber(data.carrier.carrierPhone || "");
                    }
                    data.carrier.onBoardDate = data.carrier.onBoard ? asDbDateFormat(new Date(), DATE_FORMAT_FOR_ALL_ACC_DB_SAVE) : null;
                    res = await carrierActions.addCarrier(data);
                } catch (err) {
                    if (err.code == 400 && err.message == "Email already exists") {
                        setEmailExist(true);
                    }
                }
                break;
            }
            case "trustee": {
                try {
                    res = await accountActions.addTrustee(data);
                } catch (err) {
                    if (err.code == 400 && err.message == "Email already exists") {
                        setEmailExist(true);
                    }
                }
                break;
            }
            case "bank": {
                console.log("bank switch");
                try {
                    res = await accountActions.addBank(data);
                } catch (err) {
                    if (err.code == 400 && err.message == "Email already exists") {
                        setEmailExist(true);
                    }
                }
                break;
            }
            case "admin": {
                try {
                    res = await accountActions.addAdmin(data);
                } catch (err) {
                    if (err.code == 400 && err.message == "Email already exists") {
                        setEmailExist(true);
                    }
                }
                break;
            }
            case "aggregator": {
                try {
                    if (data.aggregator && data.aggregator.phoneNumber) {
                        data.aggregator.phoneNumber = unmaskPhoneNumber(data.aggregator.phoneNumber || "");
                        data.aggregator.divisions = data.aggregator && data.aggregator.divisions && data.aggregator.divisions.map((item: any) => {
                            return {
                                divisionName: item.divisionName,
                            };
                        }) || [];
                    }
                    res = await aggregatorActions.addAggregator(data);
                } catch (err) {
                    if (err.code == 400 && err.message == "Email already exists") {
                        setEmailExist(true);
                    }
                    if (err.code == 400 && err.message == "Aggregator email already exists") {
                        setAggregatorEmailExist(true);
                    }
                }
                break;
            }
            default: {
                try {
                    res = await accountActions.addAccount(data);
                } catch (err) {
                    if (err.code == 400 && err.message == "Email already exists") {
                        setEmailExist(true);
                    }
                }
                break;
            }
        }
        if (res) {
            dispatch(reset("AddAccount"));
            props.history.push("/");
        }
    };

    if (!userRoleArr.find(p => p === userRole)) {
        return <></>;
    }

    const getImoDropDown = () => {
        let imoListArr: object[] = [
            {
                key: 0,
                value: {},
                name: "-- Please select --",
            },
        ];
        imos && imos.data && imos.data.forEach((s: any) => imoListArr.push({
            key: s.id,
            value: `${s.id}`,
            name: s.groupName,
        }));
        return imoListArr;
    };

    const getImoID = ( value: string) => {
        setImoValue(value);
    }

    async function getCarrierData(){
        if(imoValue){
            const userCarriers = await publicActions.getImoById(imoValue);
                setCarrierValue(userCarriers);
        }
    }

    const getCarrierDropDown = () => {
        let carrierListArr: object[] = [
            {
                key: 0,
                value: {},
                name: "-- Please select --",
            },
        ];

        if(carrierValue){
            carrierValue && carrierValue.imo && carrierValue.imo.carriers.forEach((s: any) => carrierListArr.push({
                key: s.id,
                value: `${s.id}`,
                name: s.carrierName,
            }));
        }
        return carrierListArr;
    };
    const pageHeadingMapping = {
        "AGENT": "ADD PREFERRED OR GHOST AGENT TO ILIA",
        "IMO": "ADD IMO Ghost Account",
    };

    return (
            <React.Fragment>
                <PageTitle title={`Add ${ucFirst(userRole)}`}/>
                <Link className="backBtn" color="primary" onClick={() => props.history.push("/")}>
                    <ArrowBackIosIcon/>Back
                </Link>
                <SimpleBreadcrumbs
                        history={props.history}
                        crumbObject={{ [pageHeadingMapping[userRole.toUpperCase()] || "ADD " + userRole.toUpperCase()]: "" }}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={11} lg={8}>
                        <Card className="muiCard">
                            <CardContent className="muiCardContent">
                                <form onSubmit={handleSubmit(addAccount)}>
                                    <Grid container spacing={2}>
                                        {
                                                ["admin", "individual", "multi-life"].find(p => p === userRole) &&
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="subtitle1" component="strong" className="colorBlue">
                                                            Provide Details for {userRole.toLowerCase()}
                                                        </Typography>
                                                    </Grid>
                                                    <PrimaryContactDetail
                                                            setEmailRef={setEmailRef}
                                                            emailExist={emailExist}
                                                            setEmailExist={setEmailExist}
                                                            disabledEmail={false}
                                                    />
                                                </>
                                        }

                                        {
                                                (userRole && userRole === "admin") &&
                                                <>
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                                fullWidth
                                                            name="admin.adminType"
                                                                label={<Label label={"Select Admin type"} required={true}/>}
                                                                options={ADMIN_TYPES.map(x => ({
                                                                value: x.value,
                                                                    name: x.name,
                                                                }))}
                                                                component={SelectField}
                                                                validate={[required]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                                fullWidth
                                                                name="admin.region"
                                                                label="Region"
                                                                placeholder="enter region"
                                                                component={SelectField}
                                                                options={imoRegions}
                                                        />
                                                    </Grid>
                                                    {
                                                    (
                                                        props.formValues && props.formValues.admin && props.formValues.admin.adminType === "IliaAdmin" ||
                                                        props.formValues && props.formValues.admin && props.formValues.admin.adminType === "Sales" ||
                                                        props.formValues && props.formValues.admin && props.formValues.admin.adminType === "Processing"
                                                    ) &&
                                                            <Grid item xs={12}>
                                                                <FieldArray name="permissions" component={SelectAdminPermissions}/>
                                                            </Grid>
                                                    }
                                                </>
                                        }
                                        {
                                                (userRole && userRole === "agent") &&
                                                <React.Fragment>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="subtitle1" component="strong" className="colorBlue">
                                                            Provide Details for {userRole.toLowerCase()}
                                                        </Typography>
                                                    </Grid>
                                                    <PrimaryContactDetail
                                                            setEmailRef={setEmailRef}
                                                            emailExist={emailExist}
                                                            setEmailExist={setEmailExist}
                                                            disabledEmail={false}
                                                    />
                                                    <Grid item xs={12} sm={12} md={6}>
                                                        <Field
                                                                fullWidth
                                                                name="imoId"
                                                                label={<Label label={"Select Imo"} required={true}/>}
                                                                options={getImoDropDown()}
                                                                component={SelectField}
                                                                validate={[required]}
                                                                onChange={(e: any) => getImoID(e)}
                                                        />
                                                    </Grid>
                                                    { carrierValue &&
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <Field
                                                                        fullWidth
                                                                        name="imoCarrierId"
                                                                        label={<Label label={"Select Carrier(s)"} required={true}/>}
                                                                        options={getCarrierDropDown()}
                                                                        multiple
                                                                        component={SelectField}
                                                                        validate={[required, isRequiredArray]}
                                                                />
                                                            </Grid>
                                                    }
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                                fullWidth
                                                                name="agent.agentType"
                                                                label={<Label label={"Select Agent type"} required={true}/>}
                                                                options={AGENT_TYPES.map(x => ({
                                                                    value: x.value,
                                                                    name: x.name,
                                                                }))}
                                                                component={SelectField}
                                                                validate={[required]}
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                        }
                                        {
                                                (userRole && userRole === "imo") &&
                                                <AddImoAccount
                                                        page={"AddAccount"}
                                                        dispatch={props.dispatch}
                                                    brandLogo={(v: string) => {
                                                    }}
                                                        emailExist={emailExist}
                                                        setEmailExist={setEmailExist}
                                                        setEmailRef={setEmailRef}
                                                        validateIMOCarriers={validateIMOCarriers}
                                                />
                                        }
                                        {
                                                (userRole && userRole === "carrier") &&
                                                <AddCarrierAccount
                                                        dispatch={props.dispatch}
                                                        page={"AddAccount"}
                                                        emailExist={emailExist}
                                                        setEmailExist={setEmailExist}
                                                        setEmailRef={setEmailRef}
                                                />
                                        }
                                        {
                                                (userRole && userRole === "trustee") &&
                                                <AddTrustee
                                                        dispatch={props.dispatch}
                                                        page={"AddAccount"}
                                                        emailExist={emailExist}
                                                        setEmailExist={setEmailExist}
                                                        setEmailRef={setEmailRef}
                                                />
                                        }
                                        {
                                                (userRole && userRole === "bank") &&
                                                <AddBank
                                                        dispatch={props.dispatch}
                                                        page={"AddAccount"}
                                                        emailExist={emailExist}
                                                        setEmailExist={setEmailExist}
                                                        setEmailRef={setEmailRef}
                                                />
                                        }
                                        {
                                                (userRole && userRole === "aggregator") &&
                                                <AddAggregator
                                                        page={"AddAccount"}
                                                        dispatch={props.dispatch}
                                                        brandLogo={(v: string) => {
                                                        }}
                                                        emailExist={emailExist}
                                                        setEmailExist={setEmailExist}
                                                        setEmailRef={setEmailRef}
                                                        aggregatorEmailExist={aggregatorEmailExist}
                                                        setAggregatorEmailExist={setAggregatorEmailExist}
                                                />
                                        }
                                        <div className="clearFix"/>
                                        <Button
                                                label="CREATE ACCOUNT"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className="floatLeft ml10 mt15 mb10"
                                                disabled={invalid || pristine || submitting}
                                                loading={submitting}
                                        />
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
    );
}

export const GreenRadio = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600] + "!important",
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export const RedRadio = withStyles({
    root: {
        color: red[400],
        "&$checked": {
            color: red[600] + "!important",
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);


const renderRadioGroup = ({ input, ...rest }) => (
        <MUIRadioGroup {...input} {...rest}
                       value={input.value}
                       onChange={(event, value) => input.onChange(value)}/>
);

const SelectAdminPermissions = ({ fields }: any) => {
    return (
            <div>
                <h4>Select admin permissions (*)</h4>
                <TableWrap>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left"/>
                            <TableCell align="left"><span
                                    style={{ padding: 5, fontWeight: 700 }}>On</span> <span
                                    style={{ padding: 5, fontWeight: 700, marginLeft: 15 }}>Off</span></TableCell>
                        </TableRow>
                        {
                            fields.map((permission, index) => {
                                const field = fields.get(index);

                                return (<TableRow key={field.id}>
                                    <TableCell align="left">{field.name}
                                        <MoreInfo value={field.moreInfo}/> </TableCell>

                                    <TableCell align="left">
                                        <Field
                                                row
                                                name={`${permission}.isEnabled`}
                                                component={renderRadioGroup}
                                                validate={[required]}>
                                            <GreenRadio
                                                    value="on"
                                                    inputProps={{ "aria-label": "on" }}
                                            />
                                            <RedRadio
                                                    value="off"
                                                    inputProps={{ "aria-label": "off" }}
                                            />
                                        </Field>
                                    </TableCell>
                                </TableRow>);
                            })
                        }
                    </TableBody>
                </TableWrap>
                <Typography variant="caption" className="f12 colorRed">(*) All required</Typography>
            </div>
    );
};

export const MoreInfo = ({ value }) => {
    return value ? (
            <Tooltip title={value}>
                <IconButton aria-label="info">
                    <InfoIcon/>
                </IconButton>
            </Tooltip>
    ) : null;
};

function mapStateToProps(state: RootState) {
    return {
        account: state.account,
        formValues: getFormValues("AddAccount")(state),
    };
}

const reduxAddAccountPage = reduxForm({
    form: "AddAccount",  // a unique identifier for this form
})(AddAccountPage);

export default connect(mapStateToProps)(reduxAddAccountPage);
