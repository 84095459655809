import React from "react";
import Button from "@material-ui/core/Button";
import { Dialog as GenericDialog, GenericDialogProps } from "./dialog";
import { Formik, useFormikContext } from "formik";
import { Label } from "./formInputs";
import { ErrorCommon, SelectField, TextField, TextFieldCommon } from "./formikFormInputs";
import { Grid, Typography } from "@material-ui/core";
import { epayRejectedDropdown } from "../pages/client/Enrollment/EnrollmentHome/basicInfo/PersonalDetailUtils";
import { REQUIRED_FIELD } from "../constants";


export default function EpayRejectPayment(props: any) {
    const initialValues = {};
    const validate = ((values: any) => {
        let errors: any = {};
        if(values["paymentRejectedReason"] == "other" && !values["message"] ){
            errors.message = REQUIRED_FIELD;
        }
        if(!values["paymentRejectedReason"]){
            errors.paymentRejectedReason = REQUIRED_FIELD;
        }
        return errors;
    });

    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "terms-and-conditions-dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (
                <>
                    <Typography variant="body1" className="mb15 floatLeft w100"> What form of payment are you
                        rejecting?</Typography>
                    <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validate={values => validate(values)}
                            validateOnChange={true}
                            onSubmit={props.onSubmit}
                    >
                        {(formikProps) => (

                                <EpayRejectPaymentFormContent {...formikProps} />

                        )}
                    </Formik>
                </>
        ),
        dividers: true,
        onClose: () => props.epaySetRp(false),
        open: props.epayShowRp,
        title: "Reject Payment",
    };
    return (
            <GenericDialog {...dialogProps} />
    );
}

function EpayRejectPaymentFormContent(props: any) {
    const  {values, handleChange, handleBlur, errors, touched, isSubmitting } = useFormikContext<any>()
    const fieldObj:any = [
        {
            id: "paymentRejectedReason",
            name: "paymentRejectedReason",
            label: <Label label={"Reason for rejecting"} required={true} />,
            isRequired: true,
            component: SelectField,
            className: "SelectFieldWidth",
            variant: "outlined",
            options: epayRejectedDropdown,
        },
    ];
    if(values["paymentRejectedReason"] == "other"){
        fieldObj.push({
            id: "message",
            key: "message",
            name: "message",
            label: <Label label={"Why the payment was rejected"} required={true} />,
            isRequired: true,
            component: TextField,
            variant: "outlined",
            multiline: true,
            rows: 5,
        })
    }
    return (
            <form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                    {fieldObj.map((field, index) => {
                        return (
                                <Grid item xs={12} key={index}>
                                    <TextFieldCommon
                                            {...field}
                                            values={values}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                    />
                                    <ErrorCommon errors={errors}
                                                 name={field.name}
                                                 touched={touched} />
                                </Grid>
                        );
                    })
                    }
                    <Grid item xs={12}>
                        <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                className="floatRight"
                                type="submit"

                        >
                            Reject Payment
                        </Button>
                    </Grid>
                </Grid>
            </form>
    );
}
