export interface paymentMethodsType  {
    "policyNumber": string,
    "policyNumberCarrier": null | string,
    "trancheNumber": null | string,
    "carrier": null | string,
    "clientName": null | string,
    "clientUsername": null | string,
    "paymentMethodStatus": string,
    "maskedAccountNumber": null | string,
    "bankName": string,
    "recordKey": string,
    "files": string[]
}

export  const fieldsName = {
    "accountNumber": "accountNumber",
    "paymentMethod": "paymentMethod",
    "amountDue": "amountDue",
    "paymentMethodId": "paymentMethodId",
    "trancheNo" : "trancheNo",
    "amountPaid" : "amountPaid",
    "bankName" : "bankName",
    "bankAccHolder" : "bankAccHolder",
    "routingNumber" : "routingNumber",
    "accountType" : "accountType",
    "note" : "note",
    "checkFront" : "checkFront",
    "checkBack" : "checkBack",
}