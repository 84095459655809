import * as React from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Link,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import {useActions} from "../actions";
import * as DocusignActions from "../actions/docusign";
import * as Actions from "../actions/enrollment";
import * as TicketActions from "../actions/ticket";
import {ActionItems as CurrentActionsItems, ClientDocumentType} from "../constants";
import AdministratorMessage from "./client/ClientProfile/component/modals/AdministratorMessage";
import RequestModification from "./client/ClientProfile/component/modals/RequestModification";
import ResetPayment from "./client/ClientProfile/component/modals/ResetPayment";
import {UploadProposalPopUp} from "../components/client/Proposal";
import PaymentReceivedPopUp from "../components/client/PaymentReceivedPopUp";
import PaymentProcessedPopUp from "../components/client/PaymentProcessedPopUp";
import * as AccountActions from "../actions/account";
import DocusignLoader from "../components/DocusignLoader";
import {Dialog as GenericDialog, GenericDialogProps} from "../components/dialog";
import {Button as CustomMuiButton} from '../components/formInputs'
import * as Aws from "../actions/aws";
import {AccountSortList} from "../model/account";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import {CreateTicketDialog} from "./ticket/CreateTicket";
import {TicketDetailDialog} from "./ticket/TicketPage";
import UploadSpecificDocument from "./client/ClientProfile/component/dialogs/UploadSpecificDocument";
import ActionItemListRow from "../components/ActionItemListRow";
import {noop, pez, va} from "../utils/commonUtils";
import {Proposal} from "../model/proposal";
import {ClientDocsInterface} from "../model/documents";
import ProposalTabs from "./client/Enrollment/component/ProposalTabs";
import LoaderGif from "../styles/images/loader_nw.gif";
import { isAllianzApp } from "./client/Enrollment/EnrollmentHome/EnrollmentApplication/Utilities";
import * as ApplicationActions from "../actions/application";
import QCPopUp from "../components/client/Proposal/ActionDailogs/QCPopUp/QCPopUp";
import CheckWire from "../components/client/Proposal/ActionDailogs/CheckWirePopup/Content";
import CheckWirePopup from "../components/client/Proposal/ActionDailogs/CheckWirePopup/CheckWire";


const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: "100%",
                marginTop: theme.spacing(3),
            },
            paper: {
                padding: 5,
            },
            table: {
                minWidth: 750,
            },
            tableWrapper: {
                overflowX: "auto",
            },
            visuallyHidden: {
                border: 0,
                clip: "rect(0 0 0 0)",
                height: 1,
                margin: -1,
                overflow: "hidden",
                padding: 0,
                position: "absolute",
                top: 20,
                width: 1,
            },
            closeButton: {
                position: "absolute",
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500],
            },
            popover: {
                pointerEvents: "none",
            },
            buttonProgress: {
                color: "white",
            },
        }),
);
const styles: any = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "10px",
        top: "20px",
        color: theme.palette.grey[500],
    },
});


function ActionItemsList(props: any) {
    const initFalse = false;
    const SEPARATOR: string = '|||||';
    const DialogTitle: any = withStyles(styles)((props: any) => {
        const { children, classes, onClose, ...other } = props;
        return (
                <MuiDialogTitle disableTypography className={classes.root} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                    ) : null}
                </MuiDialogTitle>
        );
    });
    const accountActions = useActions(AccountActions);
    const docusignActions = useActions(DocusignActions);
    const enrollmentActions = useActions(Actions);
    const ticketActions = useActions(TicketActions);
    const applicationActions = useActions(ApplicationActions);
    const awsActions = useActions(Aws);
    const classes = useStyles();
    let {
        actionItemsList,
        order,
        orderBy,
        headRows,
        role,
        getEnvelope,
        value,
        refreshPage,
        onRequestSort,
        setFilterType,
        mockSetComplete,
        isViaAgentApprovalPage,
    } = props;

    const [activeProposalTab, setActiveProposalTab] = React.useState(0);
    const [enableActions, setEnableActions] = React.useState(true);
    const [openProposalPdf, setOpenProposalPdf] = React.useState<Proposal[]>([] as Proposal[]);
    const [actionId, setActionId] = React.useState(0);
    const [enrollmentId, setEnrollmentId] = React.useState("");
    const [adminMessage, setAdminMessage] = React.useState(false);
    const [requestModification, setRequestModification] = React.useState(false);
    const [resetPayment, setResetPayment] = React.useState(false);
    const [documentUrl, setDocumentUrl] = React.useState("");
    const [openPdf, setOpenPdf] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(true);
    const [uploadProposalPopUp, setUploadProposalPopUp] = React.useState(false);
    const [QCPopUpOpen, setQCPopUpOpen] = React.useState(false);
    const [CheckWirePopUpOpen, setCheckWirePopUpOpen] = React.useState(false);
    const [paymentReceivedPopUp, setPaymentReceivedPopUp] = React.useState(false);
    const [paymentProcessedPopUp, setPaymentProcessedPopUp] = React.useState(false);
    const [clientUserId, setClientUserId] = React.useState("");
    const [client, setClient] = React.useState(null);
    const [clientId, setClientId] = React.useState(0);
    const [agentId, setAgentId] = React.useState(0);
    const [downloadProgress, setDownloadProgress] = React.useState({ enrollmentId: "" });
    const [sellerAgreementLoading, setSellerAgreementLoading] = React.useState(false);
    const [sellerAgreementActionId, setSellerAgreementActionId] = React.useState(0);
    const [sellerAgreement, setSellerAgreement] = React.useState(false);
    const [sellerAgreementUrl, setSellerAgreementUrl] = React.useState('');
    const [actionItemSelected, setActionItemSelected] = React.useState(null);
    const [coverLetterDialog, setCoverLetterDialog] = React.useState(false);
    const [coverLetterUpload, setCoverLetterUpload] = React.useState(true);
    const [clientDetail, setClientDetail] = React.useState<any>(null);
    let actionItemsListData: any = actionItemsList.rows && actionItemsList.rows.length > 0 && actionItemsList.rows.map((row: any) => {
        row.agent = row.agent ? row.agent : (row.client && row.client.agent ? row.client.agent : null);
        return row;
    });

    // TODO use common getEnrollmentAdminProposalUrl
    const getEnrollmentAdminProposalUrl = async (eId: string, adminProposal: string) => {
        let file = adminProposal.split(".");
        let fileType = file[file.length - 1].toUpperCase();
        const contentType = (fileType == "PDF") ? ("text/pdf") : ("image/*");
        let queryObj = {
            objectName: adminProposal,
            contentType: contentType,
        };
        return awsActions.getSecureUrl(eId, queryObj, true);
    }
    const viewProposal = async (id: number, eId: string, status: number, client: any) => {
        const documents: any[] = await accountActions.getClientDocumentByType((client && client.id) || 0, ClientDocumentType.ADMIN_PROPOSAL);
        const proposalArr: Proposal[] = [];
        for (let i = 0; i < documents.length; i++) {
            const proposalDocument = documents[i] as ClientDocsInterface;
            const url = await getEnrollmentAdminProposalUrl(eId, pez(proposalDocument.path));
            proposalArr.push({name: proposalDocument.name, url: url});
        }

        setActiveProposalTab(0);
        setOpenProposalPdf(proposalArr);
        setEnrollmentId(eId);
        setActionId(status === 1 ? 0 : id);
    };


    //Get Envelope for Admin
    const getAdminEnvelope = async (enrollmentId: string, offerType?: any) => {
        setOpenPdf(true);
        let envelopeUrl = "";
        if (isAllianzApp(offerType)) {
            const result = await applicationActions.getAndInitiateDocusign(enrollmentId);
            if (result) {
                envelopeUrl = result.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        } else {
            let pageUrl = window.location.protocol + "//" + window.location.hostname;
            if (window.location.hostname === "localhost") {
                pageUrl = pageUrl + ":3000";
            }
            pageUrl += "/new-client-detail/thanks?isAdminApprove=1";
            let result = await enrollmentActions.getEnvelope(enrollmentId, pageUrl);
            if (result && result && result.payload.data) {
                envelopeUrl = result.payload.data.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        }
    };

    const approveProposal = async () => {
        await enrollmentActions.updateEnrollmentProposalActionItem({ enrollmentId, actionId });
        setOpenProposalPdf([]);
        setActiveProposalTab(0);
        setActionId(0);
        refreshPage();
    };

    const cancelProposal = () => {
        setOpenProposalPdf([]);
        setActiveProposalTab(0);
        setActionId(0);
    };

    const handleAdministratorMessage = () => {
        setAdminMessage(true);
    };

    const handleRequestModification = (enrollmentId: any) => {
        setRequestModification(!requestModification);
        setEnrollmentId(enrollmentId || 0);
    };

    const handleResetPayment = () => {
        setResetPayment(true);
    };

    const handleClose = async () => {
        setAdminMessage(false);
        setRequestModification(false);
        setResetPayment(false);
        if (typeof refreshPage === "function") {
            refreshPage();
            return;
        }
        await accountActions.getFreshDataToRefreshReduxState();

    };

    const loadSellerAgreement = async (agentId: number, actionId: number, sellerAgreementId: number) => {
        setSellerAgreement(!initFalse);
        setSellerAgreementLoading(!initFalse);
        setSellerAgreementActionId(actionId);
        let pageUrl = window.location.protocol + "//" + window.location.hostname;
        if (window.location.hostname === "localhost") {
            pageUrl = pageUrl + ":3000";
        }
        pageUrl += `/new-client-detail/thanks?isAdminApproveSellerAgreement=1&sellerAgreementId=${sellerAgreementId}&sellerAgreementActionId=${actionId}`;
        const res = await docusignActions.getSellerAgreement(agentId, pageUrl);
        setSellerAgreementLoading(initFalse);
        setSellerAgreementUrl(res.envelopeUrl);
    };

    const sellerAgreementClose = async () => {
        setSellerAgreement(initFalse);
        setSellerAgreementLoading(initFalse);
        if (mockSetComplete && typeof mockSetComplete === "function") {
            mockSetComplete(sellerAgreementActionId);
        }
        setSellerAgreementActionId(0);
        return setSellerAgreementUrl('');
    };

    const getEnvelopeForTrustee = async (enrollmentId: string, offerType?: any, reqType?: string) => {
        setOpenPdf(true);
        let envelopeUrl = "";
        if (isAllianzApp(offerType)) {
            const result = await applicationActions.getAndInitiateDocusign(enrollmentId, reqType);
            if (result) {
                envelopeUrl = result.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        } else {
            let pageUrl = window.location.protocol + "//" + window.location.hostname;
            if (window.location.hostname === "localhost") {
                pageUrl = pageUrl + ":3000";
            }
            pageUrl += "/new-client-detail/thanks?isUploadTrusteeDocs=1";
            let result = await enrollmentActions.getEnvelope(enrollmentId, pageUrl);
            if (result && result && result.payload.data) {
                envelopeUrl = result.payload.data.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        }
    };

    const getEnvelopeForSignTrusteeDoc = async (enrollmentId: string, offerType?: any, reqType?: string) => {
        setOpenPdf(true);
        let envelopeUrl = "";
        if (isAllianzApp(offerType)) {
            const result = await applicationActions.getAndInitiateDocusign(enrollmentId, reqType);
            if (result) {
                envelopeUrl = result.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        } else {
            let pageUrl = window.location.protocol + "//" + window.location.hostname;
            if (window.location.hostname === "localhost") {
                pageUrl = pageUrl + ":3000";
            }
            pageUrl += "/new-client-detail/thanks?isSignTrusteeDoc=1";
            let result = await enrollmentActions.getEnvelope(enrollmentId, pageUrl);
            if (result && result && result.payload.data) {
                envelopeUrl = result.payload.data.envelopeUrl;
                setDocumentUrl(envelopeUrl);
                setInProgress(false);
            }
        }
    };

    const downloadEnvelope = async (enrollmentId: string, recipientId?: number) => {
        setDownloadProgress({ enrollmentId: enrollmentId });
        let result = await enrollmentActions.downloadEnvelope(enrollmentId, recipientId);
        if (result && result && result.payload.data) {
            let response: any = result.payload.data.fileBuf;
            let blob = new Blob([new Buffer(response, "binary")]
                    , { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = window.document.createElement("a");
            link.href = url;
            link.setAttribute("download", "application.pdf");
            window.document.body.appendChild(link);
            link.click();
            const payloadTrustee = {
                enrollmentId,
                actionType: "DOWNLOAD_TRUSTEE_DOCUMENTS",
            };
            await accountActions.updateTrusteeActionItemsStatus(payloadTrustee, "Document downloaded Successfully.");
            await accountActions.getFreshDataToRefreshReduxState();
            setDownloadProgress({ enrollmentId: "" });
        }
    };

    const getAdminButton = (actionType: string, buttonText: string = "", role: number, account: any, offerType?: any) => {
        if (account && account.status === 1 && actionType !== CurrentActionsItems.DOWNLOAD_TRUSTEE_DOCUMENTS.actionType) {
            return account.actionRequired;
        }

        if (actionType === CurrentActionsItems.UPLOAD_PROPOSAL.actionType && role !== 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => {
                                setEnrollmentId((account && account.enrollmentId) || 0);
                                setAgentId((account && account.agent && account.agent.id) || 0);
                                setClient((account && account.client) || 0);
                                setClientId((account && account.client && account.client.id) || 0);
                                setClientUserId((account && account.client && account.client.user && account.client.user.id) || "");
                                setUploadProposalPopUp(true);
                            }}
                    >
                        {buttonText}
                    </Button>
            );
        }

        if ((actionType === CurrentActionsItems.APPROVE_DOCUSIGN.actionType) && role !== 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => {
                                getAdminEnvelope((account && account.enrollmentId) || enrollmentId, offerType);
                            }}
                    >
                        {buttonText}
                    </Button>
            );
        }

        if (actionType === CurrentActionsItems.QC_EPAY.actionType) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => {
                                setEnrollmentId((account && account.enrollmentId) || 0);
                                setAgentId((account && account.agent && account.agent.id) || 0);
                                setClient((account && account.client) || 0);
                                setClientId((account && account.client && account.client.id) || 0);
                                setClientUserId((account && account.client && account.client.user && account.client.user.id) || "");
                                setQCPopUpOpen(true)
                            }}
                    >
                        {buttonText}
                    </Button>
            );
        }
        if (actionType === CurrentActionsItems.CHECK_WIRE.actionType) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => {
                                setEnrollmentId((account && account.enrollmentId) || 0);
                                setAgentId((account && account.agent && account.agent.id) || 0);
                                setClient((account && account.client) || 0);
                                setClientId((account && account.client && account.client.id) || 0);
                                setClientUserId((account && account.client && account.client.user && account.client.user.id) || "");
                                setCheckWirePopUpOpen(true)
                            }}
                    >
                        {buttonText}
                    </Button>
            );
        }
        if (actionType === CurrentActionsItems.MAKE_PAYMENT_RECEIVED.actionType) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => {
                                setEnrollmentId((account && account.enrollmentId) || 0);
                                setAgentId((account && account.agent && account.agent.id) || 0);
                                setClient((account && account.client) || 0);
                                setClientId((account && account.client && account.client.id) || 0);
                                setClientUserId((account && account.client && account.client.user && account.client.user.id) || "");
                                setPaymentReceivedPopUp(true);
                            }}
                    >
                        {buttonText}
                    </Button>
            );
        }

        if (actionType === CurrentActionsItems.MARK_PAYMENT_PROCESSED.actionType) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => {
                                setEnrollmentId((account && account.enrollmentId) || 0);
                                setAgentId((account && account.agent && account.agent.id) || 0);
                                setClient((account && account.client) || 0);
                                setClientId((account && account.client && account.client.id) || 0);
                                setClientUserId((account && account.client && account.client.user && account.client.user.id) || "");
                                setPaymentProcessedPopUp(true);
                            }}
                    >
                        {buttonText}
                    </Button>
            );
        }
        if ((actionType === CurrentActionsItems.UPLOAD_TRUSTEE_FINAL_DOCS.actionType) && role !== 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => getEnvelopeForTrustee((account && account.enrollmentId) || enrollmentId, offerType, "illustration")}
                    >
                        {buttonText}
                    </Button>
            );
        }
        // SIGN_TRUSTEE_DOCUMENTS
        if (actionType === CurrentActionsItems.SIGN_TRUSTEE_DOCUMENTS.actionType && role == 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => getEnvelopeForSignTrusteeDoc((account && account.enrollmentId) || enrollmentId, offerType)}
                    >
                        {buttonText}
                    </Button>
            );
        }
        if (actionType === CurrentActionsItems.SIGN_TRUSTEE_ILLUSTRATION.actionType && role == 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => getEnvelopeForSignTrusteeDoc((account && account.enrollmentId) || enrollmentId, offerType, "illustration")}
                    >
                        {buttonText}
                    </Button>
            );
        }

        // DOWNLOAD_TRUSTEE_DOCUMENTS
        if (actionType === CurrentActionsItems.DOWNLOAD_TRUSTEE_DOCUMENTS.actionType && role == 12) {
            return (
                    <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            className="floatRight btnSmall ml5"
                            type={"button"}
                            onClick={() => downloadEnvelope((account && account.enrollmentId) || enrollmentId, 6)}
                    >
                        {buttonText}
                        {downloadProgress.enrollmentId === ((account && account.enrollmentId) || enrollmentId) &&
                                <CircularProgress size={24}
                                                  className={classes.buttonProgress} />}
                    </Button>
            );
        }
        //mark_documents_approved
        if (actionType === CurrentActionsItems.MARK_DOCUMENTS_APPROVED.actionType && (role == 1 || role == 2)) {
            return (
                    <>
                        <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className="floatRight btnSmall ml5"
                                disabled={true}
                                type={"button"}
                                onClick={() => {
                                }}
                        >
                            Mark Documents Approved
                        </Button>
                        <Link variant="body1" className="mb0 mt5 ml15 floatRight"
                              onClick={() => props.history.push("/new-client/detail/" + ((account.client && account.client.id + `/documents`) || ""))}>
                            View documents</Link>
                    </>
            );
        }
        //clear Action item
        if (actionType === CurrentActionsItems.CLEAR_ACTION_ITEM.actionType && (role == 1 || role == 2)) {
            return (
                    <>
                        <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className="floatRight btnSmall ml5"
                                type={"button"}
                                onClick={async () => {
                                    const clientId = account && account.client && account.client.id;
                                    await accountActions.clearApprovalDocActionItem({ clientId });
                                    refreshPage();
                                }}
                        >
                            {buttonText}
                        </Button>
                    </>
            );
        }
        // cover letter required action item for admin
        if ((actionType === CurrentActionsItems.COVER_LETTER_REQUIRED.actionType ||
                actionType === CurrentActionsItems.TAX_DOCUMENT_INCOME_EARNER.actionType ||
                actionType === CurrentActionsItems.TAX_DOCUMENT_SPOUSE.actionType ||
                actionType === CurrentActionsItems.TAX_DOCUMENT_WEALTH_TRANSFER.actionType ) && (role == 1 || role == 2)) {
            return <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        if (account.status === 0) {
                            setClientDetail(account);
                            setTimeout(() => {
                                if (actionType !== "COVER_LETTER_REQUIRED") {
                                    setCoverLetterUpload(false);
                                }
                                setCoverLetterDialog(true);
                            }, 0);
                        }
                    }}
            >
                {CurrentActionsItems[actionType].actionButton}
            </Button>;
        }
        // approved_agent_seller_agreement_approval_for_admin
        if (actionType === CurrentActionsItems.AGENT_SELLER_AGREEMENT.actionType && (role == 1 || role == 2)) {
            return (
                    <CustomMuiButton
                            color="primary"
                            label={`Sign ${CurrentActionsItems[actionType].actionButton}`}
                            onClick={() => loadSellerAgreement(account.agent.id, account.id, account.agent.sellerAgreements[0].id)}
                            size="small"
                            variant="outlined"
                    />
            )
        }
    };

    const sellerCloseButtonProps = {
        id: "adminclosebtn",
        label: "Close",
        onClick: sellerAgreementClose,
        style: { display: "none" },
    };
    const sellerDialogProps: GenericDialogProps = {
        actions: (
                <Button {...sellerCloseButtonProps} />
        ),
        "aria-describedby": "seller-agreement-dialog",
        content: (
                <>
                    <div id="seller-agreement-dialog">
                        {
                                sellerAgreementLoading &&
                                <div className="mt30 textCenter">
                                    <img alt="Loading..." className="mb15" src={LoaderGif}/>
                                    <br/>
                                    <Typography variant="caption" className="w100">
                                        Signing document is being prepared. This could take some time so please be
                                        patient and don\'t close this window.
                                    </Typography>
                                </div>
                        }
                        <iframe
                                src={sellerAgreementUrl}
                                style={{width: "100%", height: "80vh"}}
                                title="seller-agreement-dialog"
                        />
                    </div>
                    <CustomMuiButton
                            id="viewer_iframe_confirm"
                            label="Confirm"
                            onClick={sellerAgreementClose}
                            style={{display: "none"}}
                    />
                </>
        ),
        fullWidth: !initFalse,
        keepMounted: !initFalse,
        maxWidth: "md",
        onClose: sellerAgreementClose,
        open: sellerAgreement,
        title: 'Approved Agent Selling Agreement',
    }

    const showPdfDialog = () => {

        return (
                <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={openPdf}
                        aria-labelledby="Enrollment Home"
                        aria-describedby="Enrollment process"
                ><DialogTitle id="customized-dialog-title">
                    View Documents
                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                        setDocumentUrl("");
                        setOpenPdf(false);
                    }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                    <DialogContent dividers>
                        <div id="alert-dialog-description" className="textCenter" style={{ height: "80vh" }}>
                            {inProgress ?
                                    <Grid container justify="center" spacing={2}>
                                        <Grid item xs={12} md={12} className="textCenter">
                                            <DocusignLoader />
                                        </Grid>
                                    </Grid>
                                    :
                                    <iframe
                                            className={"enrollmentIframe"}
                                            src={`${documentUrl}`}
                                            onLoad={noop}
                                            style={{width: "100%", height: "80vh"}}
                                    />
                            }
                        </div>
                    </DialogContent>
                    <DialogActions style={{ display: "none" }}>
                        <Button size="small" id={"closebtn"} color="primary" variant="contained"
                                className="mb20 mr30 mt15"
                                onClick={async () => {
                                    setDocumentUrl("");
                                    setOpenPdf(false);
                                    refreshPage();
                                    await accountActions.getFreshDataToRefreshReduxState();
                                }}
                                style={{ display: "none" }}
                        >
                            Close
                        </Button>
                        <Button size="small" id={"adminclosebtn"} color="primary" variant="contained"
                                className="mb20 mr30 mt15"
                                onClick={() => {
                                    setDocumentUrl("");
                                    setOpenPdf(false);
                                    refreshPage();
                                    setTimeout(() => accountActions.getFreshDataToRefreshReduxState(), 10000);
                                }}
                                style={{ display: "none" }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
        );
    };

    const showProposalPdfDialog = () => {
        return (
                <Dialog
                        fullWidth={true}
                        maxWidth={"lg"}
                        open={!!va(openProposalPdf)}
                        aria-labelledby="Enrollment Home"
                        aria-describedby="Enrollment process"
                >
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Client Proposal
                    </DialogTitle>

                    <DialogContent dividers>
                        {
                                // !!va(openProposalPdf) &&
                                <Grid container justify="center" spacing={2}>
                                    <ProposalTabs
                                            activeProposalTab={activeProposalTab}
                                            callBack={() => setEnableActions(true)}
                                            proposalObjArr={openProposalPdf}
                                            setActiveProposalTab={setActiveProposalTab}
                                    />
                                </Grid>
                        }
                    </DialogContent>
                    {
                            enableActions &&
                            <DialogActions>
                                {
                                        actionId > 0 &&
                                        <Button
                                                size="small"
                                                color="primary"
                                                variant="contained"
                                                className="floatLeft"
                                                onClick={approveProposal}
                                        >
                                            Approve
                                        </Button>
                                }
                                <Button
                                        size="small"
                                        color="secondary"
                                        variant="contained"
                                        className="floatRight"
                                        onClick={cancelProposal}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                    }
                </Dialog>
        );
    };

    const getRole = (role: number) => {
        if (role == 1 || role === 2) {
            return "Admin";
        }
        if (role == 3) {
            return "Imo";
        }
        if (role == 4) {
            return "Agent";
        }
        if (role == 12) {
            return "Trustee";
        }
        return "Client";
    };

    const getActionName = (account: any) => {
        let actionRequired: string = account.actionRequired;
        if (account && account.enrollmentNotes && account.enrollmentNotes.author) {
            actionRequired += " - " + account.enrollmentNotes.author.firstName + " " + account.enrollmentNotes.author.lastName + " (" + getRole(account.enrollmentNotes.author.role) + ")";
        }
        return actionRequired;
    };

    const createSortHandler = (property: keyof AccountSortList) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const createTicket = async (actionItem, detail, files) => {
        try {
            setActionItemSelected(actionItem);
            await ticketActions.createTicket({
                actionItemId: actionItem.id,
                detail,
                files,
            });
        } finally {
            setActionItemSelected(null);
            refreshPage();
        }
    };

    const resolveTicket = async (ticket) => {
        await ticketActions.resolveTicket(ticket.id);
        refreshPage();
    };


    return (
            <React.Fragment>
                {showPdfDialog()}
                {showProposalPdfDialog()}
                <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/css/enrollmentCss.css`} />
                <TableHead>
                    <TableRow>
                        {headRows.map((row: any, idx: number) => (
                                <TableCell
                                        key={`${idx}-${row.id}`}
                                        padding={"default"}
                                        sortDirection={orderBy === row.id ? order : false}
                                >
                                    {(row.id === "createdAt" || row.id === "actionRequired") ?
                                            <TableSortLabel
                                                    active={orderBy === row.id}
                                                    direction={order}
                                                    onClick={createSortHandler(value === 1 && row.id === "createdAt" ? "updatedAt" : row.id)}
                                            >
                                                {(value === 1 && row.id === "createdAt" ? "Completed On" : row.label)}
                                                {orderBy === row.id ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                                                        </span>
                                                ) : null}
                                            </TableSortLabel>
                                            :
                                            (value === 1 && row.id === "createdAt" ? "Completed On" : row.label)
                                    }

                                </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                            (!actionItemsListData || actionItemsListData.length === 0)
                            && <TableRow>
                                <TableCell className="textLeft" colSpan={9}>
                                    No Action list
                                </TableCell>
                            </TableRow>
                    }

                    {
                            actionItemsListData && actionItemsListData.map((actionItem: any) => {
                                return <ActionItemListRow actionItem={actionItem} role={role} history={props.history}
                                                          getEnvelope={getEnvelope} getActionName={getActionName}
                                                          setClientDetail={setClientDetail}
                                                          actionItemSelected={actionItemSelected}
                                                          createTicket={createTicket}
                                                          isViaAgentApprovalPage={isViaAgentApprovalPage}
                                                          resolveTicket={resolveTicket}
                                                          viewProposal={viewProposal}
                                                          handleRequestModification={handleRequestModification}
                                                          getAdminButton={getAdminButton}
                                                          setCoverLetterDialog={setCoverLetterDialog}
                                                          setCoverLetterUpload={setCoverLetterUpload}
                                />;
                            })
                    }
                </TableBody>

                <AdministratorMessage handleAdministratorMessage={handleAdministratorMessage} handleClose={handleClose}
                                      open={adminMessage} />
                <RequestModification handleAdministratorMessage={handleRequestModification} handleClose={handleClose}
                                     open={requestModification} enrollmentId={enrollmentId} />

                <ResetPayment handleDeclineEnrollment={handleResetPayment} handleClose={handleClose}
                              open={resetPayment} />
                {
                        (QCPopUpOpen) &&
                        <QCPopUp
                                refreshList={() => refreshPage()}
                                client={client}
                                setOpenPopup={setQCPopUpOpen}
                                openPopup={QCPopUpOpen}
                                enrollmentId={enrollmentId}
                        />
                }
                {
                        (CheckWirePopUpOpen) &&
                        <CheckWirePopup
                                refreshList={() => refreshPage()}
                                client={client}
                                setOpenPopup={setCheckWirePopUpOpen}
                                openPopup={CheckWirePopUpOpen}
                                enrollmentId={enrollmentId}
                        />
                }
                {
                        (uploadProposalPopUp) &&
                        <UploadProposalPopUp
                                client={client}
                                clientId={clientId}
                                enrollmentId={enrollmentId}
                                open={uploadProposalPopUp}
                                refreshList={() => refreshPage()}
                                setUploadProposalPopUp={setUploadProposalPopUp}
                        />
                }
                {
                        paymentReceivedPopUp &&
                        <PaymentReceivedPopUp setPaymentReceivedPopUp={setPaymentReceivedPopUp}
                                              open={paymentReceivedPopUp}
                                              enrollmentId={enrollmentId} clientId={clientId}
                                              agentId={agentId} clientUserId={clientUserId} client={client}
                                              refreshList={() => refreshPage()}
                                              setFilterType={setFilterType}
                                              role={role}
                        />
                }
                {
                        paymentProcessedPopUp &&
                        <PaymentProcessedPopUp
                                setPaymentProcessedPopUp={setPaymentProcessedPopUp}
                                open={paymentProcessedPopUp}
                                enrollmentId={enrollmentId}
                                clientId={clientId}
                                agentId={agentId}
                                clientUserId={clientUserId}
                                client={client}
                                refreshList={() => refreshPage()}
                                setFilterType={setFilterType}
                                role={role}
                        />
                }
                {
                        sellerAgreement &&
                        <GenericDialog {...sellerDialogProps} />
                }
                {
                        (coverLetterDialog && clientDetail) &&
                        <UploadSpecificDocument
                                clientDetail={clientDetail}
                                open={coverLetterDialog}
                                handleClose={async () => {
                                    setCoverLetterDialog(false);
                                    refreshPage();
                                }}
                                uploadCoverLetter={coverLetterUpload}
                                uploadAdditionalTaxDocs={!coverLetterUpload}
                                setCoverLetterReq={() => {
                                }}
                                coverLetterUploadedFromActionItem={coverLetterUpload}
                        />
                }

            </React.Fragment>
    );
}

export const TicketButton = ({ ticketId, onCreateTicket, disabled = false, onResolveTicket }) => {
    const exist = !!ticketId;

    const [isCreateTicketDialogOpen, setIsCreateTicketDialogOpen] = React.useState(false);
    const [ticketSelected, setTicketSelected] = React.useState<any>(null);


    const ticketActions = useActions(TicketActions);

    const handleClick = async () => {
        if (exist) {
            const ticket = await ticketActions.getTicket(ticketId);
            setTicketSelected(ticket);
        } else {
            setIsCreateTicketDialogOpen(true);
        }
    };


    const onCancel = () => setIsCreateTicketDialogOpen(false);
    const onOk = onCreateTicket;

    return (
            <>
                <Tooltip title={exist ? "" : "Create a Ticket"}>
                    <IconButton aria-label="ticket" onClick={handleClick} disabled={disabled}>
                        <ConfirmationNumberIcon color={exist ? "error" : "primary"} />
                    </IconButton>
                </Tooltip>
                <CreateTicketDialog
                        isDialogOpen={isCreateTicketDialogOpen}
                        onCancel={onCancel}
                        onOk={onOk}
                />
                {ticketSelected && (
                        <TicketDetailDialog
                                onClose={() => setTicketSelected(null)}
                                ticket={ticketSelected}
                                onResolveTicket={onResolveTicket}
                                isCompleted={ticketSelected.isResolved}
                        />
                )}
            </>
    );
};

export default ActionItemsList;
