import {ActionType} from "../model";
import {EstimationPayload} from "../model/estimation";
import request from "axios";
import {API_URL} from '../constants';
import {setWarning} from "./alert";

// Example POST method implementation:
async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    return response; // parses JSON response into native JavaScript objects
};

export const getEstimates = (payload: EstimationPayload) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let resp = await api.get(`estimation`, {}, payload);
		return dispatch({ type: ActionType.GET_ESTIMATION, payload: resp });
	} catch(err) {
		const errorEstimate = err && err.statusCode === 400 && err.message && err.message.includes("minimumAmount") && JSON.parse(err.message);
		if((payload.strategySlug === "kaizen" || payload.strategySlug === "trizen") &&  errorEstimate.error === "minimumAmount"){ // strategy is Kai-Zen and carrierName is not Allianz
			return dispatch({ type: ActionType.GET_ESTIMATION, payload: { statusCode : err.statusCode, minContributionAmount: errorEstimate.value} });
		}
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}
export const getInvitedClientEstimates = (payload: EstimationPayload, token:string) => async (dispatch: Function, getState: Function, api: any)  => {
	try {
		let resp = await request({
			method:'get',
			url: `${API_URL}/estimation/public`, 
			headers: {
				'Authorization': `Bearer ${token}`
			},
			params:payload
		});
        dispatch({ type: ActionType.GET_ESTIMATION, payload: resp });
		return resp;
	} catch(err) {
        const errorEstimate = err && err.response && err.response.data && err.response.data.statusCode === 400 && err.response.data.message && err.response.data.message.includes("minimumAmount") && JSON.parse(err.response.data.message);
        if((payload.strategySlug === "kaizen" || payload.strategySlug === "trizen") && errorEstimate.error === "minimumAmount"){
            return dispatch({ type: ActionType.GET_ESTIMATION, payload: { statusCode : err.response.data.statusCode, minContributionAmount: errorEstimate.value} });
        }
		return dispatch({ type: ActionType.ALERT, payload: err });
	}
}

export const getEstimatesFromCloud = (payload: any, role: number, values: any, userId, callingFrom:string|undefined) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.post('estimation/start', {...payload, userId}, {callingFrom});
        dispatch({
            type: ActionType.EXECUTION_ARN,
            payload: resp.executionArn
        });
        if (payload && payload.run && payload.run.stress && role === 4 && callingFrom === "INVITATION") {
            dispatch({
                type: ActionType.ADD_STRESS_UUID,
                payload: payload.id
            })
        } else if (payload && payload.run && !payload.run.stress && role === 4 && callingFrom === "INVITATION") {
            dispatch({
                type: ActionType.ADD_REPORT_UUID,
                payload: payload.id
            })
        }
        
        if (values && values.healthType) {
            dispatch({
                type: ActionType.ADD_INVITED_CLIENT_DETAIL,
                payload: {...values, gender: payload.common && payload.common.gender === 'female' ? 0 : 1, dob: payload.common && payload.common.dateofbirth || "" }
            })
        }
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        throw err;
    }
}
export const getIssueAge = (dob: string, carrierId: number) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get('estimation/issue/age', {}, {dob, carrierId});
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};
export const getEstimationProgress = (executionArn: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get('estimation/progress', {}, {executionArn: executionArn});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        throw err;
    }
};

export const getEstimationDetailsByUserId = (userId: string, callingFrom:string) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        let resp = await api.get('estimation/getEstimationDetailsByUserId', {}, {userId, callingFrom});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const clearEstimation = (callingFrom:string) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.delete('estimation/clearEstimation', {callingFrom}, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Estimation Cleared Successfully.", statusCode: 200}});
        //return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const confirmEstimation = (callingFrom:string) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        await api.post('estimation/confirmEstimation', {callingFrom}, {});
        dispatch({type: ActionType.ALERT, payload: {message: "Estimation Confirmed Successfully.", statusCode: 200}});
        return true;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}

export const getMinimumContribution = (queryString: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get('estimation/getMinimumContribution', {}, queryString);
        dispatch({type: ActionType.ADD_MINIMUM_CONTRIBUTION, payload: Number(resp.minimumContribution)});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}
export const getMinimumContributionPublic = (queryString: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get('estimation/getMinimumContribution/public', {}, queryString);
        dispatch({type: ActionType.ADD_MINIMUM_CONTRIBUTION, payload: Number(resp.minimumContribution)});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}
export const getQuickEstimation = (queryString: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get('estimation/quick', {}, queryString);
        dispatch({type: ActionType.QUICK_ESTIMATION, payload: resp});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
}
export const getQuickEstimationPublic = (queryString: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let resp = await api.get('estimation/quick/public', {}, queryString);
        dispatch({type: ActionType.QUICK_ESTIMATION, payload: resp});
        return resp;
    } catch (err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
    };

export const getAllocationQuestions = (payload:{estimatorId?:any}) => async (dispatch: Function, getState: Function, api: any)  => {
    try {
        return await api.get(`estimation/allocationQuestions`, {}, payload);
    } catch(err) {
        dispatch({
            type: ActionType.ALERT,
            payload: err
        });
        return false;
    }
};

/**
 * Setting Warning in Estimator
 * @param message
 */
export const setEstimatesWarning = (message: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch(setWarning(message));
}