import * as React from "react";
import {useFormikContext} from "formik";
import {ErrorCommon, NumberTextField, TextFieldCommon} from "../../../../../components/formikFormInputs";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Label} from "../../../../../components/formInputs";
import {FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography} from "@material-ui/core";
import {unmaskCurrency} from "../../../../../utils/commonUtils";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../reducers";
import {useEffect} from "react";
import {useCheckAtlantic} from "../../../../../hooks/useAuth";
import numeral from "numeral";
import { ATLANTIC_HEALTH, minContributionAmount } from "../../../../../constants";
import { MONTHLY } from "./ContributionFrequency";

const fieldsName = {
    "ADMIN_ACTION": {
        annualContribution:"contributionAmount",
        contributionType: "contributionTypeToggle"
    },
    "CLIENT": {
        annualContribution:"annualContribution",
        contributionType: "contributionType"
    },

}

export default function NewEmployerContribution(props: { atlantic?: boolean, clientDetails?: any, RadioFieldName?:string, Tag?:"ADMIN_ACTION" | "CLIENT"}) {
    const {values, errors, touched, handleChange, setFieldValue, validateForm}: any = useFormikContext();
    const { atlantic, clientDetails, RadioFieldName = "contributionType", Tag = "CLIENT"} = props;
    let client:any = {} 
    let {user} = useSelector((state: RootState) => state.auth); 
    if(!clientDetails && user){
        client = user.client
    }

    if(clientDetails){
        client = clientDetails
    }
    const match = client && client.match;
    const cap = client && client.cap;
    const showNewContribution = useCheckAtlantic()
    const companyLinkUrl = user && user.client && user.client.company && user.client.company.companyLinkUrl;
    let disablemonthly = companyLinkUrl === ATLANTIC_HEALTH[0];
    const getMaxEmployerContribution = (value: number, percentage: number, maximum: number) => {
        let maxEC = (value / 100) * percentage;
        if (maximum && maxEC > maximum) {
            return maximum;
        }
        return maxEC;
    }
    const fields = fieldsName[Tag]
    let contributionType = RadioFieldName || "contributionType"
    let totalContribution: string = "Total Contribution";
    const setFieldValueC = (fieldName, value) => {
        values[fieldName] = value;
        validateForm(values);
        setFieldValue(fieldName, value)
    }
    const setTotalEmployerContribution = () => {
        console.log(fields.annualContribution ,"here is the contribution")
        let clientContribution = Number(unmaskCurrency(values[fields.annualContribution]));
        //Added Atlantic Exception for annual employer contribution only
        if ( values["contributionFrequency"] === MONTHLY) {
            clientContribution = clientContribution * 12;
        }
        const contribution = clientContribution + Math.floor(Number(unmaskCurrency(values['employerContribution'])));
        setFieldValueC('totalCombinedContribution', contribution ? contribution : '')
    }

    const setEmployerContribution = (maxAmount: number) => {
        if (values[contributionType] === "1") {
            return setFieldValue('employerContribution', maxAmount)
        }
        return setFieldValue('employerContribution', values['employerContribution'])
    }
    useEffect(() => {
        if (atlantic) {
            match && setFieldValue("match", match);
            cap && setFieldValue("maximum", cap);
            disablemonthly && setFieldValue("contributionFrequency", MONTHLY);
            setFieldValue(contributionType, "1");
        }
        return () => {
            if (atlantic) {
                setFieldValue("match", "");
                setFieldValue("maximum", '');
            }
        };
    }, [atlantic]);
    React.useEffect(() => {
        setTotalEmployerContribution()
        let clientContribution = Number(unmaskCurrency(values[fields.annualContribution]));

        //Added Atlantic Exception for annual employer contribution only
        if (atlantic && values["contributionFrequency"] === MONTHLY) {
            clientContribution = clientContribution * 12;
        }
        const maxContribution = getMaxEmployerContribution(clientContribution, Number(unmaskCurrency(values['match'])), Number(unmaskCurrency(values['maximum'])));
        setEmployerContribution(maxContribution);
        //This is bad code causing issue hence commented
        // return () => {
        //     setFieldValue("totalCombinedContribution", "");
        // };
    }, [values[contributionType], values[fields.annualContribution], values['match'], values[contributionType], values['employerContribution'], values['maximum']]);
    const isDisabledUndefined = atlantic === undefined
    const newemployerContributionFields = [
        {
            id: 'employerContribution',
            name: "employerContribution",
            component: NumberTextField,
            disabled: (isDisabledUndefined ? values[contributionType] === "1" : atlantic),
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon" position="start">$</InputAdornment>,
            },
            value: (values['employerContribution']) && Math.floor(Number(unmaskCurrency(values['employerContribution'].toString()))),
            label: <Label label={atlantic ? "Employer Annual Contribution" : "Employer Contribution"}/>,
            variant: "outlined",
            className:"mt15",
        },
        {
            id: 'totalCombinedContribution',
            name: "totalCombinedContribution",
            disabled: (isDisabledUndefined ? true : atlantic || Tag),
            component: NumberTextField,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon" position="start">$</InputAdornment>,
            },
            label: <Label
                label={atlantic ? "Your Contribution & Employer Contribution Total (Annually)" : "Total Contribution"}/>,
            variant: "outlined",
        },
    ];
    const contributionTypeFileds = [
        {
            id: 'match',
            name: "match",
            component: NumberTextField,
            value: values['match'],
            disabled: !!atlantic,
            InputProps: {
                endAdornment: <InputAdornment className="margin0 dollerIcon" position="start">%</InputAdornment>,
            },
            label: <Label label={"Match"}/>,
            variant: "outlined",
        },
        {
            id: "maximum",
            name: "maximum",
            component: NumberTextField,
            disabled: !!atlantic,
            InputProps: {
                startAdornment: <InputAdornment className="margin0 dollerIcon" position="start">$</InputAdornment>,
            },
            label: <Label label={atlantic ? "Maximum Match Amount" : "Set Maximum Match Amount"}/>,
            variant: "outlined",
        },
    ];


    return (
        <>
                <Grid item xs={12}>
                    {!atlantic && <FormLabel style={{marginBottom:"15px !important"}} component="div" className="mb15 floatLeft w100">Select Employer Contribution
                        Type:</FormLabel> }
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            {!atlantic && <RadioGroup
                                className="floatLeft w100 displayUnset"
                                onChange={(event) => {
                                    setFieldValue(contributionType, event.currentTarget.value)
                                }}
                                name={contributionType}
                                value={atlantic ? "1" : values[contributionType]}
                            >
                                <FormControlLabel
                                    className="radioGroup mb15 floatLeft"
                                    style={{alignItems: "center"}}
                                    value={"1"}
                                    disabled={!!atlantic}
                                    control={<Radio color="primary"/>}
                                    label={"Match"}/>
                                <FormControlLabel
                                    className="radioGroup mb15 floatLeft"
                                    style={{alignItems: "center"}}
                                    value={"0"}
                                    disabled={!!atlantic}
                                    control={<Radio color="primary"/>}
                                    label={"Set Amount"}/>
                            </RadioGroup>}
                            {
                                !!atlantic && <Typography>
                                    Your employer has agreed to match {match}% of your contribution to a maximum of {numeral(cap).format("$0,0")}.
                                    Employer payments will be made annually on the policy anniversary date. Your payments
                                    will be made at the first of each month.
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={2}>
                                {
                                    values[contributionType] == "1" && contributionTypeFileds.map((field, index) => {
                                        return (
                                            <Grid item xs={12} md={12} key={index}>
                                                <TextFieldCommon
                                                    {...field}
                                                    values={values}
                                                    onChange={handleChange}
                                                />
                                                <ErrorCommon errors={errors}
                                                    name={field.name}
                                                             touched={touched}/>
                                            </Grid>);
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    newemployerContributionFields.map((field, index) => {
                        return (
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Grid container key={index}>
                                        <Grid item xs={12}>
                                            {index === 1 && (
                                                <Typography
                                                    className="mb20 mt15 floatLeft w100"
                                                    component="strong"
                                                    variant="body1"
                                                >
                                                    {totalContribution}
                                                </Typography>
                                            )}
                                            <TextFieldCommon
                                                {...field}
                                                values={values}
                                                onChange={handleChange}
                                            />
                                            <ErrorCommon errors={errors}
                                                name={field.name}
                                                touched={touched} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })
                }

        </>
    );
};