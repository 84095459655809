
import * as React from "react";
import TextField from "@material-ui/core/TextField";
interface FieldProps {
    field: {
        name: string,
        onChange: Function,
    },
    placeholder: string,
    label: string,
    value: string,
    helperText?: string
}
const Text = (props: FieldProps) => {
    const { field: { name }, placeholder, label, value, ...custom } = props;
    return <TextField
            fullWidth
            name={name}
            placeholder={placeholder}
            variant="outlined"
            label={label}
            value={value}
            InputLabelProps={{ shrink: !!value }}
            {...custom}
    />;
};

export default Text;