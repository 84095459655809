import * as React from "react";
import { List } from "@material-ui/core";
import NiwListItem from "../NiwListItem";
import {
    Home as HomeIcon,
    NotificationsNone as NotificationsNoneIcon,
    People as PeopleIcon,
    DateRange as DateRangeIcon,
    AccountBox as AccountBoxIcon,
    OndemandVideo as OndemandVideoIcon,
    Comment as CommentIcon, LocalAtm as LocalAtmIcon, Payment as PaymentIcon
} from "@material-ui/icons";

import { history } from "../../configureStore";
interface listItemType {
    url : string,
    title : string,
    listIcon : any 
}

function ImoNavigation(props:{ handleNavClick: Function }) {
    const {handleNavClick} = props;
	const [routeName, setRouteName] = React.useState(window.location.pathname);

	const navigationsList = [
		{
			url : '/imo-dashboard',
			title : 'IMO Dashboard',
			listIcon : <HomeIcon/>
		},
		{
			url : '/imo',
			title : 'Agent Management',
			listIcon : <PeopleIcon/>
		},
		{
			url : '/imos/events',
			title : 'Events & Webinars',
			listIcon : <DateRangeIcon/>
		},
		{
			url : '/imos/ILIATrainingPage',
			title : 'ilia Training',
			listIcon : <AccountBoxIcon/>
		},
        {
            url : '/ilia-notifications',
            title : 'ilia Notifications',
            listIcon : <NotificationsNoneIcon/>
        },
        {
            url : '/imos/marketing-material',
            title : 'Marketing Materials',
            listIcon : <OndemandVideoIcon/>
        },
        {
            url : '/imos/news-social',
            title : 'News & Social Posts',
            listIcon : <CommentIcon/>
        },
        {
            url : '/tranche-dates',
            title : 'Tranche Dates',
            listIcon : <LocalAtmIcon/>
        },
		{
            url : '/client-payments',
            title : 'Client Payment Options',
            listIcon : <PaymentIcon/>
        },
        //Not Needed Until Client Approves
        // {
        //     url : '/reports',
        //     title : 'Reporting',
        //     listIcon : <ReceiptIcon/>
        // }
	];

	React.useEffect(() => {
		if(window.location.pathname === '/accounts'){
			setRouteName( '/imo-dashboard' );			
		} else{
			setRouteName( window.location.pathname );
		}
		
	}, [routeName, history.location.pathname]);

	return (
		<List className="mt20 mb20">
		{
			navigationsList.map( ( listItem : listItemType ) => {
				return (
					<NiwListItem 
						key={listItem.url}
						url={listItem.url} 
						title={listItem.title} 
						listIcon={listItem.listIcon} 
						routeName={routeName}
						setRouteName={setRouteName}
                        user={null}
                        handleNavClick={handleNavClick}
					/>
				) 
			}
			)
		}
		</List>
	);
}

export default ImoNavigation;