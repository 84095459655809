import React from "react";
import { useFormikContext } from "formik";
import PaymentFileUploader from "../../../../clientServicing/Payments/PaymentFileUploader";
import PaymentFileViewer from "../../../../clientServicing/Payments/PaymentFileViewer";
import { ErrorCommon } from "../../../../formikFormInputs";

export interface UploadVoidedCheck {
    fileName : string,
    directory: string,
    previewTitle?: string,
    title?: string,
    fieldName?: string,
    disabled?:boolean

}
// filePath will be set in form filed {fieldName} if it send through the props
function UploadAndPreview( props: UploadVoidedCheck ) {
    const { fileName, directory, title, previewTitle, fieldName, disabled = false } = props;
    const fileType = "image/jpg, image/jpeg, image/png, application/pdf";
    const {values, setFieldValue, errors, touched}: any = useFormikContext<any>();
    const setFrontPath = (filePath: string) => {
        fieldName && setFieldValue(fieldName, filePath);
    };

    return (<>
                    {!(fieldName && values[fieldName]) && <>
                        <PaymentFileUploader
                                isSecuredSignedUrl={true}
                                fileType={fileType}
                                dirName={directory}
                                fileName={fileName}
                                setFileName={setFrontPath}
                                title={title || ""}
                                inputPops={{disabled}}
                        />
                        {fieldName && <ErrorCommon errors={errors}
                                      name={fieldName}
                                      touched={touched} />}
                    </>
                    }
                    {(fieldName && values[fieldName]) && directory &&
                            <PaymentFileViewer
                                    fileName={(fieldName && values[fieldName])}
                                    policyNumber={directory}
                                    title={previewTitle || title}
                                    readOnly={true}
                                    deleteFile={() => {
                                        setFrontPath('');
                                    }}
                            />
                    }
            </>
    );
}

export default UploadAndPreview;