import * as React from "react";
import { Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import RectangleShape from "../../../../components/client/landingPage/v2/images/RectangleShape.svg";
import imgHandWithIpadGraphsBgnone
    from "../../../../components/client/landingPage/images/img-hand-with-ipad-graphsbgnone.png";
import StrategyName from "../../../../components/client/StrategyName";
import { pez } from "../../../../utils/commonUtils";




const LandingSectionTwo = (props: any) => {
    const strategyName: string = pez(props.strategyData && props.strategyData.displayName, 'Kai-Zen');
    const strategyId: string = props.strategyData && props.strategyData.id || 1;
    const strategy = (
        <StrategyName
            tooltip={true}
            includeThe={false}
            strategyName={strategyName}
            registrationMarkLandingPage={true}
            parentComponentStrategyId={Number(strategyId)}
        />
    );
    return (
            <div id={"section1"} className="sectionShape">
                <Grid container justify="center">
                    <Grid item xs={12} md={11} lg={10} xl={9} className="textLeft pL7Rem">
                        <Grid container justify="center">
                            <Grid item xs={12} md={6} lg={6} data-aos="fade-zoom-in" data-aos-duration="1000">
                                <Typography variant="h1" align="left" className="colorWhite tittle"> Why using
                                    leverage
                                    is smart.</Typography>
                                <Typography variant="body1" align="left" className="colorWhite">We have all used
                                    leverage to purchase a nicer house or
                                    to buy a better car. {strategy} uses leverage for the
                                    potential to
                                    accumulate more
                                    growth & obtain more protection, while providing the ability to maintain your
                                    current
                                    standard of living. That’s the smart way to use leverage.
                                </Typography>
                                <List className="ulList colorWhite">
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLight"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="Potential for more accumulation."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLight"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="Maintain current lifestyle."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLight"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="More protection for you and your family."
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckIcon className="textHighLight"/>
                                        </ListItemIcon>
                                        <ListItemText
                                                primary="Diversify your assets."
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="displayNone1200Down">
                                    <img className="imgResponsive" src={imgHandWithIpadGraphsBgnone} alt="#"/>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <img alt="" className="rectangleShape" src={RectangleShape}/>

            </div>
    );
};

export default LandingSectionTwo;
