import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Link, TableCell, TableRow } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { dateUtils } from "../utils";
import { ActionItems, ActionItems as CurrentActionsItems, cashStreamSlug, GWT_GEN2_STRATEGY } from "../constants";
import { TicketButton } from "../pages/ActionItemsList";
import { tr } from "date-fns/locale";
import EpayRejectPayment from "./EpayRejectPayment";
import RejectPayment from "./RejectPayment";

const AgentActionTypeButton = (props: {
    actionItem: any,
    getEnvelope: Function,
    setClientDetail: Function,
    setCoverLetterUpload: Function,
    setCoverLetterDialog: Function,
    history: any
}) => {
    const { actionItem, getEnvelope, setClientDetail, setCoverLetterDialog, setCoverLetterUpload } = props;
    const offerType = actionItem && actionItem.client && actionItem.client.underWritingOfferType || actionItem && actionItem.client && actionItem.client.underWritingOfferTypeFinal;
    const navigateTo = useHistory();

    if (actionItem.actionType === "COVER_LETTER_REQUIRED" ||
            actionItem.actionType === "TAX_DOCUMENT_INCOME_EARNER" ||
            actionItem.actionType === "TAX_DOCUMENT_SPOUSE" ||
            actionItem.actionType === "TAX_DOCUMENT_WEALTH_TRANSFER") {

        return <Button
                className="textBlue"
                size="small"
                variant="outlined"
                color="primary"
                disabled={actionItem.status === 1}
                onClick={() => {
                    if (actionItem.status === 0) {
                        setClientDetail({ ...actionItem });
                        setTimeout(() => {
                            if (actionItem.actionType !== "COVER_LETTER_REQUIRED") {
                                setCoverLetterUpload(false);
                            }
                            setCoverLetterDialog(true);
                        }, 0);
                    }
                }}
        >
            {actionItem.status === 0 ? CurrentActionsItems[actionItem.actionType].actionButton : "Uploaded Cover Letter"}
        </Button>;
    } else if (actionItem && actionItem.actionType === "GREAT_WEALTH_TRANSFER") {
        return (
                <Button
                        className="btnSmall textBlue"
                        color="primary"
                        onClick={() => navigateTo.push(`/new-client/detail/${(actionItem.client && actionItem.client.id) || ""}/case-details`)}
                        size="small"
                        type={"button"}
                        variant="outlined"
                >
                    {CurrentActionsItems[actionItem.actionType].actionButton}
                </Button>
        );
    }
    return <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={
                // cover letter 1 or (additional tax 1 and Not Gen2 type)
                    (actionItem && actionItem.client && (actionItem.client.coverLetterReq === 1 || (actionItem.client.strategy && actionItem.client.strategy.id !== GWT_GEN2_STRATEGY && actionItem.client.additionalTaxDocReq === 1))) || false
            }
            onClick={() => {
                actionItem.status === 0 ? getEnvelope(actionItem.enrollmentId, "", offerType) : getEnvelope(actionItem.enrollmentId, 2, offerType);
            }}
    >
        {actionItem.status === 0 ?
                actionItem && actionItem.client && actionItem.client.strategy && actionItem.client.strategy.slug !== cashStreamSlug ? "Sign Application Docs" : "Approve Application Docs"
                : "Signed Application (View)"}
    </Button>;
};

const getAgentName = (agent: any) => {
    return agent.user ? (agent.user.firstName + " " + agent.user.lastName) : "";
};

function ActionItemListRow(props: {
    actionItem: any,
    role: number,
    history: any,
    getEnvelope: Function,
    getActionName: Function,
    setClientDetail: Function
    actionItemSelected: any,
    createTicket: Function,
    resolveTicket: Function,
    viewProposal: Function,
    handleRequestModification: Function,
    getAdminButton: Function,
    setCoverLetterDialog: Function,
    setCoverLetterUpload: Function,
    isViaAgentApprovalPage: boolean,
}) {
    const {
        actionItem,
        role,
        getEnvelope,
        getActionName,
        setClientDetail,
        actionItemSelected,
        createTicket,
        isViaAgentApprovalPage,
        resolveTicket,
        viewProposal,
        handleRequestModification,
        getAdminButton,
        setCoverLetterDialog,
        setCoverLetterUpload,
    } = props;

    let additionalDocName = "";
    let docReqText = "";
    const offerType = actionItem && actionItem.client && actionItem.client.underWritingOfferType || actionItem && actionItem.client && actionItem.client.underWritingOfferTypeFinal;
    const isGWTGen2 = actionItem.client && actionItem.client.strategy.id === GWT_GEN2_STRATEGY;
    if (actionItem && actionItem.actionType === "GREAT_WEALTH_TRANSFER" && actionItem.client && actionItem.client.coverLetterReq === 1) {
        docReqText = "This is a Great Wealth Transfer Case. please complete the GWT section in case details for this case.";
    }
    if (!isGWTGen2 && actionItem && actionItem.actionType === "SIGN_AGENT" && actionItem.client && actionItem.client.coverLetterReq === 1) {
        docReqText = "A cover letter must be added before agent can sign application";
    }
    if (!isGWTGen2 && actionItem && actionItem.actionType === "SIGN_AGENT" && actionItem.client && actionItem.client.additionalTaxDocReq === 1) {
        if (actionItem.client && actionItem.client.currentlyEmployed.toLowerCase() === "other") {
            if (actionItem.client.parentsAreIncomeEarner) {  //WT
                additionalDocName = `Wealth Transfer docs`;
            } else {
                additionalDocName = `Income Earner docs`;
            }
        } else if (actionItem && actionItem.client && actionItem.client.usingSpouseIncome === 1) {
            additionalDocName = `Spouse docs`;
        }
        if (!docReqText) {
            docReqText = `${additionalDocName} must be added before agent can sign application`;
        } else { // case if cover letter requirement is there
            docReqText = `A cover letter and ${additionalDocName} must be added before agent can sign application`;
        }
    }

    return <>
        <TableRow hover key={actionItem.id}>
            <TableCell>
                {
                        actionItem.agent && actionItem.client &&
                        (
                                <Tooltip
                                        arrow
                                        placement="right-start"
                                        title={
                                            <Typography
                                                    variant="caption"
                                                    className="mb0"
                                            > Take me to clients details screen. </Typography>
                                        }
                                >
                                    <Link onClick={() => props.history.push("/new-client/detail/" + ((actionItem.client && actionItem.client.id + `/${role === 12 ? "details" : role === 4 ? "case-details" : "action-items"}`) || ""))}>
                                        {actionItem.client.user.firstName + " " + actionItem.client.user.lastName}
                                    </Link>
                                </Tooltip>
                        )
                }
                {
                        actionItem.agent && !actionItem.client &&
                        <Tooltip arrow title={`Agent - ${getAgentName(actionItem.agent)}`}>
                            <span>{getAgentName(actionItem.agent)}</span>
                        </Tooltip>
                }
            </TableCell>
            <TableCell>
                {
                    getActionName(actionItem)
                }
            </TableCell>
            {
                    role !== 4 && (
                            <TableCell>{actionItem.agent.user.firstName + " " + actionItem.agent.user.lastName}</TableCell>
                    )
            }
            <TableCell>{dateUtils.asDbDateFormat(actionItem.status === 0 ? actionItem.createdAt : actionItem.updatedAt)}</TableCell>
            {
                    role <= 2 && !isViaAgentApprovalPage &&
                    <TableCell>
                        {actionItem.client && actionItem.client.tranche && actionItem.client.tranche.trancheNumber}
                    </TableCell>
            }
            {
                    !isViaAgentApprovalPage &&
                    <TableCell>
                        {actionItem.client && actionItem.client.imo && actionItem.client.imo.region}
                    </TableCell>
            }
            {
                    role <= 2 && !isViaAgentApprovalPage &&
                    <TableCell>
                        <TicketButton
                                ticketId={actionItem.currentTicket}
                                disabled={actionItemSelected === actionItem}
                                onCreateTicket={({
                                                     detail,
                                                     files,
                                                 }) => createTicket(actionItem, detail, files)}
                                onResolveTicket={resolveTicket} />
                    </TableCell>
            }
            {
                role === 4 ?
                        <TableCell>
                            {
                                actionItem.actionType === "VIEW_APPROVE" ?
                                        <Button
                                                size="small"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    viewProposal(actionItem.id, actionItem.enrollmentId, actionItem.status, actionItem.client);
                                                }}
                                        >
                                            {actionItem.status === 0 ? "View and Approve Proposal" : "View Proposal"}
                                        </Button> :
                                        <>{docReqText &&
                                                <Typography className="colorRed">{docReqText}</Typography>}
                                            <AgentActionTypeButton actionItem={actionItem}
                                                                   getEnvelope={getEnvelope}
                                                                   setClientDetail={setClientDetail}
                                                                   setCoverLetterUpload={setCoverLetterUpload}
                                                                   setCoverLetterDialog={setCoverLetterDialog}
                                                                   history={props.history}
                                            />
                                        </>
                            }
                            {
                                    CurrentActionsItems.VIEW_APPROVE.actionType === actionItem.actionType && actionItem.status === 0 &&
                                    <Button
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            className="btnSmall"
                                            type={"button"}
                                            onClick={() => handleRequestModification(actionItem.enrollmentId)}
                                    >
                                        Request Modification
                                    </Button>
                            }
                        </TableCell> :
                        <TableCell>
                            {
                                    (role === 1 || role === 2 || role === 12) &&
                                    getAdminButton(actionItem.actionType, CurrentActionsItems[actionItem.actionType] ? CurrentActionsItems[actionItem.actionType].actionButton : "", role, actionItem, offerType)
                            }
                        </TableCell>
            }
        </TableRow>

    </>;
}

export default ActionItemListRow;