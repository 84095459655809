import React from 'react';
import {useActions} from "../../../../actions";
import * as AccountActions from "../../../../actions/account";
import {RouteComponentProps} from "react-router-dom";
import LandingPage from "../v2/LandingPage";
import TrizenLandingPage from "../v2/trizen/LandingPage";
import CommonLoader from "../../../../components/CommonLoader";

interface Props extends RouteComponentProps<void> {}

type landingPageProps = {
    accountType: string,
    verifiedInvite: boolean | undefined;
    checked: boolean,
    updated: boolean,
    firstName: string,
    phoneNumber: string,
    email: string,
    role: number | undefined;
    strategy: any | undefined;
    agent: any,
    client: any,
    authenticType: string,
    companyId: number,
    groupDetail: any,
    carrier: any,
    nationalLife?: any,
    notAuthenticated?: boolean,
    linkExpiry?: string
}

export default function GroupInvitationPage(props: Props) {
  const params: any = props.match.params;
  const [loading, setLoading] = React.useState(true);
  const [landingpageData, setLandingpageData] = React.useState( { accountType:'GROUP', agent : {imo:{ reBrand : 0, reBrandStrategy : '' }}, strategy:{id:0,slug:'', reBrand : 0}  } );
  const [linkExpired, setLinkedExpired] = React.useState(false);
  const accountActions = useActions(AccountActions);

  React.useEffect( () => {
      const getGroup = async () => {
          const response = await accountActions.getGroup(params.id);
          const resp:any = response.data;
          let landingPageProps : landingPageProps = {
              accountType : 'GROUP',
              verifiedInvite: false,
              checked: false,
              updated: false,
              firstName: '',
              phoneNumber: '',
              email: '',
              role: 6,
              strategy: resp && resp.strategies ? resp.strategies : null,
              client: null,
              agent: resp && resp.agent ? resp.agent : null,
              authenticType: (resp && resp.authenticType) || '',
              companyId: (resp && resp.id) || 0,
              groupDetail: resp,
              carrier : resp && resp.carrier,
              nationalLife : (response && response.nationalLife) || null,
              notAuthenticated: !!(resp && resp.notAuthenticated),
              linkExpiry: (resp && resp.linkExpiry) || ""
          }
          setLandingpageData(landingPageProps);
          setLoading(false);
          setLinkedExpired(!!(resp && resp.linkExpired))
      }
    getGroup();
  }, []);

    if( loading ){
        return (
                <CommonLoader />
        );
    }

  let isStrategyESDTrizen:boolean = landingpageData && landingpageData.strategy && (landingpageData.strategy.slug === 'trizen' || landingpageData.strategy.slug === 'esd');

  if (linkExpired) {
      window.location.href = "/login";
  }
  if( isStrategyESDTrizen ){
      return (
              <TrizenLandingPage {...props} {...landingpageData} />
      );
  }

  return (
        <React.Fragment>
            <LandingPage {...props} {...landingpageData} />
        </React.Fragment>
  );
}

