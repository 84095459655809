import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DescriptionIcon from "@material-ui/icons/Description";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import InfoIcon from "@material-ui/icons/Info";
import VideocamIcon from "@material-ui/icons/Videocam";
import SecurityIcon from "@material-ui/icons/Security";
import {useHistory} from "react-router-dom";
import ExtensionIcon from "@material-ui/icons/Extension";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import CommentIcon from "@material-ui/icons/Comment";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import {AccountType, StrategySlugEnum} from "../../constants";
import * as React from "react";
import {AccountStatus} from "../../model";
import {useSelector} from "react-redux";
import {RootState} from "../../reducers";
import ListAltIcon from "@material-ui/icons/ListAlt";
import {isDebug, isFunc, noop, va} from "../../utils/commonUtils";
import {isNfIulCase} from "../../utils/commonFunc";

const myInformationTabs = [
    "/dashboard",
    "/intro",
    "/client/estimation",
    "/client/estimation-new",
    "/client/estimationV1",
    "/financialconcerns",
    "/howitwork",
    "/SplitDollar",
    "/product",
    "/leverage",
    "/minimumrisk",
    "/plandetails",
    "/news-social",
    "/participants",
    "/testimonials",
    "/testimonials-aon",
    "/news-social",
    "/faq",
];

const clientServiceTabs = [
    "/client/dashboard",
    "/client/payments",
    "/client/plandocuments",
    "/client/annualreview",
    "/client/mydetails",
    "/client/claims",
    "/client/questions",
    "/client/referralfeedback",
    "/client/surrenderplan",
    "/client/contactus",
    "/profile",
    "/account",
    "/bugs-feedback",
];

interface CommonListProps {
    enrollmentClick: (user: any) => Promise<any>;
    enrollmentStatus: any;
    enrollmentTabse: string[];
    estimationRoute: string;
    handleNavClick: any;
    handleNavigation: (route: string) => void;
    isClientDemoAccess: boolean;
    isServiceDashboardAccessible: any;
    routeName: string;
    SetIsEstimation: any;
    setRouteName: (value: (((prevState: string) => string) | string)) => void;
    strategySlug: string;
    user: any;
}

export default function CommonList(props: CommonListProps) {
    let reBrand: any = 0;
    let strategyName: any = "Kai-Zen";
    const {
        handleNavigation,
        estimationRoute,
        routeName,
        user,
        setRouteName,
        SetIsEstimation,
        handleNavClick,
        isServiceDashboardAccessible,
        strategySlug,
        isClientDemoAccess,
        enrollmentTabse,
        enrollmentClick,
        enrollmentStatus,
    } = props;
    const estimationRouteArray: any = ["/client/estimation", "/client/estimationV1"];
    const [showServicingToggle, setShowServicingToggle] = React.useState(false);
    const history = useHistory();
    const {user: {client, accessType}} = useSelector((state: RootState) => state.auth);
    const strategyId = (user.client && user.client.strategy && user.client.strategy.id) || (user && user.demoStrategyId);
    const isNfIul = isNfIulCase(strategyId, strategySlug);
    let showMarketing: number | undefined = 1;
    const [showToggle, setShowToggle] = React.useState(true);
    const enrollment = useSelector((state: RootState) => state.enrollment);
    const [estimationVersion, setEstimationVersion] = React.useState<number>(2);

    if (client) {
        showMarketing = client.showMarketing || (strategySlug === "cashstream" ? 0 : 1);
        reBrand = client && client.imo && client.imo.reBrand && client.strategy && client.strategy.reBrand === 2;
        if (client.strategy && client.strategy.reBrand === 1) {
            reBrand = 1;
        }
    }

    if (user && user.role === 6) {
        if (user && user.client && user.client.company && user.client.company.id) {
            showMarketing = user.client.company.showMarketing ? 1 : 0;
        }
    }

    if (client) {
        strategyName = (client.strategy && client.strategy.name) || strategyName;
    } else if (accessType === "client" && (user.role === 1 || user.role === 2 || user.role === 3 || user.role === 4 || user.role === 15 || user.role === AccountType.Aggregator || user.role === AccountType.AggregatorMember) && user.demoStrategy) {
        strategyName = (user.demoStrategy && user.demoStrategy.name) || strategyName;
        if (user.demoStrategy.reBrand === 1) {
            reBrand = 1;
        }
        if (user.role === 3) {
            if (user.imo && user.imo.reBrand && user.demoStrategy.reBrand === 2) {
                reBrand = 1;
            }
        }
        if (user.role === 4) {
            if (user.agent && user.agent.imo && user.agent.imo.reBrand && user.demoStrategy.reBrand === 2) {
                reBrand = 1;
            }
        }
        if (user.role === 15) {
            if (user.hasOwnProperty("ImoAdditionalMembers")) {
                if (user.ImoAdditionalMembers && user.ImoAdditionalMembers.imo && user.ImoAdditionalMembers.imo.reBrand === 1) {
                    reBrand = 1;
                }
            }
        }
    }

    const showEstimator = !(strategySlug === StrategySlugEnum.TriZen || strategySlug === StrategySlugEnum.EnhancedSplitDollar);


    React.useEffect(() => {
        if ((history.location.pathname === "/accounts" || history.location.pathname === "/intro") && user && user.client && showMarketing == 0) {
            //MS: Servicing Visibility to client yet
            if (user.inviteStatus >= AccountStatus.TrusteeSignAndEnrollmentClosed) {
                setRouteName("/client/dashboard");
                history.push("/client/dashboard");
            } else {
                setRouteName("/new-enrollment");
                history.push("/new-enrollment");
            }
        }
    }, [user && user.client]);

    React.useEffect(() => {
        if (user.enrollment && history.location.pathname === "/enrollment") {
            history.push("/enrollment/start");
        }
        setRouteName(history.location.pathname);
        if (myInformationTabs.indexOf(routeName) > -1) {
            setShowToggle(true);
        }
        if (clientServiceTabs.indexOf(routeName) > -1) {
            setShowServicingToggle(true);
        } else {
            setShowServicingToggle(false);
        }
        if (history.location.pathname.search("/client/estimation-new") > -1) {
            SetIsEstimation(true);
        } else {
            SetIsEstimation(false);
        }
    }, [user.enrollment, routeName, enrollment, history.location.pathname]);

    const onEnrollmentBegin = async () => {
        await enrollmentClick(user);
        handleNavClick();
    }
    const onServicingToggle = (routeName: string) => {
        debugger;
        setShowServicingToggle(!showServicingToggle);
        handleNavigation(routeName);
    };

    const isServicingAccess = (isClientDemoAccess || isServiceDashboardAccessible);

    type Menu = {
        enrollment: MenuType[];
        main: MenuType[];
        product: MenuType[];
        productOptions: MenuType[];
        servicing: MenuType[];
    };
    type MenuType = {
        className?: string;
        disabled?: boolean;
        iconPostfix?: JSX.Element;
        iconPrefix?: JSX.Element;
        isShown: boolean;
        label: string | JSX.Element;
        labelClass?: string;
        onNavClick?: Function;
        routeName?: string;
        subList?: MenuType[];
    };
    const menu: Menu = {
        enrollment: [
            {
                className: `menuHead ${(enrollmentTabse.indexOf(history.location.pathname) > -1) ? "active" : ""}`,
                disabled: user.accessType === "client",
                iconPrefix: <ListAltIcon/>,
                isShown: !isServiceDashboardAccessible && !isClientDemoAccess && !(user.inviteStatus === 10 && strategySlug !== "esd"),
                labelClass: `${user.accessType === "client" ? "disabled" : ""}`,
                label: (
                        <>
                            <span style={{float: "left", marginTop: "6px"}}>Enrollment </span>
                            {
                                    (user.inviteStatus >= 2 && !user.enrollment) &&
                                    <span className="btnHilightBar glow" style={{color: "#ffffff"}}> Get Started </span>
                            }
                            {enrollmentStatus()}
                        </>
                ),
                onNavClick: onEnrollmentBegin,
            },
        ],
        main: [
            {
                className: "menuHead",
                iconPrefix: <HomeIcon/>,
                isShown: !(user.accessType === "client" || isServiceDashboardAccessible),
                label: "Home Dashboard",
                routeName: "/dashboard",
            },
            {
                className: "menuHead",
                iconPostfix: showServicingToggle ? <ExpandMoreIcon/> : <ChevronRightIcon/>,
                iconPrefix: <HomeIcon/>,
                isShown: isServicingAccess,
                label: "Servicing Dashboard",
                onNavClick: (routeName: string) => onServicingToggle(routeName),
                routeName: "/client/dashboard",
            },
        ],
        product: [
            {
                className: "menuHead",
                iconPostfix: (!isNfIul || !isClientDemoAccess) ? (showToggle ? <ExpandMoreIcon/> : <ChevronRightIcon/>) : undefined,
                iconPrefix: <InfoIcon/>,
                isShown: (!!(showMarketing && showMarketing === 1) && !isClientDemoAccess && !showServicingToggle && !isServiceDashboardAccessible) as boolean,
                label: isNfIul ? "IUL Education" : <span>{strategyName} Details</span>,
                onNavClick: () => setShowToggle(!showToggle),
            }
        ],
        productOptions: [
            {
                iconPrefix: <VideocamIcon/>,
                isShown: true,
                label: "Introduction Video",
                routeName: "/intro",
            },
            estimationVersion === 1 ? {
                iconPrefix: <DescriptionIcon/>,
                isShown: showEstimator,
                label: "Estimate My Benefits",
                routeName: "/client/estimation-new",
            } : {
                className: estimationRouteArray.includes(routeName) ? "active" : "",
                iconPrefix: <DescriptionIcon/>,
                isShown: showEstimator,
                label: "Estimate My Benefits",
                routeName: estimationRoute,
            },
            {
                iconPrefix: <SecurityIcon/>,
                isShown: true,
                label: "Financial Solutions",
                routeName: "/financialconcerns",
            },
            {
                iconPrefix: <ExtensionIcon/>,
                isShown: true,
                label: "How It Works",
                routeName: "/howitwork",
                subList: [
                    {
                        iconPrefix: <FiberManualRecordIcon/>,
                        isShown: (strategySlug !== "kaizen" || (user && user.demoStrategy && user.demoStrategy.slug !== "kaizen")),
                        label: "SplitDollar",
                        routeName: "/SplitDollar",
                    },
                    {
                        iconPrefix: <FiberManualRecordIcon/>,
                        isShown: true,
                        label: "Product",
                        routeName: "/product",
                    },
                    {
                        iconPrefix: <FiberManualRecordIcon/>,
                        isShown: true,
                        label: "Leverage",
                        routeName: "/leverage",
                    },
                    {
                        iconPrefix: <FiberManualRecordIcon/>,
                        isShown: true,
                        label: "Minimizing Risk",
                        routeName: "/minimumrisk",
                    },
                ]
            },
            {
                iconPrefix: <SearchIcon/>,
                isShown: true,
                label: "Plan Details",
                routeName: "/plandetails",
            },
            ...(Number(reBrand) == 1 ? [{
                        iconPrefix: <NotificationsActiveIcon/>,
                        isShown: strategySlug == "kaizen",
                        label: "Testimonials",
                        routeName: "/testimonials-aon",
                    }] : [
                        {
                            iconPrefix: <NotificationsActiveIcon/>,
                            isShown: strategySlug == "kaizen",
                            label: "Testimonials",
                            routeName: "/testimonials",
                        }, {
                            iconPrefix: <CommentIcon/>,
                            isShown: strategySlug == "kaizen",
                            label: "News & Social Posts",
                            routeName: "/news-social",
                        }
                    ]
            ),
            {
                iconPrefix: <LiveHelpIcon/>,
                isShown: true,
                label: "FAQ",
                routeName: "/faq",
            },
        ],
        servicing: [
            {
                iconPrefix: <AttachMoneyIcon/>,
                routeName: "/client/payments",
                isShown: isServicingAccess,
                label: "Payments",
            },
            {
                iconPrefix: <DescriptionIcon/>,
                routeName: "/client/plandocuments",
                isShown: isServicingAccess,
                label: "Plan Documents",
            },
            {
                iconPrefix: <AccessAlarmsIcon/>,
                routeName: "/client/annualreview",
                isShown: isServicingAccess,
                label: "Annual Review",
            },
            {
                iconPrefix: <AccountCircleOutlinedIcon/>,
                routeName: "/client/mydetails",
                isShown: isServicingAccess,
                label: "My Details",
            },
            {
                iconPrefix: <PlaylistAddCheckIcon/>,
                routeName: "/client/claims",
                isShown: isServicingAccess,
                label: "Claims & Distributions",
            },
            {
                iconPrefix: <InfoOutlinedIcon/>,
                routeName: "/client/questions",
                isShown: isServicingAccess,
                label: "Questions (FAQ)",
            },
            {
                iconPrefix: <SupervisorAccountOutlinedIcon/>,
                routeName: "/client/referralfeedback",
                isShown: isServicingAccess,
                label: "Feedback & Referrals",
            },
            {
                iconPrefix: <EmailOutlinedIcon/>,
                routeName: "/client/contactus",
                isShown: isServicingAccess,
                label: "Contact Us",
            },
        ],
    };

    /**
     * RenderListItem: function to render list items in Client Nav;
     * only one small code with (user.inviteStatus === 10 && strategySlug !== "esd") condition removed
     * Everything else should be same as before
     * @param item
     * @param index
     * @param asButton
     */
    const renderListItem = (item: MenuType, index: number, asButton: boolean = true) => {
        const theOnClickFux = () => {
            return item.onNavClick && isFunc(item.onNavClick) ?
                    item.onNavClick(item.routeName) :
                    (
                            !!item.routeName ?
                                    handleNavigation(item.routeName as string) :
                                    noop()
                    );
        };
        return (
                <React.Fragment key={index}>
                    {
                            item.isShown ?
                            <ListItem
                                    button={asButton as any}
                                    className={`${item.className} ${(routeName === item.routeName) ? "active" : ""}`}
                                    disabled={item.disabled}
                                    onClick={theOnClickFux}
                            >
                                {
                                        item.iconPrefix &&
                                        <ListItemIcon>
                                            {
                                                item.iconPrefix
                                            }
                                        </ListItemIcon>
                                }

                                <ListItemText className={item.labelClass} primary={item.label}/>

                                {
                                        item.iconPostfix &&
                                        <ListItemIcon>
                                            {
                                                item.iconPostfix
                                            }
                                        </ListItemIcon>
                                }
                            </ListItem>:<></>
                    }
                    {
                            item.isShown && item.subList && !!va(item.subList) ?
                            <List className="pl15 subList" component="div" disablePadding>
                                {
                                    item.subList.map((subItem: MenuType, index: number) => renderListItem(subItem, index, true))
                                }
                            </List>:<></>
                    }
                </React.Fragment>
        );
    };

    return (
            <>
                <List>
                    {
                            isDebug() &&
                            <ListItem>
                                <pre>
                                    {JSON.stringify({
                                        showToggle,
                                        showMarketing,
                                        isNfIul,
                                        isClientDemoAccess,
                                        showServicingToggle,
                                        isServiceDashboardAccessible,
                                    }, null, 2)}
                                </pre>
                            </ListItem>
                    }
                    {
                        menu.main.map((item, index) => renderListItem(item, index, false))
                    }

                    {
                            showServicingToggle &&
                            <List component="div" disablePadding>
                                {
                                    menu.servicing.map((item, index) => renderListItem(item, index, true))
                                }
                            </List>
                    }

                    {
                        menu.product.map((item, index) => renderListItem(item, index, true))
                    }

                    {
                            showToggle && showMarketing === 1 && !isNfIul && !isClientDemoAccess && !showServicingToggle && !isServiceDashboardAccessible &&
                            <List component="div" disablePadding>
                                {
                                    menu.productOptions.map((item, index) => renderListItem(item, index, true))
                                }
                            </List>
                    }
                </List>

                <List className="listTwo">
                    {
                        menu.enrollment.map((item, index) => renderListItem(item, index, true))
                    }
                </List>
            </>
    );
}